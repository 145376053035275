import React, { useEffect, useState } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet, Linking } from 'react-native';
import { useRecoilState } from 'recoil';
import CalendarOpenState from '../../Recoil/CalendarOpenState';
import { Box, Heading, Spinner, HStack, VStack, Text, Divider, Pressable, Button, useTheme, Stack, Skeleton, Alert } from 'native-base';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import MainButton from './Components/MainButton';
import ScaledImage from '../Components/ScaledImage';
import main from '../../Assets/Styles/main.json';
import APIAction from '../../Actions/APIAction';
import JobView from '../Components/JobView';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../Components/Trans';
import GeneralAction from '../../Actions/GeneralAction';
import { useNavigation } from '@react-navigation/native';
import Request from '../Job/Request';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import UserAction from '../../Actions/UserAction';
import Jobrequests from '../Components/JobRequests';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create(main);

const HomeScreen = () => {

    const [approvedJobs, setApprovedJobs] = useState([]);
    const [requests, setRequests] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [calendarOpen, setCalendarOpen] = useRecoilState(CalendarOpenState);
    const navigation = useNavigation();
    const [showJobRequests, setShowJobRequests] = useState(true);
    const [showHoursToApprove, setShowHoursToApprove] = useState(true);
    const [client, setClient] = useState(null);
    const [clientIsLoading, setClientIsLoading] = useState(false);
    const [hoursToApprove, setHoursToApprove] = useState(null);

    const themeColors = useTheme()['colors'];

    useEffect(() => {
        const getApprovedJobs = async () => {
            // Get jobs
            let jobs = await APIAction.request({
                method: 'get', url: '/api/get/approved/employee_shifts', params: {
                }
            });

            if ('hydra:member' in jobs) {
                jobs = jobs['hydra:member'];
            }

            let approvedElements = [];
            let tempApprovedElementsPerDay = [];
            let tempJobIds = [];
            let jobIds = [];
            let previousDate;
            let indexDate = 0;
            // Loop through jobs
            // Sort the different JobViews in blocks per day
            for (const key in jobs) {
                const job = jobs[key];
                // The job is on the same day as the previous one
                if (previousDate == new Date(job.shift.startOn).setHours(0, 0, 0, 0) || key == 0) {
                    tempApprovedElementsPerDay.push(<JobView key={uuidv4()} job={job} action={'approve'} />);
                    tempJobIds.push(job.id);
                }
                // Put the previous jobs in a day-block and start a new group of jobs
                else {
                    jobIds.push(tempJobIds);
                    let localIndexDate = indexDate;
                    approvedElements.push(
                        createDayElement(previousDate, tempApprovedElementsPerDay, jobIds, localIndexDate)
                    );
                    tempApprovedElementsPerDay = [];
                    tempJobIds = [];
                    tempApprovedElementsPerDay.push(<JobView key={uuidv4()} job={job} action={'approve'} />);
                    tempJobIds.push(job.id);
                    indexDate++;
                }
                // Add the last job to the blocks
                if (key == jobs.length - 1) {
                    jobIds.push(tempJobIds);
                    let localIndexDate = indexDate;
                    approvedElements.push(
                        createDayElement(job.shift.startOn, tempApprovedElementsPerDay, jobIds, localIndexDate)
                    );
                }
                previousDate = new Date(job.shift.startOn).setHours(0, 0, 0, 0);
            }

            setApprovedJobs(approvedElements);
            setIsLoaded(true);
        };

        function createDayElement(date, children, jobIds, localIndexDate) {
            return <Pressable
                style={mainStyle.boxItem}
                onPress={() => {
                    navigation.navigate('ApproveJobs', {
                        // id: job.id
                        ids: jobIds[localIndexDate]
                    });
                }}
            >
                <VStack style={{ width: '100%' }}>
                    <Box style={{
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        paddingTop: 0,
                    }}>
                        <Box style={mainStyle.dateDayLabelWrapper}>
                            <Text style={[mainStyle.dateDayLabel, { marginBottom: 10 }]}>{GeneralAction.formatDateFull(date, 'nl')}</Text>
                        </Box>
                    </Box>
                    {children}
                </VStack>
            </Pressable>
        }

        // const getJobRequests = async () => {
        //     let requests = await APIAction.request({
        //         method: 'get', url: '/api/get/accepted/job_promos', params: {
        //         }
        //     });

        //     if ('hydra:member' in requests) {
        //         requests = requests['hydra:member'];
        //     }

        //     // Create an object to store requests grouped by shift
        //     const requestGroups = {};

        //     for (const key in requests) {
        //         const request = requests[key];

        //             //Check if user is already assigned to shift
        //             let userIsAssignedToShift = false;
        //             for(const key2 in request.jobPromo.shift.employeeShifts){
        //                 let employeeShift = request.jobPromo.shift.employeeShifts[key2];
        //                 if(employeeShift.employee?.id == request.employee.id){
        //                     userIsAssignedToShift = true;
        //                     console.log(employeeShift.employee.user.email);
        //                 }
        //             }

        //         // Add only unassigned and undenied jobRequests
        //         if( !request.denied && !userIsAssignedToShift){
        //             const shiftId = request.jobPromo.id;
        //             // If the shift group doesn't exist, create it
        //             if (!requestGroups[shiftId]) {
        //                 requestGroups[shiftId] = [];
        //             }

        //             // Push the request to the appropriate shift group
        //             requestGroups[shiftId].push(request);
        //         }
        //     }

        //     // Convert the object of grouped requests to an array of arrays
        //     // const requestArray = Object.values(requestGroups);

        //     let shifts = [];
        //     for (const key in requestGroups){
        //         const shift = requestGroups[key];
        //         //If shift is fully booked, dont show it anymore
        //         if(shift[0].jobPromo.shift.amountFilledIn < shift[0].jobPromo.shift.quantity){
        //             shifts.push(
        //             <Box style={[ mainStyle.boxItem, { paddingVertical: 10 }]}>
        //                 <Box style={{ flex: 1 }}>
        //                     <Stack
        //                         direction={{
        //                             md: 'row',
        //                             base: 'column'
        //                         }}
        //                         alignItems={{
        //                             md: 'center',
        //                             base: 'flex-start'
        //                         }}
        //                         justifyContent={'space-between'}
        //                         space={{
        //                             md: 0,
        //                             base: 2
        //                         }}
        //                         style={{ marginVertical: 10 }}
        //                     >
        //                         <HStack alignItems={'center'}>
        //                             <HStack alignItems={'center'} style={{ backgroundColor: themeColors['primary']['100'], paddingHorizontal: 7, paddingVertical: 7, borderRadius: 8, marginRight: 10}}>
        //                                 <Text color={themeColors['primary']['600']} style={{ fontSize: 12 }}>{shift[0].jobPromo.shift.amountFilledIn}/{shift[0].jobPromo.shift.quantity}</Text>
        //                                 <Icon color={themeColors['primary']['600']} name={'user'} size={17} />
        //                             </HStack>
        //                             <Text>{shift[0].jobPromo.shift.position.alias ? shift[0].jobPromo.shift.position.alias : shift[0].jobPromo.shift.position.name} </Text>
        //                         </HStack>
        //                         <HStack alignItems={'center'}>                                
        //                         <Text style={[mainStyle.dateDayLabel, {marginRight: 10}]}>{GeneralAction.formatDateFull(shift[0].jobPromo.shift.startOn, 'nl')}</Text>
        //                             <Text style={mainStyle.timeLabel}>{GeneralAction.formatTime(shift[0].jobPromo.shift.startOn)} - {GeneralAction.formatTime(shift[0].jobPromo.shift.endOn)}</Text>
        //                         </HStack>
        //                     </Stack>
        //                     {shift.map(function(request){
        //                         return <Request promo={request} reload={() => { getJobRequests() }} />
        //                     })}
        //                 </Box>
        //             </Box>
        //             )
        //         }
        //     }

        //     setRequests(shifts);
        // };

        const getClient = async () => {
            //get user
            let user = await UserAction.getUser();

            //get client
            let client = await APIAction.request({
                method: 'GET',
                url: user.client,
                cache: false
            });

            setClient(client);
            setClientIsLoading(false);

            return client;
        }

        getApprovedJobs();
        // getJobRequests();
        let client = getClient();

        //check what day it is
        let now = new Date();
        let format = new Intl.DateTimeFormat('nl-BE', { weekday: 'short' }).format(now);
        if (format == 'ma' || format == 'di') {
            let getIfHoursToApprove = async () => {
                let hoursToApprove = await APIAction.request({
                    method: 'get', url: '/api/get/hours_to_approve', params: {
                    }
                });
                if (hoursToApprove.open) {
                    setHoursToApprove(false);
                } else {
                    setHoursToApprove(true);
                }
            }
            getIfHoursToApprove();
        }



    }, [navigation, themeColors, setHoursToApprove]);


    let horecaWerktButton = <MainButton
        onPress={() => {
            Linking.openURL('https://horecawerkt.be');
        }}
        left={
            <ScaledImage
                height={32}
                uri={require('../../Assets/Images/horeca-werkt.png')}
            />
        }
        right={
            <ScaledImage
                style={{ borderBottomRightRadius: 12 }}
                height={60}
                uri={require('../../Assets/Images/horeca.png')}
            />
        }
    />
    let uwLoonhuisButton = <MainButton
        onPress={() => {
            Linking.openURL('https://uwloonhuis.be');
        }}
        left={
            <ScaledImage
                height={32}
                uri={require('../../Assets/Images/uw-loonhuis.png')}
            />
        }
        right={
            <ScaledImage
                style={{ borderBottomRightRadius: 12 }}
                height={60}
                uri={require('../../Assets/Images/horeca.png')}
            />
        }
    />
    let dimonaNuButton = <MainButton
        onPress={() => {
            setTimeout(() => {
                let openDate = new Date(new Date().setMinutes(new Date().getMinutes() + 35));
                while (openDate.getMinutes() % 15 !== 0) {
                    openDate = new Date(openDate.setMinutes(openDate.getMinutes() + 1));
                }
                setCalendarOpen(openDate);
                navigation.navigate('Calendar', {
                    date: new Date().getTime()
                });
            }, 0);
        }}
        left={
            <ScaledImage
                height={32}
                uri={require('../../Assets/Images/dimonaNu.png')}
            />
        }
        right={
            <ScaledImage
                style={{ borderBottomRightRadius: 12 }}
                height={60}
                uri={require('../../Assets/Images/dimona.png')}
            />
        }
    />
    let projectButton = <MainButton
        onPress={() => {
            navigation.navigate('ProjectList');
        }}
        left={
            <ScaledImage
                height={32}
                uri={require('../../Assets/Images/projects.png')}
            />
        }
        right={
            <ScaledImage
                style={{ borderBottomRightRadius: 12 }}
                height={60}
                uri={require('../../Assets/Images/dimona.png')}
            />
        }
    />


    return (
        <AppContainer>
            <VStack
                space={2}
            >
                {clientIsLoading || client == null ? (
                    // <Skeleton h="40" />
                    <Stack
                        direction={{
                            md: 'row',
                            base: 'column'
                        }}
                        space={2} >
                        {horecaWerktButton}
                        {uwLoonhuisButton}
                        {dimonaNuButton}
                    </Stack>
                ) :
                    client.enableProjectView ? (
                        // Display 2 buttons per row
                        <>
                            <HStack space={2} alignItems="flex-start">
                                {horecaWerktButton}
                                {uwLoonhuisButton}
                            </HStack>
                            <HStack space={2} alignItems="flex-start">
                                {dimonaNuButton}
                                {projectButton}
                            </HStack>
                        </>
                    ) : (
                        // Display all buttons in a single row
                        <Stack
                            direction={{
                                md: 'row',
                                base: 'column'
                            }}
                            space={2} >
                            {horecaWerktButton}
                            {uwLoonhuisButton}
                            {dimonaNuButton}
                        </Stack>
                    )}

                {
                    isLoaded && hoursToApprove === true ?
                        <Box>
                            <Alert colorScheme={'success'} status={'success'}>
                                <HStack space={2} flexShrink={1} alignItems="center">
                                    <Alert.Icon />
                                    <Text color={themeColors['success']['600']}><Trans>Dank u! U heeft alle uren van vorige week reeds bevestigd.</Trans></Text>
                                </HStack>
                            </Alert>
                        </Box>
                        : null
                }
                {
                    isLoaded && hoursToApprove === false ?
                        <Box>
                            <Alert colorScheme={'danger'} status={'error'}>
                                <HStack space={2} flexShrink={1} alignItems="center">
                                    <Alert.Icon />
                                    <Text color={themeColors['danger']['600']}><Trans>Gelieve de uren van vorige week af te ronden. Voor alle projecten die niet voor dinsdag 11h30 zijn afgerond zullen de geplande uren uitbetaald en gefactureerd worden.</Trans></Text>
                                </HStack>
                            </Alert>
                        </Box>
                        : null
                }
            </VStack>



            {/*<Pressable style={mainStyle.homeButton}>
                <Box style={mainStyle.homeButtonContent}>

                    <Box style={mainStyle.homeButtonData}>
                        <Image
                            style={[mainStyle.homeButtonLogo, { width: 166, height: 40 }]}
                            source={require('../../Assets/Images/payroll.png')}
                        />
                    </Box>
                    <Box style={mainStyle.homeButtonIllustration}>
                        <ImageBackground imageStyle={{ borderBottomRightRadius: 12, borderTopRightRadius: 12 }} style={mainStyle.homeButtonIllustrationBackground} source={paymentImage} resizeMode="cover" />
                    </Box>
                </Box>
            </Pressable>
            <Pressable style={mainStyle.homeButton}>
                <Box style={mainStyle.homeButtonContent}>

                    <Box style={mainStyle.homeButtonData}>
                        <Image
                            style={[mainStyle.homeButtonLogo, { width: 178, height: 40 }]}
                            source={require('../../Assets/Images/uw-loonhuis.png')}
                        />
                    </Box>
                    <Box style={mainStyle.homeButtonIllustration}>
                        <ImageBackground imageStyle={{ borderBottomRightRadius: 12, borderTopRightRadius: 12 }} style={mainStyle.homeButtonIllustrationBackground} source={monthImage} resizeMode="cover" />
                    </Box>
                </Box>
            </Pressable>



            <Box style={mainStyle.smallButtonsHome}>
                <Pressable style={mainStyle.pressableSmallButton}>
                    <Box style={mainStyle.homeButtonSmallData}>
                        <Image
                            style={[mainStyle.homeButtonLogo, { width: 51, height: 24 }]}
                            source={require('../../Assets/Images/yeat-1.png')}
                        />
                    </Box>
                    <Box style={mainStyle.homeButtonSmallIllustration}>
                        <ImageBackground imageStyle={{ borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }} style={mainStyle.homeButtonSmallIllustrationBackground} source={reservationImage} resizeMode="cover" />
                    </Box>

                </Pressable>
                <Pressable style={[mainStyle.pressableSmallButton, { marginLeft: '3.5%', marginRight: '3.5%' }]}>
                    <Box style={mainStyle.homeButtonSmallData}>
                        <Heading style={mainStyle.homeButtonHeading}>Dimona nu</Heading>
                    </Box>
                    <Box style={mainStyle.homeButtonSmallIllustration}>
                        <ImageBackground imageStyle={{ borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }} style={mainStyle.homeButtonSmallIllustrationBackground} source={dimonaImage} resizeMode="cover" />
                    </Box>
                </Pressable>
                <Pressable style={mainStyle.pressableSmallButton}>
                    <Box style={mainStyle.homeButtonSmallData}>
                        <Heading style={mainStyle.homeButtonHeading}>Go incasso</Heading>
                    </Box>
                    <Box style={mainStyle.homeButtonSmallIllustration}>
                        <ImageBackground imageStyle={{ borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }} style={mainStyle.homeButtonSmallIllustrationBackground} source={invoiceImage} resizeMode="cover" />
                    </Box>
                </Pressable>

                </Box>*/}

            {
                isLoaded ?
                    <Box style={mainStyle.pageTitleWrapper} mt={5}>
                        <Box style={{ flexGrow: 1 }}>
                            {approvedJobs.length > 0 &&
                                <Pressable
                                    onPress={() => { setShowHoursToApprove(!showHoursToApprove) }}
                                    style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <Heading style={mainStyle.pageTitle}>
                                        <Icon name={showHoursToApprove ? 'angle-down' : 'angle-right'} style={{ marginRight: 5 }} />
                                        <Trans>Hours to approve</Trans>
                                    </Heading>
                                    <Button variant={'subtle'} onPress={() => { setShowHoursToApprove(!showHoursToApprove) }}>
                                        <Text color={themeColors['primary']['600']} style={{ display: showHoursToApprove ? 'flex' : 'none' }}>
                                            <Trans>Collapse</Trans>
                                        </Text>
                                        <Text color={themeColors['primary']['600']} style={{ display: showHoursToApprove ? 'none' : 'flex' }}>
                                            <Trans>Show</Trans>
                                        </Text>
                                    </Button>
                                </Pressable>}
                            <Box
                                style={{ display: showHoursToApprove ? 'flex' : 'none' }}
                                mt={3}
                            >
                                {approvedJobs}
                            </Box>
                            {/* {requests.length > 0 &&
                            <Pressable
                                onPress={() => {setShowJobRequests(!showJobRequests)}}
                                style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10}}
                                >
                                <Heading style={mainStyle.pageTitle}>
                                    <Icon name={showJobRequests ? 'angle-down' : 'angle-right'} style={{marginRight:5}}/>
                                    <Trans>Job requests</Trans>
                                </Heading>
                                <Button variant={'subtle'} onPress={() => {setShowJobRequests(!showJobRequests)}}>
                                    <Text color={themeColors['primary']['600']} style={{display: showJobRequests ? 'flex' : 'none'}}>
                                        <Trans>Collapse</Trans>
                                    </Text>
                                    <Text color={themeColors['primary']['600']} style={{display: showJobRequests ? 'none' : 'flex'}}>
                                        <Trans>Show</Trans>
                                    </Text>
                                </Button>
                            </Pressable>}
                            <Box
                                style={{display: showJobRequests ? 'flex' : 'none'}} 
                                mt={3}
                                >
                                {requests}
                            </Box> */}
                            <Jobrequests />
                        </Box>
                    </Box>
                    :
                    <Box p={3}>
                        <Spinner size={'lg'} />
                    </Box>
            }

        </AppContainer>
    );
};

export default HomeScreen;
