import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Skeleton, VStack, HStack, Box, Button, Heading, Modal, Text, FlatList } from 'native-base';
import AppContainer from '../../Components/AppContainer';
import RefreshControl from '../../../Libs/RefreshControl';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import DayBox from './Components/DayBox';
import GeneralAction from '../../../Actions/GeneralAction';
import { v4 as uuidv4 } from 'uuid';
import CalendarSwitcher from '../Components/CalendarSwitcher';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const OneMonthScreen = ({ navigation, route }) => {

    const
        [date, setDate] = useState(new Date(route.params.date).setHours(0, 0, 0, 0)),
        [displayDate, setDisplayDate] = useState(null),
        [monthList, setMonthList] = useState([]),
        [monthListRefreshing, setMonthListRefreshing] = useState(false),
        [monthListShow, setMonthListShow] = useState(false),
        [firstLoad, setFirstLoad] = useState(false),
        [ready, setReady] = useState(false),
        [dayElements, setDayElements] = useState([])
        ;

    const onFirstLoad = useCallback((props) => {
        const init = async (cache = true) => {

            /* DATE */
            let zeroDate = new Date(route.params.date).setHours(0, 0, 0, 0);
            zeroDate = new Date(zeroDate).setDate(1);
            zeroDate = new Date(zeroDate);

            let endDate = new Date(zeroDate);
            endDate = new Date(endDate.setMonth(endDate.getMonth() + 1));
            endDate = new Date(endDate.setDate(endDate.getDate() - 1));
            endDate = new Date(endDate.setHours(23, 59, 59, 999));

            setDate(zeroDate);
            let formattedDateStart = await GeneralAction.formatLocal(zeroDate, {
                month: 'long',
                year: 'numeric',
                timeZone: 'europe/brussels'
            });
            setDisplayDate(formattedDateStart);

            /* DAY ITEMS */
            let newDayItems = [];
            let currDate = new Date(zeroDate);
            while (currDate < endDate) {
                newDayItems.push(
                    <DayBox
                        date={new Date(currDate)}
                        reload={() => {
                            setFirstLoad(false);
                        }}
                    />
                );
                currDate = new Date(new Date(currDate).setDate(new Date(currDate).getDate() + 1));
            }

            //set
            setDayElements(newDayItems);
            setReady(true);
        };
        init();
    }, [route.params.date]);

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad();
            setFirstLoad(true);
        }
    }, [onFirstLoad, firstLoad]);

    //generate list of weeks for quick navigation
    const generateCalenderList = useCallback((direction = null) => {
        const init = async () => {
            //create batch of weeks
            const dateBatch = async (startDate) => {
                let result = [];

                let lastDate = startDate;
                for (let i = 0; i < 10; i++) {

                    lastDate = new Date(lastDate.setHours(0, 0, 0, 0));
                    lastDate = new Date(lastDate.setDate(1));

                    let endDate = new Date(lastDate);
                    endDate = new Date(endDate.setMonth(endDate.getMonth() + 1));
                    endDate.setDate(endDate.getDate() - 1);
                    endDate = new Date(endDate);

                    let name = await GeneralAction.formatLocal(lastDate, {
                        month: 'long',
                        year: 'numeric',
                        timeZone: 'europe/brussels'
                    });

                    result.push({
                        key: uuidv4(),
                        startDate: lastDate,
                        endDate: endDate,
                        name: name
                    });

                    let nextDate = new Date(endDate);
                    nextDate = nextDate.setDate(nextDate.getDate() + 1);
                    lastDate = new Date(nextDate);
                }

                return result;
            }

            //if no direction asked, start anew
            if (null === direction) {
                let current = new Date();
                current = new Date(current.setHours(0, 0, 0, 0)).setDate(1);
                current = new Date(current);

                //get first batch
                let batch = await dateBatch(current);

                //set in state
                setMonthList(batch);
            }

            //if new dates need to be appended to end
            if ('end' === direction) {
                //get last day in existing list
                let lastDay = monthList[monthList.length - 1];

                //create next list
                let nextDay = new Date(lastDay.endDate);
                nextDay.setDate(nextDay.getDate() + 1);
                nextDay = new Date(nextDay);

                //create batch
                let batch = await dateBatch(nextDay);

                //set in state
                setMonthList([...monthList, ...batch]);
            }

            //if new dates need to be appended to start
            if ('start' === direction) {
                //get first day in existing list
                let firstDay = monthList[0];

                //previous list
                let previousDay = new Date(firstDay.startDate);
                previousDay = new Date(previousDay.setDate(previousDay.getDate() - 1));
                previousDay.setMonth(previousDay.getMonth() - 10);
                previousDay = new Date(previousDay);

                //create batch
                let batch = await dateBatch(previousDay);

                //set in state
                setMonthList([...batch, ...monthList]);
            }
        }
        init(direction);
    }, [monthList]);

    //load first weeks in picker
    useEffect(() => {
        if (monthList.length < 1) {
            generateCalenderList();
        }
    }, [monthList, generateCalenderList]);


    return (
        <>
            <AppContainer
                headerColor={'#f2f9fc'}
                header={
                    <HStack alignItems={'center'} space={2} px={3}>
                        <Box flexGrow={1}>
                            <HStack alignItems={'center'}>
                                <Button variant={'ghost'} onPress={() => {
                                    let prevDate = new Date(date);
                                    prevDate = prevDate.setMonth(prevDate.getMonth() - 1);
                                    prevDate = new Date(prevDate);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'OneMonth',
                                            params: {
                                                date: prevDate.getTime()
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-left'} size={24} />
                                </Button>
                                <Button flexGrow={1} variant={'ghost'} p={0} height={45} onPress={() => {
                                    setMonthListShow(true);
                                }}>
                                    <Heading style={[mainStyle.pageHeaderNavigationTitle]}>{displayDate}</Heading>
                                </Button>
                                <Button variant={'ghost'} onPress={() => {
                                    let nextDate = new Date(date);
                                    nextDate = nextDate.setMonth(nextDate.getMonth() + 1);
                                    nextDate = new Date(nextDate);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'OneMonth',
                                            params: {
                                                date: nextDate.getTime()
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-right'} size={24} />
                                </Button>
                            </HStack>
                        </Box>
                        {/*<Button colorScheme={'primary'} height={30} py={0} onPress={() => {
                            setMonthListShow(true);
                        }}>
                            <Icon color={'#fff'} name={'calendar-check'} size={24} />
                    </Button>*/}
                    </HStack>
                }
            >

                {!ready ?
                    <VStack space={2}>
                        <Skeleton h={20} />
                        <Skeleton h={20} />
                    </VStack>
                    :
                    <>
                        {dayElements}
                    </>
                }

            </AppContainer>

            {/* switcher */}
            <CalendarSwitcher
                style={{ position: 'absolute', bottom: 100, right: 25 }}
                selected={'month'}
                onTrigger={(val) => {
                    switch (val) {
                        case 'day':
                            navigation.reset({
                                index: 0,
                                routes: [{
                                    name: 'OneDay',
                                    params: {
                                        date: new Date(date).getTime()
                                    }
                                }]
                            });
                            break;
                        case 'week':
                            navigation.reset({
                                index: 0,
                                routes: [
                                    {
                                        name: 'OneWeek',
                                        params: {
                                            date: new Date(date).getTime()
                                        }
                                    }
                                ]
                            });
                            break;
                    }
                }}
            />

            {/* modal */}
            <Modal isOpen={monthListShow} onClose={() => setMonthListShow(false)}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text ><Trans>Choose month</Trans></Text></Modal.Header>
                    <Box height={300} p={3}>
                        <FlatList
                            data={monthList}
                            keyExtractor={item => item.key}
                            ItemSeparatorComponent={() => <Box height={1} />}
                            renderItem={({ item }) => {
                                let now = new Date();
                                let colorScheme = 'light';

                                if (
                                    (item.startDate <= now) &&
                                    (item.endDate >= now)
                                ) {
                                    colorScheme = 'primary';
                                }

                                if (
                                    (item.startDate <= date) &&
                                    (item.endDate >= date)
                                ) {
                                    colorScheme = 'secondary';
                                }

                                return (
                                    <Button colorScheme={colorScheme} variant={'subtle'} size={'sm'} onPress={() => {
                                        navigation.reset({
                                            index: 0,
                                            routes: [{
                                                name: 'OneWeek',
                                                params: {
                                                    date: (new Date(item.startDate)).getTime()
                                                }
                                            }]
                                        });
                                    }}>
                                        <Text>{item.name}</Text>
                                    </Button>
                                );
                            }}
                            onEndReached={() => {
                                generateCalenderList('end');
                            }}
                            onEndReachedThreshold={2}
                            refreshControl={
                                <RefreshControl
                                    refreshing={monthListRefreshing}
                                    onRefresh={() => {
                                        setMonthListRefreshing(true);
                                        generateCalenderList('start');
                                        setMonthListRefreshing(false);
                                    }}
                                />
                            }
                        />
                    </Box>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default OneMonthScreen;