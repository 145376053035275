import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { Box, Heading, Button, Text, FormControl, VStack, Stack, Center, useBreakpointValue, Skeleton } from 'native-base';
import { format } from 'date-fns';
import AppContainer from '../../Components/AppContainer';
import CardScanner from '../../../Libs/CardScanner';
import DateTimePicker from '../../../Libs/DateTimePicker';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import ScaledImage from '../../Components/ScaledImage';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import { v4 as uuidv4 } from 'uuid';
import FileFunctions from '../../../Libs/FileFunctions';
import { useIsFocused } from '@react-navigation/native';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const IdCardScreen = ({ navigation, route }) => {

    const [user, setUser] = useState([]);
    const [initialLoaded, setInitialLoaded] = useState(false);

    const [scannerKey, setScannerKey] = useState(uuidv4());
    const [scannerVisible, setScannerVisible] = useState(false);
    const [scannerData, setScannerData] = useState({
        title: '',
        onPhoto: () => {
        },
    });
    const [frontImageSrc, setFrontImageSrc] = useState({ uri: null });
    const [backImageSrc, setBackImageSrc] = useState({ uri: null });
    const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width <= 1024 ? Dimensions.get('window').width : 1024);
    //const [selectMonths, setSelectMonths] = useState([]);
    //const [selectYears, setSelectYears] = useState([]);
    const [expiryDate, setExpiryDate] = useState(new Date());
    //const [expiryMonth, setExpiryMonth] = useState(null);
    //const [expiryYear, setExpiryYear] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);

    let [id, setId] = useState(null);
    // Init
    const isFocused = useIsFocused();

    const maxImageWidth = useBreakpointValue({
        base: 1,
        lg: 2
    });

    useEffect(() => {

        const fetchData = async () => {
            // Get user
            if (!initialLoaded) {
                let apiUser = await APIAction.request({ method: 'GET', url: `/api/users/${route.params.id}`, cache: false });
                setUser(apiUser);
                setInitialLoaded(true);
            } else {
                if (isFocused) {
                    /*let months = [];
                    for (let i = 1; i <= 12; i++) {
                        let val = (i + '').padStart(2, '0');
                        months.push(<Select.Item label={val} value={val}/>);
                    }
                    setSelectMonths(months);
          
                    let years = [];
                    let currentYear = new Date().getFullYear();
                    for (let i = currentYear; i <= (currentYear + 20); i++) {
                        years.push(<Select.Item label={i + ''} value={i + ''}/>);
                    }
                    setSelectYears(years);*/


                    if (!id) {
                        let verification = await APIAction.request({
                            method: 'GET',
                            url: '/api/identity_verifications/get/open',
                            params: {
                                employeeId: user.employee.id,
                            },
                        });

                        if (verification) {
                            setExpiryDate(new Date(verification.expiryDate));
                            setId(verification.id);

                            let frontEntity = await FileFunctions.download({ url: verification.frontImage });

                            if (frontEntity) {
                                setFrontImageSrc(frontEntity);
                            }

                            let backEntity = await FileFunctions.download({ url: verification.backImage });

                            if (backEntity) {
                                setBackImageSrc(backEntity);
                            }
                        }
                    }

                }
            }

        };


        fetchData();


    }, [id, isFocused, expiryDate, initialLoaded, route.params.id, user]);

    /**
     * Open scanner for cards
     * @param {*} side
     */
    const openScanner = async (side) => {
        if (side === 'front') {
            setScannerKey(uuidv4());
            setScannerData({
                title: <Trans>Scan front of card</Trans>,
                onPhoto: (result) => {
                    setFrontImageSrc(result);
                },
            });
        } else {
            setScannerKey(uuidv4());
            setScannerData({
                title: <Trans>Scan back of card</Trans>,
                onPhoto: (result) => {
                    setBackImageSrc(result);
                },
            });
        }
        setScannerVisible(true);
    };

    /**
     * Check and post form
     */
    const checkForm = async () => {
        let error = false;

        if (frontImageSrc.uri === null) {
            error = true;
            GeneralAction.toast('error', <Trans>Upload front of id card</Trans>);
        }

        if (backImageSrc.uri === null) {
            error = true;
            GeneralAction.toast('error', <Trans>Upload back of id card</Trans>);
        }


        if (
            (expiryDate === null) ||
            (expiryDate <= (new Date()))
        ) {
            error = true;
            GeneralAction.toast('error', <Trans>Invalid expiry date</Trans>);
        }

        //if no errors, post
        if (!error) {

            //data object
            let data = {
                frontImage: frontImageSrc,
                backImage: backImageSrc,
                expiryDate: format(expiryDate, 'yyyy-MM-dd'),
                userId: user.id,
            };

            if (id) {
                data.id = id;
            }

            // Make call to verify identity
            setDisableBtn(true);
            await APIAction.request({ method: 'post', url: '/api/verify/identity', body: data, type: 'multipart/form-data' });

            // Update employee with completed steps
            let nameStep = 'identityVerified';
            let completedSteps = user.employee.completedSteps;
            let employeeUrl = '/api/employees/' + user.employee.id;
            if (!completedSteps) {
                completedSteps = [];
            }

            if (!completedSteps.includes(nameStep)) {
                completedSteps.push(nameStep);
            }

            let employeeData = {
                completedSteps: completedSteps,
            };

            await APIAction.request({ method: 'PATCH', url: employeeUrl, body: employeeData });

            GeneralAction.toast('success', <Trans>Verification request send</Trans>);

            setTimeout(() => {
                navigation.replace('CompleteProfile', { 'id': user.id });
            }, 2000);
        }
    };

    /**
     * Listen to dimentions changes
     */
    Dimensions.addEventListener('change', ({ window }) => {
        setWindowWidth(window.width <= 1024 ? window.width : 1024);
    });

    return (
        <AppContainer>
            {initialLoaded ?
                <VStack space={2}>
                    <Box style={mainStyle.pageTitleWrapper}>
                        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                            <Heading style={mainStyle.pageTitle}><Trans>Verify ID</Trans></Heading>
                        </Box>
                    </Box>

                    <Stack
                        space={2}
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                    >
                        {/* Front of id */}
                        <Box flex={1}>
                            <VStack space={2}>
                                <Button colorScheme={'primary'} onPress={() => openScanner('front')}>
                                    <Box style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        minWidth: '100%',
                                    }}>
                                        <Box>
                                            <Icon color={'#fff'} size={30} name={'identification-card'} />
                                        </Box>
                                        <Box marginLeft={3}>
                                            <Text color={'#fff'}><Trans>Upload front of id card</Trans></Text>
                                        </Box>
                                    </Box>
                                </Button>

                                <Center>
                                    <ScaledImage
                                        uri={frontImageSrc.uri}
                                        width={(windowWidth / maxImageWidth) - 0.2 * (windowWidth / maxImageWidth)}
                                        maxHeight={200}
                                    />
                                </Center>
                            </VStack>
                        </Box>

                        {/* Back of id */}
                        <Box flex={1}>
                            <VStack space={2}>
                                <Button colorScheme={'primary'} onPress={() => openScanner('back')}>
                                    <Box style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        minWidth: '100%',
                                    }}>
                                        <Box>
                                            <Icon color={'#fff'} size={30} name={'credit-card'} />
                                        </Box>
                                        <Box marginLeft={3}>
                                            <Text color={'#fff'}><Trans>Upload back of id card</Trans></Text>
                                        </Box>
                                    </Box>
                                </Button>

                                <Center>
                                    <ScaledImage
                                        uri={backImageSrc.uri}
                                        width={(windowWidth / maxImageWidth) - 0.2 * (windowWidth / maxImageWidth)}
                                        maxHeight={200}
                                    />
                                </Center>
                            </VStack>
                        </Box>
                    </Stack>

                    {/* Expiry date */}
                    <Box>
                        <FormControl isRequired>
                            <FormControl.Label><Text><Trans>Expiry date</Trans></Text></FormControl.Label>
                            <DateTimePicker
                                mode={'date'}
                                minimumDate={new Date()}
                                value={expiryDate}
                                onChange={(value) => {
                                    setExpiryDate(value);
                                }
                                }
                            />
                        </FormControl>
                    </Box>

                    {/* Submit */}
                    <Box>
                        <Button
                            isDisabled={disableBtn}
                            isLoading={disableBtn}
                            colorScheme={'success'}
                            onPress={() => {
                                checkForm();
                            }}
                        >
                            <Text color={'#fff'}><Trans>Done</Trans></Text>
                        </Button>
                    </Box>
                </VStack>
                :
                <VStack space={2}>
                    <Skeleton h={'20'} />
                    <Skeleton h={'20'} />
                    <Skeleton h={'20'} />
                </VStack>
            }

            {/* Scanners */}
            <CardScanner
                key={scannerKey}
                title={scannerData.title}
                isOpen={scannerVisible}
                onClose={() => setScannerVisible(false)}
                onPhoto={scannerData.onPhoto}
            />
        </AppContainer>
    );
};

export default IdCardScreen;
