import React from 'react';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Linking, StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { Box, Heading, Skeleton, Text, Stack, Button, Pressable, HStack, VStack } from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';


const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Status = (props) => {

    return (
        <Box style={[props.style]}>
            {props.user ?
                <Box style={[mainStyle.boxItem, { margin: 0, flexDirection: 'column' }]}>
                    <Box>
                        <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                            <Trans>Status</Trans>
                        </Text>
                        <HStack
                            space={1}
                        >
                            <Button
                                flex={1}
                                variant={'subtle'}
                                colorScheme={'success'}
                            >
                                <VStack alignItems={'center'}>
                                    <Icon name={'check-circle'} size={20} style={{ color: '#2ebfa5' }} />
                                    <Text style={[mainStyle.statusBoxItemText, { color: '#2ebfa5' }]}><Trans>Contract</Trans></Text>
                                </VStack>
                            </Button>
                            <Button
                                flex={1}
                                variant={'subtle'}
                                colorScheme={'gray'}
                            >
                                <VStack alignItems={'center'}>
                                    <Icon name={'address-card'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                    <Text style={[mainStyle.statusBoxItemText, { color: 'rgba(0,0,0,0.5)' }]}><Trans>Dimona</Trans></Text>
                                </VStack>
                            </Button>
                            <Button
                                flex={1}
                                variant={'subtle'}
                                colorScheme={'gray'}
                            >
                                <VStack alignItems={'center'}>
                                    <Icon name={'edit'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                    <Text style={[mainStyle.statusBoxItemText, { color: 'rgba(0,0,0,0.5)' }]}><Trans>Edit</Trans></Text>
                                </VStack>
                            </Button>
                            <VStack
                                flex={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <HStack>
                                    <Icon name={'star'} size={16} style={{ color: '#ffb412' }} />
                                    <Icon name={'star'} size={16} style={{ color: '#ffb412' }} />
                                    <Icon name={'star'} size={16} style={{ color: '#ffb412' }} />
                                    <Icon name={'star'} size={16} style={{ color: 'rgba(0,0,0,0.3)' }} />
                                    <Icon name={'star'} size={16} style={{ color: 'rgba(0,0,0,0.3)' }} />
                                </HStack>
                                <Text style={mainStyle.ratingText}><Trans>Personal rating:</Trans> <Text style={mainStyle.bold}>3.7</Text></Text>
                            </VStack>
                        </HStack>
                    </Box>
                </Box>
                :
                <Skeleton h={85} />
            }
        </Box>
    );
};

export default Status;
