import React, {useCallback, useEffect, useRef, useState} from 'react';
import {StyleSheet} from 'react-native';
import {Box, Button, VStack, Text, Skeleton, useTheme} from 'native-base';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import APIAction from '../../../Actions/APIAction';
import ProjectShift from './ProjectShift';
import Trans from '../../Components/Trans';

import lineawesome from '../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../Assets/Styles/main.json';
import ProjectShiftEditPane from './ProjectShiftEditPane';
import GeneralAction from '../../../Actions/GeneralAction';
import TriggerAction from '../../../Actions/TriggerAction';
import {v4 as uuidv4} from 'uuid';

const Icon = createIconSetFromFontello(lineawesome);
const mainStyle = StyleSheet.create(main);

const ProjectShiftList = props => {
  const firstLoad = useRef(true),
    [show, setShow] = useState(true),
    [showCreate, setShowCreate] = useState(false),
    showCreateFirst = useRef(false),
    [shiftElements, setShiftElements] = useState(null);

  const {colors} = useTheme();

  const shiftsAreEmpty = useCallback(isTrue => {
    props.onShiftsEmpty(isTrue);
  }, [props]);

  const loadData = useCallback(() => {
    const init = async () => {
      let abortController = TriggerAction.getTigger('projectScreenCancel');
      //get shifts
      let shifts = await APIAction.request({
        method: 'GET',
        url: '/api/shifts/find_by/project',
        params: {
          projectId: props.project.id,
        },
        cache: false,
        abortController: abortController,
      });
      if (shifts === false) return;
      shifts = shifts['hydra:member'];

      //sort shifts
      let sortedShifts = {};
      for (let shift of shifts) {
        let department = 'none';
        if (shift.department) department = shift.department.name;
        if (!(department in sortedShifts)) sortedShifts[department] = {};
        let startOn = GeneralAction.formatDate(shift.startOn);
        if (!(startOn in sortedShifts[department]))
          sortedShifts[department][startOn] = [];
        sortedShifts[department][startOn].push(shift);
      }

      //create new shift elements
      let newShiftElements = [];
      for (let [department, sorted] of Object.entries(sortedShifts)) {
        //department title
        newShiftElements.push(
          <Text opacity={0.5} marginBottom={2}>
            {department.toUpperCase()}
          </Text>,
        );
        for (let [date, shiftArray] of Object.entries(sorted)) {
          for (let shift of shiftArray) {
            newShiftElements.push(
              <ProjectShift
                key={uuidv4()}
                paddingLeft={2}
                paddingBottom={2}
                shift={shift}
                project={props.project}
                onUpdate={() => {
                  init();
                }}
                shiftsAreEmpty={shiftsAreEmpty}
              />,
            );
          }
        }
      }

      setShiftElements(newShiftElements);
    };
    init();
  }, [props.project, shiftsAreEmpty]);

  useEffect(() => {
    if (firstLoad.current && show) {
      firstLoad.current = false;
      loadData();
    }
  }, [loadData, show]);

  return (
    <>
      <Box style={props.style}>
        {/* <Button
                    variant={'link'}
                    onPress={() => setShow(!show)}
                >
                    <Icon size={20} name={show ? 'chevron-up' : 'chevron-down'} />
                </Button> */}

        <Box display={show ? 'flex' : 'none'}>
          {shiftElements !== null ? (
            <VStack space={2} alignItems={'flex-start'}>
              {shiftElements.length > 0 && (
                <VStack
                  style={{
                    backgroundColor: '#edf5f9',
                    width: '100%',
                    padding: 5,
                    borderRadius: 5,
                  }}>
                  {shiftElements}
                </VStack>
              )}
              {new Date(props.project.endDate) >=
                new Date(Date.now() - 86400000) && ( // It should stay visible on the last day as well
                <Button
                  w={'100%'}
                  p={1}
                  leftIcon={
                    <Icon
                      style={{color: colors['secondary']['600']}}
                      size={16}
                      name={'plus'}
                    />
                  }
                  borderRadius={'md'}
                  colorScheme={'secondary'}
                  variant={'outline'}
                  onPress={() => {
                    showCreateFirst.current = true;
                    setShowCreate(true);
                  }}>
                  <Text fontSize={'xs'} color={colors['secondary']['600']}>
                    <Trans>Add shift</Trans>
                  </Text>
                </Button>
              )}
            </VStack>
          ) : (
            <VStack space={2}>
              <Skeleton.Text lines={2} />
              <Skeleton.Text lines={2} />
            </VStack>
          )}
        </Box>
      </Box>
      {showCreateFirst && (
        <ProjectShiftEditPane
          open={showCreate}
          project={props.project}
          onClose={() => {
            setShowCreate(false);
          }}
          reload={() => {
            loadData();
          }}
        />
      )}
    </>
  );
};

export default ProjectShiftList;
