import React, {useState} from 'react';
import {Box, Button, ScrollView, Text, VStack} from 'native-base';
import ApproveJob from './ApproveJob';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';

const ApproveJobs = ({ route, navigation }) => {
    // Init
    let ids = [];
    const [trigger, setTrigger] = useState(0);
    const [isApproving, setIsApproving] = useState(false);

    // Get id
    if (route.params) {
        if (route.params.ids) {
            ids = route.params.ids;
        }
    }

    return (
        <AppContainer>
          <ScrollView>
              <VStack>
                  {ids.map((id) => {
                      return <ApproveJob
                          route={route}
                          navigation={navigation}
                          id={id}
                          trigger={trigger}
                          stopApproving={() => {setIsApproving(false)}}
                      />
                  })
                  }
                  <Button
                      isLoading={isApproving}
                      flex={1}
                      variant={'subtle'}
                      onPress={() => {
                          setIsApproving(true);
                          setTrigger((trigger) => trigger + 1)
                      }}
                  >
                      <Text color={'primary.600'}><Trans>Approve</Trans></Text>
                  </Button>
              </VStack>
          </ScrollView>
    </AppContainer>
    );
}

export default ApproveJobs;
