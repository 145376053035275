import React from 'react';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Linking, StyleSheet, TouchableOpacity } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { Box, Heading, Skeleton, Text, Stack, Button, HStack } from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Email = (props) => {

    return (
        <Box style={[props.style]}>
            {props.user ?
                <TouchableOpacity 
                    style={ { margin: 0, flexDirection: 'column' }}
                    onPress={async () => {
                        // Mail user
                        let url = 'mailto:' + GeneralAction.trim(props.user.email);
                        await Linking.openURL(url);
                    }}>
                    <Stack
                        direction={{
                            base: 'column',
                            sm: 'row'
                        }}
                        space={3}
                        justifyContent={{
                            base: 'flex-start',
                            sm: 'space-between'
                        }}
                        alignItems={{
                            base: 'stretch',
                            sm: 'center'
                        }}
                    >
                        <Box>
                            <HStack alignItems={'center'} space={2}>
                                <Icon name={'envelope'} size={16} style={{ color: '#2ebfa5' }}></Icon>
                                <Text style={{ color: 'rgba(0,0,0,0.5)' }} fontSize={12}>
                                    <Trans>E-mail</Trans>
                                </Text>
                            </HStack>
                            <Text>{props.user.email}</Text>
                        </Box>
                        {/* <Box flexGrow={1} flexShrink={1} maxWidth={{
                            base: 'full',
                            sm: 250
                        }}>
                            <Button
                                variant={'subtle'}
                                colorScheme={'success'}
                                borderRadius={'full'}
                                onPress={async () => {
                                    // Mail user
                                    let url = 'mailto:' + GeneralAction.trim(props.user.email);
                                    await Linking.openURL(url);
                                }}
                            >
                                <HStack space={1} alignItems={'center'}>
                                    <Icon name={'envelope'} size={16} style={{ color: '#2ebfa5' }}></Icon>
                                    <Text style={{ color: '#2ebfa5', fontSize: 13 }}><Trans>Write now</Trans></Text>
                                </HStack>
                            </Button>
                        </Box> */}
                    </Stack>
                </TouchableOpacity>
                :
                <Skeleton h={85} />
            }
        </Box>
    );
};

export default Email;
