import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Skeleton, VStack } from 'native-base';
import main from '../../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import DepartmentBox from './Components/DepartmentBox';
import APIAction from '../../../../Actions/APIAction';
import UserAction from '../../../../Actions/UserAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DepartmentScreen = (props) => {

    const
        [firstLoad, setFirstLoad] = useState(false),
        [ready, setReady] = useState(false),
        [departmentItems, setDepartmentItems] = useState([])
        ;

    let routeDate = new Date(props.date);
    routeDate.setHours(0, 0, 0, 0);

    const onFirstLoad = useCallback((props) => {
        const init = async (cache = false) => {

            /* SET DATE */
            let currentDate = new Date(props.date);
            currentDate.setHours(0, 0, 0, 0);

            /* GET SHIFTS */
            let currentUser = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(currentUser.client);

            let apiMainShifts = await APIAction.request({
                method: 'GET',
                url: '/api/shifts',
                params: {
                    onDate: currentDate.toISOString(),
                    empty: true
                },
                cache: false
            });
            apiMainShifts = apiMainShifts['hydra:member'];

            let apiShifts = await APIAction.request({
                method: 'GET', url: '/api/employee_shifts', params: {
                    clientId: clientId,
                    onDate: currentDate.toISOString()
                }, cache: cache
            });
            apiShifts = apiShifts['hydra:member'];
            let reloadKey = uuidv4();

            /* ADD SHIFTS TOGETHER */
            for (let mainShift of apiMainShifts) {
                apiShifts.push({ shift: mainShift });
            }

            /* GET DEPARTMENTS */
            let departments = await APIAction.request({ method: 'GET', url: '/api/departments', cache: false });
            departments = departments['hydra:member'];
            let newDepartments = [];
            for (let department of departments) {
                newDepartments[department.id] = department;
            }
            departments = newDepartments;

            /* SORT SHIFTS */
            let sortedShifts = {};
            for (let apiShift of apiShifts) {
                let department = apiShift.shift.department;
                let departmentId = 'none';
                if (department !== null) {
                    departmentId = department.id
                    departments[departmentId] = department;
                }

                //set key if doesn't exist
                if (!(departmentId in sortedShifts)) sortedShifts[departmentId] = [];

                //add
                sortedShifts[departmentId].push(apiShift);
            }
            //add departments that are empty on bottom
            for (let [index, department] of Object.entries(departments)) {
                if (!(index in sortedShifts)) sortedShifts[index] = [];
            }

            /* DEPARTMENT ITEMS */
            let newDepartmentItems = [];
            for (let [index, value] of Object.entries(sortedShifts)) {
                newDepartmentItems.push(
                    <DepartmentBox
                        date={props.date}
                        department={departments[index]}
                        shifts={value}
                        reloadKey={reloadKey}
                        reload={() => {
                            setFirstLoad(false);
                        }}
                    />
                );
            };

            //set
            setDepartmentItems(newDepartmentItems);
            setReady(true);
        };
        init();
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad(props);
            setFirstLoad(true);
        }
    }, [props, onFirstLoad, firstLoad]);

    return (
        <>
            {!ready ?
                <VStack space={2}>
                    <Skeleton h={16} />
                    <Skeleton h={16} />
                    <Skeleton h={16} />
                </VStack>
                :
                <>
                    {departmentItems}
                </>
            }
        </>
    );
}

export default DepartmentScreen;