import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Text, Box, Pressable, Image } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../Assets/Styles/main.json';
import FileFunctions from '../../../Libs/FileFunctions';
import { useNavigation } from '@react-navigation/native';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const SearchResult = (props) => {

    // Init
    const navigation = useNavigation();
    const type = props.type;
    const text = props.text;
    const id = props.id;
    let color = '#00aaff';
    let typeIcon = props.typeIcon;
    let [image] = useState(props.image);
    let [imageUri, setImageUri] = useState('');

    // Add default icon if we don't have an image
    if (!image) {
        typeIcon = 'user';
    }

    // Change color of search label
    if (type && type.toLowerCase() === 'personel') {
        color = '#556ee6';
    }

    if (type && type.toLowerCase() === 'date') {
        color = '#2ebfa5';
    }

    if (type && type.toLowerCase() === 'project') {
        color = '#ffb412';
    }

    useEffect(() => {
        const fetchData = async () => {
            // Get image of user
            if (image && image.id && !imageUri) {
                let url = '/api/files/' + image.id;
                let imageEntity = await FileFunctions.download({ url: url });

                if (imageEntity) {
                    setImageUri(imageEntity.uri);
                }
            }
        };

        fetchData();

    }, [imageUri, image]);

    const openDetailPage = () => {
        // Open detail page of personel
        if (type.toLowerCase() === 'personel') {
            navigation.navigate('Person', { 'id': id });
        }

        // Open detail page of project
        if (type.toLowerCase() === 'project') {
            navigation.navigate('Project', { 'id': id });
        }

        // Go to organization overview
        if (type.toLowerCase() === 'job type') {
            navigation.navigate('OrganizationOverview');
        }
    };

    // Return view
    return (
        <Box style={mainStyle.resultItemWrapper}>
            <Pressable onPress={() => openDetailPage()}>
                <Box style={mainStyle.resultItem}>
                    <Box style={mainStyle.resultItemLabelBox}>
                        <Box style={[mainStyle.resultItemLabel, { backgroundColor: color }]}>
                            <Text style={mainStyle.resultItemLabelText}>{type}</Text>
                        </Box>
                    </Box>
                    <Box style={mainStyle.resultItemIcon}>
                        {typeIcon && <Icon name={typeIcon} size={24} style={{ color: 'rgba(0,0,0,1)' }} />}
                        {imageUri && <Image
                            style={[mainStyle.userCircle]}
                            source={imageUri}
                        />}
                    </Box>
                    <Box style={mainStyle.resultItemText}>
                        <Text style={mainStyle.resultItemTextStyle}>{text}</Text>
                    </Box>
                    <Box style={mainStyle.resultItemArrow}>
                        <Icon name={'angle-right'} size={16} style={{ color: 'rgba(0,0,0,0.35)' }} />
                    </Box>

                </Box>

            </Pressable>
            {/*
            // Date view (not used at the moment)
    <Box style={mainStyle.resultItemWrapper}>
              <Pressable>
                <Box style={mainStyle.resultItem}>
                  <Box style={mainStyle.resultItemLabelBox}>
                    <Box style={[mainStyle.resultItemLabel, { backgroundColor: '#2ebfa5' }]}>
                      <Text style={mainStyle.resultItemLabelText}>Date</Text>
                    </Box>
                  </Box>
                  <Box style={mainStyle.resultItemIcon}>
                    <Icon name={'calendar-check'} size={24} style={{ color: 'rgba(0,0,0,1)' }} ></Icon>
                  </Box>
                  <Box style={mainStyle.resultItemText}>
                    <Text style={mainStyle.resultItemTextStyle}>25/09/2022</Text>
                  </Box>
                  <Box style={mainStyle.resultItemArrow}>
                    <Icon name={'angle-right'} size={16} style={{ color: 'rgba(0,0,0,0.35)' }} ></Icon>
                  </Box>

                </Box>
              </Pressable>
            </Box>

    */}
        </Box>
    );
};

export default SearchResult;
