import React, { useEffect, useState } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet } from 'react-native';
import {
    Box,
    Text,
    Heading,
    Input,
    Pressable,
    Stack,
    Skeleton,
} from 'native-base';
import Trans from '../Components/Trans';
import main from '../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import SearchResult from './Components/SearchResult';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

let searchTimeout = null;

const SearchScreen = () => {

    // Init
    const [searchValue, setSearchValue] = useState('');
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const limit = 10;
    const [loading] = useState([
        <Skeleton key={uuidv4()} h={'5'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'5'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'5'} marginBottom={'3'} />,
    ]);

    const [loadingElement, setLoadingElement] = useState([]);


    useEffect(() => {
        clearTimeout(searchTimeout);
        const fetchData = () => {
            // Show loading screen and submit search if user isn't typing
            if (!searchSubmitted && searchValue) {
                setLoadingElement(loading);
                searchTimeout = setTimeout(() => {
                    setSearchSubmitted(true);
                    search();
                }, 1500);
            }
        };

        fetchData();

        /**
         * Get results
         *
         * @returns {Promise<void>}
         */
        const search = async () => {
            // Api call
            let response = await APIAction.request({
                method: 'get', url: '/api/search', params: {
                    query: searchValue,
                    limit: limit,
                },
            });

            // process response
            if (response) {
                setPage(2);
                handleResults(response);
                setLoadingElement([]);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, searchSubmitted, totalResults]);

    /**
     * Get next results
     *
     * @returns {Promise<void>}
     */
    const getNextResults = async () => {
        // API call
        let response = await APIAction.request({
            method: 'get', url: '/api/search', params: {
                query: searchValue,
                limit: limit,
                page: page,
            },
        });

        // Handle response
        if (response) {
            setPage(page + 1);
            handleResults(response, true);
        }
    };

    /**
     * Process results
     *
     * @param response
     * @param add
     */
    const handleResults = (response, add = false) => {
        // Init
        let resultsArray = [];

        // Check if we have results
        if (response.total) {
            setTotalResults(response.total);

            // Add users
            if (response.users) {
                for (const key in response.users) {
                    // Get user
                    let user = response.users[key];

                    //-- Get name
                    let name = '';
                    if (user.firstName) {
                        name += user.firstName;
                    }

                    if (user.firstName && user.lastName) {
                        name += ' ';
                    }

                    if (user.lastName) {
                        name += user.lastName;
                    }

                    // Add to array
                    resultsArray.push(<SearchResult key={uuidv4()} type={'Personel'} id={user.id} text={name}
                        image={user.image} />);
                }
            }

            // Get projects
            if (response.projects) {
                for (const projectKey in response.projects) {
                    // Get project
                    let project = response.projects[projectKey];
                    let name = '';

                    // Get client name
                    if (project.client && project.client.name.length > 0) {
                        name += project.client.name;
                    }

                    // Get name of project
                    if (project.name.length > 0) {
                        name += ' - ' + project.name;
                    }

                    // Add to array
                    resultsArray.push(<SearchResult key={uuidv4()} type={'Project'} id={project.id} text={name}
                        icon={'location-arrow'} />);
                }
            }

            // Add positions
            if (response.positions) {
                for (const positionKey in response.positions) {
                    // Get position
                    let position = response.positions[positionKey];
                    let name = '';

                    // Get name
                    if (position.alias.length > 0) {
                        name += position.alias;
                    } else if (position.name.length > 0) {
                        name += position.name;
                    }

                    // Add to array
                    resultsArray.push(<SearchResult key={uuidv4()} type={'Job Type'} id={position.id} text={name}
                        icon={'mug-hot'} />);
                }
            }

            if (!add) {
                // Reset results (in case of new search)
                setResults(resultsArray);
            } else {
                // Append results (in case of 'show more')
                let currentResults = [...results, ...resultsArray];
                setResults(currentResults);
            }
        }
    };

    // Return view
    return (
        <AppContainer>
            <Box style={mainStyle.pageTitleWrapper}>
                <Box style={{ flexGrow: 1 }}>
                    <Heading style={mainStyle.pageTitle}><Trans>Search</Trans></Heading>
                </Box>
            </Box>
            <Box style={mainStyle.searchWrapper}>
                <Box style={mainStyle.searchFieldWrapper}>
                    <Stack space={4} w="100%" alignItems="center">
                        <Input
                            type={'text'}
                            variant={'unstyled'}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            style={mainStyle.whiteInputSearch}
                            value={searchValue}
                            onChangeText={(val) => {
                                setSearchValue(val);
                                setSearchSubmitted(false);
                            }}
                            w={{
                                base: '94%',
                                //md: "3%" // Too small for web?
                            }} InputLeftElement={<Icon name={'search'} size={24} style={{ color: '#00aaff' }} />}
                            placeholder="Type project, date or personel..." />
                    </Stack>
                </Box>

                <Box style={mainStyle.searchResultsWrapper}>
                    {loadingElement}
                    {(totalResults > 0) && !loadingElement.length &&
                        <>
                            <Heading style={mainStyle.mediumTitle} mb="2"><Trans>Results</Trans> <Text
                                style={mainStyle.mediumTtitleSubtitle}>- {totalResults}</Text></Heading>
                            {results}
                        </>
                    }
                    {(totalResults === 0) && !loadingElement.length &&
                        <Text style={mainStyle.mediumTitle}>
                            <Trans>No results found</Trans>
                        </Text>
                    }
                </Box>

                {results.length > 0 && (totalResults - results.length) > 0 &&
                    <Box style={mainStyle.loadMore}>

                        <Pressable onPress={() => {
                            getNextResults();
                        }}>
                            <Box style={mainStyle.loadMoreButton}>
                                <Text style={mainStyle.loadMoreText}><Trans>Show more</Trans> &nbsp;
                                    (
                                    {(totalResults - results.length)} &nbsp;
                                    {(totalResults - results.length > 1 ? <Trans>results</Trans> :
                                        <Trans>result</Trans>)}
                                    )</Text>
                            </Box>
                        </Pressable>

                    </Box>
                }
            </Box>


        </AppContainer>
    );
};

export default SearchScreen;
