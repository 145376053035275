import UserAction from './UserAction';
import APIAction from './APIAction';
import GeneralAction from './GeneralAction';

const ClientAction = {
    // Get settings of client
    getClientSetting: async (key) => {
        let currentUser = await UserAction.getUser();
        let clientId = GeneralAction.iriToId(currentUser.client);

        let response = await APIAction.request({
            method: 'GET', url: '/api/get/client/setting', params: {
                'keyCode': key,
                'clientId': clientId,
            }
        });

        if (response) {
            return response;
        }

        return false;
    },
}

export default ClientAction;