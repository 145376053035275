import React from 'react';
import { Box, Tooltip, useTheme } from 'native-base';
import Svg, { Circle } from 'react-native-svg';

const Wrapper = (props) => {
    if (props.total) {
        return (
            <Tooltip placement={'top'} label={`${props.count} / ${props.total}`}>
                {props.children}
            </Tooltip>
        );
    }

    return (
        <>
            {props.children}
        </>
    );
}

const PieChart = (props) => {

    const { colors } = useTheme();

    let bgColor = colors['danger']['100'];
    let pieColor = colors['danger']['600'];

    let percentage = 0;
    if (props.total) {
        percentage = (props.count / props.total) * 100;
    }

    if (percentage >= 50) {
        bgColor = colors['warning']['100'];
        pieColor = colors['warning']['600'];
    }
    if (percentage == 100) {
        bgColor = colors['success']['100'];
        pieColor = colors['success']['600'];
    }

    return (
        <Box style={props.style}>
            <Wrapper {...props}>
                <Box>
                    <Svg
                        height={`${props.size}px`}
                        width={`${props.size}px`}
                        viewBox={'0 0 100 100'}
                    >
                        <Circle
                            cx={'50'}
                            cy={'50'}
                            r={'50'}
                            fill={bgColor}
                        />
                        <Circle
                            cx={'50'}
                            cy={'50'}
                            r={25 * 0.8}
                            fill={bgColor}
                            stroke={pieColor}
                            strokeWidth={50 * 0.8}
                            strokeDasharray={`${percentage * (2 * Math.PI * (25 * 0.8)) / 100} ${2 * Math.PI * (25 * 0.8)}`}
                            transform={'rotate(-90) translate(-100)'}
                        />
                    </Svg>
                </Box>
            </Wrapper>
        </Box>
    )
};

export default PieChart;