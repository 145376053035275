import React, { useCallback, useEffect, useState, useRef } from 'react';
import APIAction from '../../../Actions/APIAction';
import { Box, FormControl, Input, Text, Pressable, Actionsheet, Heading, ScrollView, HStack } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Trans from '../../Components/Trans';
import { useRecoilState } from 'recoil';
import ShiftEditPaneAppendState from '../../../Recoil/ShiftEditPaneAppendState';
import { Platform, StyleSheet } from 'react-native';
import TranslationAction from '../../../Actions/TranslationAction';
import main from '../../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const EmployeeSelect = ({ translations, i, updateAddEmployees, employee, formDataRef, shift, acceptedEmployees, otherEmployees }) => {

    let selectedValue = null;
    for (let item of employee.elements) {
        if (item.value === formDataRef["position" + i]) {
            selectedValue = item
            break;
        }
    }
    if (selectedValue === null) {
        selectedValue = employee.elements[0];
    }

    const
        firstLoad = useRef(true),
        [result, setResult] = useState(selectedValue),
        [shiftPaneAppend, setShiftPaneAppend] = useRecoilState(ShiftEditPaneAppendState),
        [localSelector, setLocalSelector] = useState(null),
        [language, setLanguage] = useState(null)
        ;

    const setSelector = async() => {

        let selectedLanguage = await TranslationAction.getSelectedLanguage();
        setLanguage(selectedLanguage);

        const selecor =
            <Actionsheet isOpen={true} onClose={() => {
                setShiftPaneAppend(null);
                setLocalSelector(null);
            }}>
                <Actionsheet.Content>
                    <ScrollView width={'100%'}>
                        {acceptedEmployees.length > 0 &&
                            <Box w={'100%'} style={{ paddingHorizontal: 15 }}>
                                <Heading textAlign={'left'} color={'primary.600'} size={'sm'}><Trans>Interested employees</Trans></Heading>
                            </Box>
                        }
                        {acceptedEmployees.map((item, index) => {
                            let itemProps = {};
                            if (item.value === result.value) {
                                itemProps = {
                                    color: 'primary.600'
                                }
                            }

                            return (
                                <Actionsheet.Item
                                    onPress={() => {
                                        setResult(item);
                                        formDataRef["position" + i] = item.value;
                                        updateAddEmployees();
                                        setShiftPaneAppend(null);
                                        setLocalSelector(null);
                                    }}
                                >
                                    <Text {...itemProps}>{item.label}</Text>
                                </Actionsheet.Item>
                            );
                        })}
                        {otherEmployees.length > 0 &&
                            <Box w={'100%'} style={{ paddingHorizontal: 15 }}>
                                <Heading textAlign={'left'} color={'primary.600'} size={'sm'}><Trans>Others</Trans></Heading>
                            </Box>
                        }
                        {otherEmployees.map((item, index) => {
                            let itemProps = {};
                            if (item.value === result.value) {
                                itemProps = {
                                    color: 'primary.600'
                                }
                            }

                            return (
                                <Actionsheet.Item
                                    onPress={() => {
                                        setResult(item);
                                        formDataRef["position" + i] = item.value;
                                        updateAddEmployees();
                                        setShiftPaneAppend(null);
                                        setLocalSelector(null);
                                    }}
                                >
                                    <HStack>
                                        <Text {...itemProps}>
                                            {item.label}

                                        </Text>
                                        {index > 0 &&
                                            <Text
                                            style={[mainStyle.timeLabel, {marginLeft: 5}]}>
                                                {item.status}
                                            </Text>
                                        }
                                    </HStack>
                                </Actionsheet.Item>
                            );
                        })}
                        <Actionsheet.Item
                            onPress={() => {
                                setShiftPaneAppend(null);
                                setLocalSelector(null);
                            }}
                        >
                            <Text color={'danger.600'}><Trans>Cancel</Trans></Text>
                        </Actionsheet.Item>
                    </ScrollView>
                </Actionsheet.Content>
            </Actionsheet>
            ;
        if (Platform.OS === 'web') {
            setShiftPaneAppend(selecor);
        } else {
            setLocalSelector(selecor);
        }

    };


    return (
        <Box style={{ width: '48%' }} key={i}>
            <FormControl.Label><Text><Trans>Employee</Trans> {i}</Text></FormControl.Label>
            <Pressable
                onPress={() => {
                    setSelector();
                }}
            >
                <Box opacity={0} position={'absolute'} zIndex={1} left={0} right={0} top={0} bottom={0}>
                    {/* Click catcher */}
                </Box>
                <Input
                    isReadOnly
                    InputRightElement={<Icon style={{ paddingHorizontal: 10 }} name={'angle-down'} size={24} color='#000' />}
                    value={selectedValue.label}
                />
            </Pressable>
            {/*<Select
                placeholder={translations['Select']}
                selectedValue={formDataRef["position" + i]}
                onValueChange={(value) => {
                    formDataRef["position" + i] = value;
                    updateAddEmployees();
                    // setFormData(
                    //     { ...formData, ...{ [`position${i}`]: value } }
                    // );
                }}
            >
                {employee.elements}
            </Select>*/}
            {localSelector}
        </Box>
    );
};

export default EmployeeSelect;
