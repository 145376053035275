import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { NativeBaseProvider, extendTheme, StatusBar } from 'native-base';
import NativeBaseConfig from './Libs/NativeBase/Config';
import { RecoilRoot, useRecoilState } from 'recoil';
import LoadingState from './Recoil/LoadingState';
import Router from './Router';

import FontAction from './Actions/FontAction';

import LoadingScreen from './Views/General/LoadingScreen';

FontAction.init();

const theme = extendTheme({
    fonts: {
        heading: 'Poppins',
        body: 'Poppins',
    },
    components: {
        Input: {
            baseStyle: {
                backgroundColor: '#fff'
            }
        },
        Select: {
            defaultProps: {
                optimized: false
            }
        }
    },
    colors: {
        primary: {
            50: '#ebf8ff',
            100: '#c4ebff',
            200: '#9ddeff',
            300: '#76d1ff',
            400: '#4ec4ff',
            500: '#27b7ff',
            600: '#00AAFF',
            700: '#0090d8',
            800: '#0076b1',
            900: '#005c89',
        },
        secondary: {
            50: '#eceffc',
            100: '#c7d0f7',
            200: '#a2b0f1',
            300: '#7d90ec',
            400: '#5871e6',
            500: '#3351e1',
            600: '#556EE6',
            700: '#1e3ccc',
            800: '#1931a7',
            900: '#132682',
        },
        light: {
            50: '#E6F1FF',
            100: '#e3efff',
            200: '#aacfff',
            300: '#71b0ff',
            400: '#3990ff',
            500: '#0070ff',
            600: '#0057c6',
            700: '#003e8e',
            800: '#002555',
            900: '#000c1c'
        }
    }
});

/* main */
const App = () => {

    let [loaded, setLoaded] = useRecoilState(LoadingState);

    useEffect(() => {
        if (Platform.OS === 'web') {
            localStorage.setItem('InitialUrl', window.location.href);
        }
    }, []);

    return (
        <NativeBaseProvider theme={theme} config={NativeBaseConfig}>
            <StatusBar barStyle={'dark-content'} backgroundColor={'#fff'} />
            {loaded ?
                <Router /> :
                <LoadingScreen loadingState />
            }
        </NativeBaseProvider>
    );
};

const RecoilApp = () => {
    return (
        <RecoilRoot>
            <App />
        </RecoilRoot>
    );
};

export default RecoilApp;
