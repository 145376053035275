import database from '../Database/Database';
import { Q } from '@nozbe/watermelondb';
import SecureStorage from '../Libs/SecureStorage';

const UserAction = {
    setUser: async (user) => {
        //open writer
        await database.write(async () => {

            //delete previous entries
            await database.get('current_user').query().destroyAllPermanently();

            //create new entry
            let dbUser = await database.get('current_user').create(userObj => {
                userObj.userId = user.id;
                userObj.email = user.email;
                userObj.phone = user.phone;
                userObj.firstName = user.firstName;
                userObj.lastName = user.lastName;
                userObj.employee = user.employee;
                userObj.language = user.language;
                userObj.client = user.client
                userObj.isIdentityVerified = user.identityVerified;
                userObj.isPaymentVerified = user.paymentVerified;
            });
        });
    },

    getUser: async () => {
        //get user from deb
        let user = await database.get('current_user').query(
            Q.take(1)
        );

        //if nu user exists
        if (user.length === 0) {
            return false;
        } else {
            user = user[0];
        }

        return user;
    },

    logOut: async () => {
        await SecureStorage.clearCredentials();

        //open writer
        await database.write(async () => {
            await database.get('current_user').query().destroyAllPermanently();
            await database.get('setting').query().destroyAllPermanently();
        });
    }
};

export default UserAction;
