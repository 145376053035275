import React from 'react';
import { StyleSheet } from 'react-native';
import { Pressable, Box, HStack } from 'native-base';

import main from '../../../Assets/Styles/main.json';
const mainStyle = StyleSheet.create(main);

const MainButton = (props) => {
    return (
        <Pressable style={[mainStyle.homeButton, { marginBottom: 0, flex: 1, ...props.style }]} onPress={() => {
            if (typeof props.onPress === 'function') {
                props.onPress();
            }
        }}>
            <HStack
                justifyContent={'space-between'}
            >
                <Box
                    p={5}
                    zIndex={1}
                >
                    {props.left}
                </Box>
                <Box
                    position={'absolute'}
                    right={0}
                    bottom={0}
                >
                    {props.right}
                </Box>
            </HStack>
        </Pressable>
    );
};

export default MainButton;