import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import AppContainer from '../Components/AppContainer';
import { Box, Text, VStack, Stack, Modal, HStack, Button, useTheme, Center, ScrollView, Skeleton } from 'native-base';
import APIAction from '../../Actions/APIAction';
import Trans from '../Components/Trans';
import { ImagePicker } from '../../Libs/FilePicker';
import Job from './Components/Job';
import TimeClock from './Components/TimeClock';
import { v4 as uuidv4 } from 'uuid';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ClockOpenState from '../../Recoil/ClockOpenState';
import { useRecoilState } from 'recoil';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ClockScreen = (props) => {

    const
        closeCode = '4411',
        { colors } = useTheme(),
        firstLoad = useRef(true),
        [shifts, setShifts] = useState(null),
        [enteredCode, setEnteredCode] = useState('****'),
        [openModal, setOpenModal] = useState(false),
        [ready, setReady] = useState(false),
        [clockOpen, setClockOpen] = useRecoilState(ClockOpenState),
        loopInterval = useRef(null)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            //get employee shifts
            let employeeShifts = await APIAction.request({
                method: 'GET',
                url: '/api/get/clock/employee_shifts',
            });
            employeeShifts = employeeShifts['hydra:member'];

            let newShifts = [];
            for (let shift of employeeShifts) {
                newShifts.push(
                    <Stack
                        direction={{
                            md: 'row',
                            base: 'column'
                        }}
                    >
                        <Box flexGrow={1} flexShrink={1}>
                            <Job key={uuidv4()} job={shift} />
                        </Box>
                        <TimeClock job={shift} onReload={() => {
                            onFirstLoad();
                        }} />
                    </Stack>

                );
            }

            setShifts(newShifts);
            setReady(true);
        };
        init();
    }, [setShifts]);

    useEffect(() => {
        clearInterval(loopInterval.current);
        loopInterval.current = setInterval(() => {
            if (!clockOpen) {
                onFirstLoad();
            }
        }, 300000);
    }, [onFirstLoad, clockOpen]);

    const onChangeCode = async (value) => {
        let oldCode = enteredCode.replace(/\*/g, '');
        let newCode = '';
        if (value === '-1') {
            newCode = oldCode.slice(0, -1);
        } else {
            newCode = oldCode + value;
        }
        if (newCode.length === 4) {
            if (newCode === closeCode) {
                setOpenModal(false);
                AsyncStorage.removeItem('ClockLocked').then(() => {
                    clearInterval(loopInterval.current);
                    props.navigation.navigate('App');
                });
            }
            newCode = '';
        }
        newCode = newCode.padEnd(4, '*');
        setEnteredCode(newCode);
    };

    const closeModal = () => {
        setEnteredCode('****');
        setOpenModal(false);
    };

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    });

    return (
        <>
            <AppContainer
                noTabBar
                noScroll
            >
                <ScrollView style={{ height: '97vh' }}>
                    {ready ?
                        shifts
                        :
                        <>
                            <Skeleton h={85} marginBottom={15} />
                            <Skeleton h={85} marginBottom={15} />
                            <Skeleton h={85} marginBottom={15} />
                        </>
                    }
                </ScrollView>
                <Box height={'3vh'} marginBottom={6} >
                    <Button variant={'subtle'} colorScheme={'danger'}
                        onPress={() => {
                            AsyncStorage.removeItem('ClockLocked').then(() => {
                                clearInterval(loopInterval.current);
                                props.navigation.navigate('App');
                            });
                        }}
                    >
                        <Icon color={colors['danger']['600']} size={16} name={'times'} />
                    </Button>
                    {/*<ImagePicker open={true} directToCam={true} frontCamera={true} />*/}
                </Box>
            </AppContainer>
            <Modal
                isOpen={openModal}
                onClose={() => closeModal()}
            >
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text style={[mainStyle.modalTitle]}><Trans>Code</Trans></Text></Modal.Header>
                    <Modal.Body>
                        <Center>
                            <Text fontSize={'xl'}>{enteredCode}</Text>
                        </Center>
                        <VStack
                            space={2}
                        >
                            <HStack
                                space={2}
                            >
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('1')}
                                >1</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('2')}
                                >2</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('3')}
                                >3</Button>
                            </HStack>
                            <HStack
                                space={2}
                            >
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('4')}
                                >4</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('5')}
                                >5</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('6')}
                                >6</Button>
                            </HStack>
                            <HStack
                                space={2}
                            >
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('7')}
                                >7</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('8')}
                                >8</Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('9')}
                                >9</Button>
                            </HStack>
                            <HStack
                                space={2}
                            >
                                <Button
                                    colorScheme={'danger'}
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => closeModal()}
                                ><Text color={'#fff'}><Trans>Cancel</Trans></Text></Button>
                                <Button
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('0')}
                                >0</Button>
                                <Button
                                    colorScheme={'warning'}
                                    flex={1}
                                    flexGrow={1}
                                    flexShrink={1}
                                    onPress={() => onChangeCode('-1')}
                                ><Icon color={'#fff'} size={16} name={'arrow-left'} /></Button>
                            </HStack>
                        </VStack>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default ClockScreen;