import React, { useState, useEffect, useCallback } from 'react';
import AppContainer from '../../Components/AppContainer';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Button, HStack, Modal } from 'native-base';
import { Calendar } from 'react-native-calendars';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Trans from '../../Components/Trans';
import GeneralAction from '../../../Actions/GeneralAction';
import StaffScreen from './Staff/StaffScreen';
import DepartmentScreen from './Department/DepartmentScreen';
import CalendarSwitcher from '../Components/CalendarSwitcher';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const OneDayScreen = ({ navigation, route }) => {

    const [date, setDate] = useState(new Date(route.params.date).setHours(0, 0, 0, 0));
    const [displayDate, setDisplayDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const onLoad = useCallback(() => {
        const init = async (date) => {
            let zeroDate = new Date(date).setHours(0, 0, 0, 0);
            setDate(zeroDate);
            let formattedDate = await GeneralAction.formatLocal(zeroDate, {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: '2-digit',
                timeZone: 'europe/brussels'
            });
            setDisplayDate(formattedDate);
        };
        init(route.params.date);
    }, [route.params]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return (
        <>
            <AppContainer
                headerColor={'#f2f9fc'}
                header={
                    <HStack alignItems={'center'} space={2} px={3}>
                        <Box flexGrow={1}>
                            <HStack alignItems={'center'}>
                                <Button variant={'ghost'} onPress={() => {
                                    let prevDate = new Date(date);
                                    prevDate.setDate(prevDate.getDate() - 1);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'OneDay',
                                            params: {
                                                date: prevDate.getTime(),
                                                type: route.params.type
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-left'} size={24} />
                                </Button>
                                <Button flexGrow={1} variant={'ghost'} p={0} height={45} onPress={() => {
                                    setShowCalendar(true);
                                }}>
                                    <Heading style={mainStyle.pageHeaderNavigationTitle}>{displayDate}</Heading>
                                </Button>
                                <Button variant={'ghost'} onPress={() => {
                                    let nextDate = new Date(date);
                                    nextDate.setDate(nextDate.getDate() + 1);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'OneDay',
                                            params: {
                                                date: nextDate.getTime(),
                                                type: route.params.type
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-right'} size={24} />
                                </Button>
                            </HStack>
                        </Box>
                        <Button.Group isAttached colorScheme={'primary'} height={30} backgroundColor={'#fff'}>
                            <Button py={0} onPress={() => {
                                navigation.navigate('OneDay', { type: 'staff', date: new Date(date).getTime() })
                            }} variant={route.params.type === 'staff' ? 'solid' : 'outline'}>
                                <Text color={route.params.type === 'staff' ? '#fff' : '#000'}><Trans>Staff</Trans></Text>
                            </Button>
                            <Button py={0} onPress={() => {
                                navigation.navigate('OneDay', { type: 'department', date: new Date(date).getTime() });
                            }} variant={route.params.type === 'staff' ? 'outline' : 'solid'} >
                                <Text color={route.params.type === 'staff' ? '#000' : '#fff'}><Trans>Dept</Trans></Text>
                            </Button>
                        </Button.Group>
                    </HStack>
                }
            >
                {route.params.type === 'staff' ?
                    <StaffScreen
                        date={date}
                    />
                    :
                    <DepartmentScreen
                        date={date}
                    />
                }
            </AppContainer>

            {/* switcher */}
            <CalendarSwitcher
                style={{ position: 'absolute', bottom: 100, right: 25 }}
                selected={'day'}
                onTrigger={(val) => {
                    switch (val) {
                        case 'week':
                            navigation.reset({
                                index: 0,
                                routes: [{
                                    name: 'OneWeek',
                                    params: {
                                        date: new Date(date).getTime()
                                    }
                                }]
                            });
                            break;
                        case 'month':
                            navigation.reset({
                                index: 0,
                                routes: [{
                                    name: 'OneMonth',
                                    params: {
                                        date: new Date(date).getTime()
                                    }
                                }]
                            });
                            break;
                    }
                }}
            />

            {/* modal */}
            <Modal isOpen={showCalendar} onClose={() => setShowCalendar(false)}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text ><Trans>Choose day</Trans></Text></Modal.Header>
                    <Modal.Body _scrollview={{ alwaysBounceHorizontal: false, alwaysBounceVertical: false }}>
                        <Calendar
                            renderArrow={direction => <Icon size={20} name={'angle-' + direction} />}
                            enableSwipeMonths={true}
                            firstDay={1}
                            onDayPress={({ timestamp }) => {
                                navigation.reset({
                                    index: 0,
                                    routes: [{
                                        name: 'OneDay',
                                        params: {
                                            date: timestamp,
                                            type: route.params.type
                                        }
                                    }]
                                });
                            }}
                            markedDates={{
                                [GeneralAction.formatDate(new Date(date), true)]: { marked: true }
                            }}
                        />
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default OneDayScreen;