import React, { useEffect, useState, useRef, useCallback } from 'react';
import main from '../../Assets/Styles/main.json';
import { StyleSheet } from 'react-native';
import { Box, Heading, Pressable, Stack, Text, Modal, VStack, Spinner, ScrollView } from 'native-base';
import Trans from './Trans';
import APIAction from '../../Actions/APIAction';
import AvailabilityProject from './AvailabilityProject';


const mainStyle = StyleSheet.create(main);

const AvailabilityPlanModal = (props) => {

    const
        [planModalOpen, setPlanModalOpen] = useState(false),
        firstLoad = useRef(true),
        [possibleShifts, setPossibleShifts] = useState(null),
        [loading, setLoading] = useState(false)
        ;

    const onClose = useCallback(() => {
        setPlanModalOpen(false);
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    }, [props]);

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            let response = await APIAction.request({
                method: 'GET',
                url: '/api/shifts/find_by/date',
                params: {
                    date: props.availability.date,
                    employeeId: props.availability.employee.id
                }
            });

            //sort by project
            let byProject = {};
            for (let possibleShift of response.possibleShifts) {
                //get project
                let possibleShiftProject = possibleShift.project;

                //check if project exists
                if (byProject[possibleShiftProject.id] === undefined) {
                    byProject[possibleShiftProject.id] = {
                        project: possibleShiftProject,
                        shifts: []
                    };
                }

                //add shift
                byProject[possibleShiftProject.id].shifts.push(possibleShift);
            }

            //get planned shifts times
            let plannedShiftsTimes = [];
            for (let plannedShift of response.plannedShifts) {
                plannedShiftsTimes.push({
                    startOn: plannedShift.startOn,
                    endOn: plannedShift.endOn
                });
            }

            //create possible shifts options
            let possibleSiftItems = [];
            for (let [key, project] of Object.entries(byProject)) {
                possibleSiftItems.push(
                    <AvailabilityProject
                        data={project}
                        plannedShiftsTimes={plannedShiftsTimes}
                        employee={props.availability.employee}
                        onClose={() => onClose()}
                        onLoading={() => setLoading(true)}
                    />
                );
            }

            setPossibleShifts(possibleSiftItems);
        }
        init();
    }, [props.availability, onClose]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
            return;
        }
    }, [firstLoad, onFirstLoad])

    useEffect(() => {
        setPlanModalOpen(props.isOpen);
    }, [props.isOpen])

    return (
        <Modal isOpen={planModalOpen} onClose={() => onClose()} size={'xl'}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header><Text style={[mainStyle.modalTitle]}><Trans>Plan</Trans> {props.availability.employee.user.firstName} {props.availability.employee.user.lastName}</Text></Modal.Header>
                <Modal.Body>
                    {(possibleShifts === null || loading) ?
                        <Spinner size={'lg'} />
                        :
                        <>
                            { possibleShifts.length === 0 ?
                                <Text><Trans>No shifts available.</Trans></Text>
                                :
                                <VStack space={2}>
                                    {possibleShifts}
                                </VStack>
                            }
                        </>
                    }
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
}

export default AvailabilityPlanModal;