let options = {};

const TriggerAction = {
    getTigger: (name) => {
        if (name in options) {
            return options[name];
        } else {
            return null;
        }
    },
    setTrigger: (name, value) => {
        options[name] = value;
    }
};

export default TriggerAction;