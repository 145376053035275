let Helpers = {
  firstLetterUpperCase: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  createStringDate: function (date) {
    let year = date.getFullYear() + '';
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let stringDate = `${year}-${month}-${day}T00:00:00+01:00`;
    return new Date(stringDate);
  },
  formatDateUrlString: function (date) {
    let year = date.getFullYear() + '';
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
  downloadAndOpenPdfFromResponse: function (response) {
    let url = window.URL.createObjectURL(new Blob([response]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'deviation-sheets.pdf');
    document.body.appendChild(link);
    link.click();
  },
};

export default Helpers;
