import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Heading, Text, ScrollView, VStack } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../../Assets/Fontello/line-awesome-config.json';
import CalendarHeader from '../../../Components/CalendarHeader';
import CalendarRow from '../../../Components/CalendarRow';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DepartmentBox = (props) => {

    const
        [show, setShow] = useState(false),
        [firstLoad, setFirstLoad] = useState(false),
        [reloadKey, setReloadKey] = useState(props.reloadKey),
        [header, setHeader] = useState(null),
        [shiftElements, setShiftElements] = useState([]),
        [length, setLength] = useState(0),
        [minDate, setMinDate] = useState(null),
        [maxDate, setMaxDate] = useState(null)
        ;

    const collapseStyle = {
        height: show ? 'auto' : 0,
        overflow: 'hidden'
    };

    //create header first
    const onFirstLoad = useCallback(() => {
        const init = async () => {

            /* SET HEADER IN STATE */
            setHeader(
                <CalendarHeader
                    key={uuidv4()}
                    shifts={props.shifts}
                    date={props.date}
                    onLength={(hLenght) => {
                        setLength(hLenght);
                    }}
                    onMinDate={(hMin) => {
                        setMinDate(new Date(hMin));
                    }}
                    onMaxDate={(hMax) => {
                        setMaxDate(new Date(hMax));
                    }}
                />
            );

        };
        init();
    }, [props]);


    //then load other items
    useEffect(() => {
        const init = async () => {

            /* SORT BY DATE */
            let apiShifts = props.shifts;
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SPLIT BY EMPLOYEE */
            let employees = {};
            let employeeShifts = {};

            for (let shift of apiShifts) {
                let employeeId = 'none_' + uuidv4();
                if (shift.employee) {
                    employeeId = shift.employee['@id'];
                }
                if (!(employeeId in employeeShifts)) {
                    employees[employeeId] = shift.employee;
                    employeeShifts[employeeId] = [];
                }

                employeeShifts[employeeId].push(shift);
            }

            /* CREATE SHIFT ITEMS */
            let newShiftElements = [];
            for (let [employeeId, shifts] of Object.entries(employeeShifts)) {
                newShiftElements.push(
                    <CalendarRow
                        key={uuidv4()}
                        shifts={shifts}
                        startOn={minDate}
                        endOn={maxDate}
                        date={props.date}
                        employeeId={employeeId}
                        departmentId={props.department['@id']}
                        reload={() => {
                            if (typeof props.reload === 'function') props.reload();
                        }}
                    />
                );
            }

            //add last row
            newShiftElements.push(
                <CalendarRow
                    key={uuidv4()}
                    shifts={[]}
                    startOn={minDate}
                    endOn={maxDate}
                    date={props.date}
                    departmentId={props.department['@id']}
                    reload={() => {
                        if (typeof props.reload === 'function') props.reload();
                    }}
                />
            )

            /* SET IN STATE */
            setShiftElements(newShiftElements);

        }
        if (minDate && maxDate) init();
    }, [minDate, maxDate, props])

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad();
            setFirstLoad(true);
        }
    }, [onFirstLoad, firstLoad, props]);

    useEffect(() => {
        if (reloadKey !== props.reloadKey) {
            setReloadKey(props.reloadKey);
            setMinDate(null);
            setMaxDate(null);
            setFirstLoad(false);
        }
    }, [reloadKey, props.reloadKey]);

    return (
        <>

            <Box style={mainStyle.whiteBoxCollapse}>
                <Pressable style={mainStyle.collapseButton} onPress={() => { setShow(!show) }}>
                    <Box style={mainStyle.collapseButtonContent}>
                        <Heading style={[mainStyle.mediumTitle, mainStyle.collapseButtonText]}>{props.department.name} <Text style={mainStyle.smallText}>- {shiftElements.length - 1} <Icon name={'user'} /></Text></Heading>
                        <Box style={mainStyle.collapseButtonIcon}>
                            <Icon name={'angle-' + (show ? 'up' : 'down')} size={16} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                        </Box>
                    </Box>
                </Pressable>

                {/* collapsible */}
                <Box style={collapseStyle}>
                    <Box style={mainStyle.whiteBoxCollapseContent}>

                        <ScrollView horizontal contentContainerStyle={{ minWidth: '100%', width: length * 60 }}>
                            <VStack w={'100%'} space={2}>
                                {header}
                                {shiftElements}
                            </VStack>
                        </ScrollView>

                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DepartmentBox;