import database from '../Database/Database';
import { Q } from '@nozbe/watermelondb';
import Config from '../Libs/Config';
import HashGenerator from '../Libs/HashGenerator';
import { createPerformanceTrace, stopPerformanceTrace } from '../Libs/Firebase';

const RequestCacheAction = {

    generateHash: async ({ method, url, params, body }) => {
        let trace = await createPerformanceTrace('hash_time');
        trace.putAttribute('url', url);
        let hash = await HashGenerator.hash(method + url + params + body);
        await stopPerformanceTrace(trace);
        return hash;
    },

    write: async ({ method, url, params, body, response, finished = true }) => {

        if (null === params) params = 'null';
        if (null === body) body = 'null';

        let hash = await RequestCacheAction.generateHash({ method: method, url: url, params: JSON.stringify(params), body: JSON.stringify(body) });
        if (null !== response) response = JSON.stringify(response);

        let queryTrace = await createPerformanceTrace('cache_write');
        queryTrace.putAttribute('url', url);

        await database.write(async () => {
            let items = await database.get('request_cache').query(
                Q.where('hash_value', hash)
            );

            for (let item of items) {
                await item.destroyPermanently();
            }

            let finishedAt = finished ? new Date() : null;

            await database.get('request_cache').create(newItem => {
                newItem.hash = hash;
                newItem.method = method;
                newItem.finishedAt = finishedAt;
                newItem.url = url;
                newItem.params = JSON.stringify(params);
                newItem.body = JSON.stringify(body);
                newItem.response = response
            });

            await stopPerformanceTrace(queryTrace);

        });
    },

    read: async ({ method, url, params, body }) => {

        if (null === params) params = 'null';
        if (null === body) body = 'null';

        let hash = await RequestCacheAction.generateHash({ method: method, url: url, params: JSON.stringify(params), body: JSON.stringify(body) });

        //get comarison date
        let milliseconds = (new Date()).getTime();
        milliseconds = milliseconds - (1000 * parseInt(Config.REQUEST_CACHE_TIMEOUT));
        let limitDate = new Date(milliseconds);

        const getItem = async () => {

            let queryTrace = await createPerformanceTrace('cache_read');
            queryTrace.putAttribute('url', url);

            let items = await database.get('request_cache').query(
                Q.where('hash_value', hash),
                Q.where('created_at', Q.gt(limitDate.getTime())),
                Q.sortBy('created_at', Q.desc),
                Q.take(1)
            ).unsafeFetchRaw();

            await stopPerformanceTrace(queryTrace);

            items = items.map((val) => {
                return {
                    id: val.id,
                    finishedAt: new Date(val.finished_at),
                    response: val.response_value
                };
            });

            let item = null;

            if (items.length > 0) item = items[0];

            return item;
        };

        let item = await getItem();

        const waitForItem = async (item) => {

            //if item unavailable or finished return immediatly
            if (
                (null === item) ||
                (null !== item.finishedAt)
            ) {
                return item;
            }

            let count = 0;
            while (
                (null !== item) &&
                (null !== item.finishedAt) &&
                (count < 5)
            ) {
                //wait 
                await new Promise((resolve) => {
                    setTimeout(resolve, 300);
                });

                //try again
                item = await getItem();

                //counter
                count++;
            }

            //return 
            return item;
        };
        item = await waitForItem(item);

        //parse response & copy
        if (null !== item && null !== item.response) {
            item = {
                response: JSON.parse(item.response)
            };
        }

        return item;
    },

    clear: async ({ url }) => {
        await database.write(async () => {
            let items = await database.get('request_cache').query(
                Q.where('url_value', Q.like(`%${url}%`))
            );

            for (let item of items) {
                await item.destroyPermanently();
            }
        });
    },

    empty: async () => {
        await database.write(async () => {
            await database.get('request_cache').query().destroyAllPermanently();
        });
    }
};

export default RequestCacheAction;