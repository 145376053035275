import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Pressable, Skeleton, VStack, useTheme, Stack } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import AppContainer from '../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../Components/Trans';
import UserAction from '../../Actions/UserAction';
import Job from '../Clock/Components/Job';
import JobView from '../Components/JobView';
import CalendarAction from "../../Actions/CalendarAction";
import Lottie from '../../Libs/Lottie/index';
import GeneralAction from '../../Actions/GeneralAction';
import { useNavigation } from '@react-navigation/native';

// import lottieEmptyCalendar from '../../Assets/Lottie/empty-calendar.json';
// import TimeClock from './Components/TimeClock';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create(main);

const Upcomingjobs = (props) => {

    const isFocused = useIsFocused();
    const { colors } = useTheme();
    const [requests, setRequests] = useState([]);
    const [showJobRequests, setShowJobRequests] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState('');
    const cancelJobPromos = useRef(new AbortController());
    const navigation = useNavigation();

    const themeColors = useTheme()['colors'];

    let [jobElements, setJobElements] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);

    useEffect(() => {
        let init = async () => {
            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);
            setClient(clientId);
        }
        init();
    }, [setClient]);

    useEffect(() => {

        /**
         * Get recently upcoming jobs
         *
         * @returns {Promise<void>}
         **/
        const getUpcomingJobs = async () => {
            console.log('props.user.employee:', props);
            if (props.user){
                // Get jobs
                let jobs = await APIAction.request({ method: 'get', url: '/api/get/upcoming/employee_shifts', params: {
                    employeeId: props.user.employee.id
                } });

                if ('hydra:member' in jobs) {
                    jobs = jobs['hydra:member'];
                }
                console.log('jobs: ', jobs);

                let elements = [];

                // Loop through jobs
                for (const key in jobs) {
                    const job = jobs[key];

                    // Add element to array
                    elements.push(
                        <Stack
                            direction={{
                                md: 'row',
                                base: 'column'
                            }}
                        >
                            <Box style={{ marginBottom: 10, flexGrow: 1 }} key={job.id + '_jelement'}>
                                <Pressable
                                    sytle={{ paddingLeft: 10 }}
                                    onPress={() => {
                                        navigation.navigate('Preview', {
                                            id: job.id,
                                        });
                                    }}
                                >
                                    <Job style={{ marginBottom: 0 }} key={uuidv4()} job={job} noName />
                                </Pressable>
                            </Box>
                            {/* <TimeClock job={job} onReload={() => getUpcomingJobs()} /> */}
                        </Stack>
                    );
                }
            setJobElements(elements);
            }

        };

        const fetchData = async () => {
            if (isFocused) {
                // Get jobs
                await getUpcomingJobs();
            
            };
        }
        fetchData();
    }, [isFocused, navigation, props]);

    return (
        <>
            {/* Default message */}
            {jobElements.length === 0 &&
                <VStack alignItems="center" style={{ marginTop: 60 }}>
                    <Text style={{ color: colors['primary']['600'], fontSize: 17 }}>
                        <Trans>No upcoming jobs.</Trans>
                    </Text>
                    {/* <Lottie source={lottieEmptyCalendar} autoPlay={true} loop={true} speed={1} style={{ width: 230, height: 230 }} /> */}
                </VStack>
            }

            {jobElements}
        </>
    );
};

export default Upcomingjobs;
