import React, { useCallback, useEffect, useState, useRef } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet } from 'react-native';
import { Box, Heading, Pressable, Skeleton, VStack, HStack, IconButton } from 'native-base';
import main from '../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import EmployeeItem from './Components/EmployeeItem';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../Components/Trans';
import { useIsFocused } from '@react-navigation/native';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const OrganizationScreen = ({ navigation }) => {

    // Init
    const firstLoad = useRef(true);
    const focused = useIsFocused();
    const [employees, setEmployees] = useState(false);

    /**
     * Function to load specific data at first load
     *
     * @type {(function(): void)|*}
     */
    const onFirstLoad = useCallback(() => {
        const init = async () => {
            // Get employees
            let apiEmployees = await APIAction.request(
                {
                    method: 'GET', url: '/api/employees', params: {
                        verified: 'false',
                    }, cache: false,
                });
            apiEmployees = apiEmployees['hydra:member'];

            let employeeItems = [];
            for (let apiEmployee of apiEmployees) {
                employeeItems.push(
                    <EmployeeItem key={uuidv4()} data={apiEmployee} showStatus={1} noPressable={!apiEmployee.paymentVerified && !apiEmployee.indentityVerified} />
                );
            }

            setEmployees(employeeItems);
        };

        if (focused) {
            init();
        }
    }, [focused]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }

    }, [onFirstLoad]);

    useEffect(() => {
        if (!focused) {
            firstLoad.current = true;
        }
    }, [focused]);


    // Return view
    return (
        <AppContainer>
            <VStack
                space={2}
            >
                <Box style={[mainStyle.pageTitleWrapper, { paddingBottom: 0 }]}>
                    <Box style={{ flexGrow: 1 }}>
                        <HStack space={2} alignItems={'center'}>
                            <Box flexGrow={1} flexShrink={1}>
                                <Heading style={mainStyle.pageTitle}><Trans>Medewerkers</Trans></Heading>
                            </Box>
                            <Box>
                                <IconButton
                                    onPress={() => navigation.navigate('Invite')}
                                    variant={'solid'}
                                    colorScheme={'secondary'}
                                    borderRadius={'full'}
                                    icon={<Icon name={'plus'} size={16} style={{ color: '#ffffff' }} />}
                                />
                            </Box>
                        </HStack>
                    </Box>
                </Box>
                <Box style={mainStyle.whiteBox}>
                    <VStack space={2}>
                        {employees === false &&
                            <VStack space={2}>
                                <Skeleton h={'30'} />
                                <Skeleton h={'30'} />
                            </VStack>
                        }
                        {employees}
                    </VStack>
                </Box>
            </VStack>
        </AppContainer>
    );
};

export default OrganizationScreen;
