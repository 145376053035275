import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Heading, Text, ScrollView, VStack, Skeleton } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import CalendarHeader from '../../Components/CalendarHeader';
import CalendarRow from '../../Components/CalendarRow';
import GeneralAction from '../../../../Actions/GeneralAction';
import APIAction from '../../../../Actions/APIAction';
import UserAction from '../../../../Actions/UserAction';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DayBox = (props) => {

    const
        [show, setShow] = useState(false),
        [firstLoad, setFirstLoad] = useState(false),
        [header, setHeader] = useState(null),
        [shifts, setShifts] = useState(null),
        [shiftElements, setShiftElements] = useState(null),
        [length, setLength] = useState(0),
        [minDate, setMinDate] = useState(null),
        [maxDate, setMaxDate] = useState(null),
        [weekday, setWeekday] = useState(''),
        [fullDate, setFullDate] = useState(''),
        [ready, setReady] = useState(false)
        ;

    const collapseStyle = {
        height: show ? 'auto' : 0,
        overflow: 'hidden'
    };

    //create title first
    const onFirstLoad = useCallback(() => {
        const init = async () => {

            /* SET DATE */
            let formatWeekday = await GeneralAction.formatLocal(props.date, { weekday: 'long' });
            setWeekday(formatWeekday);

            let formatFullDate = await GeneralAction.formatLocal(props.date, { day: 'numeric', month: 'long' });
            setFullDate(formatFullDate);
        };
        init();
    }, [props]);

    //set header when open
    useEffect(() => {
        const init = async () => {

            let date = new Date(props.date);

            /* GET SHIFTS */
            let currentUser = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(currentUser.client);

            let apiMainShifts = await APIAction.request({
                method: 'GET',
                url: '/api/shifts',
                params: {
                    onDate: date.toISOString(),
                    empty: true
                },
                cache: false
            });
            apiMainShifts = apiMainShifts['hydra:member'];

            let apiShifts = await APIAction.request({
                method: 'GET', url: '/api/employee_shifts', params: {
                    clientId: clientId,
                    onDate: date.toISOString()
                }, cache: false
            });
            apiShifts = apiShifts['hydra:member'];

            /* ADD SHIFTS TOGETHER */
            for (let mainShift of apiMainShifts) {
                apiShifts.push({ shift: mainShift });
            }

            /* SORT BY DATE */
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SET HEADER IN STATE */
            setHeader(
                <CalendarHeader
                    key={uuidv4()}
                    shifts={apiShifts}
                    date={date}
                    onLength={(hLenght) => {
                        setLength(hLenght);
                    }}
                    onMinDate={(hMin) => {
                        setMinDate(new Date(hMin));
                    }}
                    onMaxDate={(hMax) => {
                        setMaxDate(new Date(hMax));
                    }}
                />
            );

            /* SET SHIFTS IN STATE */
            setShifts(apiShifts);
        }
        if (show && (shifts === null)) init();
    }, [props, firstLoad, show, shifts]);

    //then load other items
    useEffect(() => {
        const init = async () => {

            /* SORT BY DATE */
            let apiShifts = shifts
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SPLIT BY EMPLOYEE */
            let employees = {};
            let employeeShifts = {};

            for (let shift of apiShifts) {
                let employeeId = 'none_' + uuidv4();
                if (shift.employee) {
                    employeeId = shift.employee['@id'];
                }
                if (!(employeeId in employeeShifts)) {
                    employees[employeeId] = shift.employee;
                    employeeShifts[employeeId] = [];
                }

                employeeShifts[employeeId].push(shift);
            }

            /* CREATE SHIFT ITEMS */
            let newShiftElements = [];
            for (let [employeeId, shifts] of Object.entries(employeeShifts)) {
                let id = uuidv4();
                newShiftElements.push(
                    <CalendarRow
                        key={id}
                        shifts={shifts}
                        startOn={minDate}
                        endOn={maxDate}
                        date={props.date}
                        employeeId={employeeId}
                        reload={() => {
                            setMinDate(null);
                            setMaxDate(null);
                            setShifts(null);
                            setShiftElements(null);
                            setFirstLoad(false);
                        }}
                    />
                );
            }

            //add last row
            newShiftElements.push(
                <CalendarRow
                    key={uuidv4()}
                    shifts={[]}
                    startOn={minDate}
                    endOn={maxDate}
                    date={props.date}
                    reload={() => {
                        setMinDate(null);
                        setMaxDate(null);
                        setShifts(null);
                        setShiftElements(null);
                        setFirstLoad(false);
                    }}
                />
            );

            /* SET IN STATE */
            setShiftElements(newShiftElements);
            setReady(true);
        }

        if (minDate && maxDate && shifts && (shiftElements === null)) init();
    }, [minDate, maxDate, shifts, shiftElements, props])

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad();
            setFirstLoad(true);
        }
    }, [onFirstLoad, firstLoad, props]);

    return (
        <>
            <Box style={mainStyle.whiteBoxCollapse}>
                <Pressable style={mainStyle.collapseButton} onPress={() => { setShow(!show) }}>
                    <Box style={mainStyle.collapseButtonContent}>
                        <Heading style={[mainStyle.mediumTitle, mainStyle.collapseButtonText]}> {weekday} <Text style={mainStyle.smallText}>- {fullDate}</Text></Heading>
                        <Box style={mainStyle.collapseButtonIcon}>
                            <Icon name={'angle-' + (show ? 'up' : 'down')} size={16} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                        </Box>
                    </Box>
                </Pressable>

                {/* collapsible */}
                <Box style={collapseStyle}>
                    <Box style={mainStyle.whiteBoxCollapseContent}>
                        <ScrollView horizontal contentContainerStyle={{ minWidth: '100%', width: length * 60 }}>
                            <VStack w={'100%'} space={2}>
                                {header}
                                {!ready ?
                                    <Skeleton.Text /> :
                                    <>
                                        {shiftElements}
                                    </>
                                }
                            </VStack>
                        </ScrollView>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DayBox;