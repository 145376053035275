import React, { useCallback, useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Pressable, Box, Text } from 'native-base';
import { useRecoilState } from 'recoil';
import CalendarOpenState from '../../../Recoil/CalendarOpenState';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import CalendarEditPane from './CalendarEditPane';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import GeneralAction from '../../../Actions/GeneralAction';
import Trans from '../../Components/Trans';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CalendarAddBlock = (props) => {

    const
        firstLoad = useRef(true),
        [refresh, setRefresh] = useState(uuidv4()),
        [showEdit, setShowEdit] = useState(props.open || false),
        [hidden, setHidden] = useState(true),
        [timeLimits, setTimeLimits] = useState({
            start: new Date(new Date(props.date).setHours(9, 0, 0, 0)),
            end: new Date(new Date(props.date).setHours(17, 0, 0, 0))
        }),
        [calendarOpen, setCalendarOpen] = useRecoilState(CalendarOpenState),
        [paneId, setPaneId] = useState(uuidv4())
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            let hiddenState = false;
            let startOn = new Date(props.startOn);

            //hide blocks in the past
            if( new Date(new Date(props.date).setHours(23, 15, 0, 0)) < new Date()){
                hiddenState = true;
            }

            //current day: set start date always at the next quarter of an hour + 30 minutes
            if(startOn < new Date()){

                // Get the current date and time
                let startDate = new Date();
                // Set the minutes to the next quarter of an hour
                let nextQuarterHour = Math.ceil(startDate.getMinutes() / 15) * 15;
                startDate.setMinutes(nextQuarterHour);
                // Add 30 minutes
                startDate.setMinutes(startDate.getMinutes() + 30);

                // Make end date the start date + 8 hours
                let endDate = new Date(startDate.getTime() + (8 * 60 * 60 * 1000));

                setTimeLimits({
                    start: startDate,
                    end: endDate
                });
            }

            setHidden(hiddenState);
        };
        init();
    }, [props]);

    useEffect(() => {
        if (firstLoad.current) {
            onFirstLoad();
            firstLoad.current = false;
        }
    }, [onFirstLoad, refresh])

    //listen if calendar needs to open
    useEffect(() => {
        if (calendarOpen) {
            let openDate = new Date(calendarOpen);
            //check if correct date
            if (GeneralAction.formatDate(openDate.toISOString(), true) === GeneralAction.formatDate(new Date(props.date).toISOString(), true)) {
                setCalendarOpen(false);
                setTimeLimits({
                    start: new Date(openDate),
                    end: new Date(new Date(openDate).setHours(openDate.getHours() + 6))
                });
                setPaneId(uuidv4());
                setShowEdit(true);
            }
        }
    }, [calendarOpen, setCalendarOpen, props.date]);

    return (
        <>
            {hidden ?
                <Box style={{ width: props.width }} />
                :
                <>
                    <Pressable style={{ paddingHorizontal: 1, width: props.width }} onPress={() => {
                        if (typeof props.onPress === 'function') props.onPress();
                        setShowEdit(true);
                    }}>
                        <Box flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={[mainStyle.previewPlaceButtonAddOutline, { textAlign: 'center' }]}>
                            <Icon name={'plus'} size={24} style={{ color: '#00aaff' }} />
                            <Text style={{ color: '#00aaff' }}><Trans>Add shift</Trans></Text>
                        </Box>
                    </Pressable>
                    <CalendarEditPane
                        key={paneId}
                        date={props.date}
                        startOn={timeLimits.start}
                        endOn={timeLimits.end}
                        employeeId={props?.employeeId}
                        departmentId={props?.departmentId}
                        open={showEdit}
                        onClose={() => setShowEdit(false)}
                        reload={() => {
                            if (typeof props.reload === 'function') props.reload();
                        }}
                    />
                </>
            }
        </>
    );
};

export default CalendarAddBlock;
