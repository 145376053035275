import React, {useEffect, useRef, useState, useCallback} from 'react';
import {
  AlertDialog,
  Text,
  FormControl,
  Box,
  VStack,
  Button,
  Checkbox,
  Center,
  useTheme,
} from 'native-base';
import Trans from '../../Components/Trans';
import DateTimePicker from '../../../Libs/DateTimePicker';
import GeneralAction from '../../../Actions/GeneralAction';
import TranslationAction from '../../../Actions/TranslationAction';
import APIAction from '../../../Actions/APIAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import {v4 as uuid} from 'uuid';

const ProjectCopyModal = props => {
  const firstRender = useRef(true),
    headerFooterKey = useRef(uuid()),
    {colors} = useTheme(),
    [copyToDate, setCopyToDate] = useState(
      new Date().setDate(new Date().getDate() + 1),
    ),
    [isRepeat, setIsRepeat] = useState(false),
    [copyConfirm, setCopyConfirm] = useState(false),
    [copyWaiting, setCopyWaiting] = useState(false),
    [copyUntilDate, setCopyUntilDate] = useState(
      new Date().setMonth(new Date().getMonth() + 1),
    ),
    [daysOfWeek, setDaysOfWeek] = useState({
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      7: '',
    }),
    [copyDays, setCopyDays] = useState([]),
    [copyDaysChecks, setCopyDaysChecks] = useState([]),
    [translations, setTranslations] = useState({
      Copy: '',
      Repeat: '',
    });
  const onFirstLoad = useCallback(() => {
    const init = async () => {
      let newDaysOfWeek = {};
      let dateOfWeek = new Date();
      dateOfWeek.setDate(dateOfWeek.getDate() - dateOfWeek.getDay() + 1);

      for (let i = 1; i <= 7; i++) {
        let value = await GeneralAction.formatLocal(dateOfWeek, {
          weekday: 'long',
        });
        newDaysOfWeek[i] = value;
        dateOfWeek.setDate(dateOfWeek.getDate() + 1);
      }

      setDaysOfWeek(newDaysOfWeek);

      //set translations
      let newTranslations = await TranslationAction.translateInLine(
        Object.keys(translations),
      );
      setTranslations(newTranslations);

      setCopyDaysChecks(
        Object.keys(newDaysOfWeek).map((day, index) => {
          return (
            <Checkbox value={day}>
              <Text>{newDaysOfWeek[day]}</Text>
            </Checkbox>
          );
        }),
      );
    };
    if (translations['Copy'] === '' || translations['Repeat'] === '') {
      init();
    }
  }, [setDaysOfWeek, setTranslations, translations]);

  useEffect(() => {
    if (firstRender.current && props.isOpen) {
      firstRender.current = false;
      onFirstLoad();
    }
  }, [onFirstLoad, props.isOpen]);

  const copyProject = async () => {
    setCopyWaiting(true);
    let copyToDateString = new Date(copyToDate).toISOString();

    //create url
    let url = `/api/projects/${props.project.id}/copy-to-date/${copyToDateString}`;

    //create params
    let params = null;

    //is repeat
    if (isRepeat) {
      //check if days selected
      if (copyDays.length === 0) {
        await GeneralAction.toast(
          'danger',
          <Trans>Please select at least one day</Trans>,
        );
      }

      let copyUntilDateString = new Date(copyUntilDate).toISOString();
      params = {
        repeatUntil: copyUntilDateString,
        repeatDays: JSON.stringify(copyDays),
      };
    }

    //send request
    let response = await APIAction.request({
      method: 'GET',
      url: url,
      params: params,
    });

    await RequestCacheAction.clear({url: 'projects'});
    if (typeof props.onReload === 'function') {
      props.onReload();
    }
  };

  // Removed it so transition works normally
  // if (!props.isOpen) return <></>;

  return (
    <AlertDialog
      isOpen={props.isOpen}
      onClose={() => {
        setCopyConfirm(false);
        setCopyToDate(new Date().setDate(new Date().getDate() + 1));
        setIsRepeat(false);
        setCopyDays([]);
        props.onClose();
      }}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header key={headerFooterKey.current + '1'}>
          {isRepeat ? (
            <Text>{translations.Repeat}</Text>
          ) : (
            <Text>{translations.Copy}</Text>
          )}
        </AlertDialog.Header>
        <AlertDialog.Body>
          <VStack space={2}>
            {!copyConfirm && (
              <Center>
                <Button.Group isAttached>
                  <Button
                    variant={isRepeat ? 'outline' : 'solid'}
                    onPress={() => {
                      setIsRepeat(false);
                      headerFooterKey.current = uuid();
                    }}>
                    <Text color={isRepeat ? colors['primary']['600'] : '#fff'}>
                      <Trans>Copy</Trans>
                    </Text>
                  </Button>
                  <Button
                    variant={isRepeat ? 'solid' : 'outline'}
                    onPress={() => {
                      setIsRepeat(true);
                      headerFooterKey.current = uuid();
                    }}>
                    <Text color={isRepeat ? '#fff' : colors['primary']['600']}>
                      <Trans>Repeat</Trans>
                    </Text>
                  </Button>
                </Button.Group>
              </Center>
            )}
            {copyConfirm ? (
              <Box>
                {isRepeat ? (
                  <>
                    <Text>
                      <Trans>Repeat</Trans>{' '}
                      <Text bold>
                        {props.project.name} <Trans>of</Trans>{' '}
                        {GeneralAction.formatDate(props.project.startDate)}
                      </Text>
                    </Text>
                    <Text>
                      <Trans>on the following days:</Trans>
                    </Text>
                    {copyDays.sort().map((day, index) => {
                      return (
                        <Text>
                          - <Text bold>{daysOfWeek[day]}</Text>
                        </Text>
                      );
                    })}
                    <Text>
                      <Trans>Starting from</Trans>{' '}
                      <Text bold>{GeneralAction.formatDate(copyToDate)}</Text>{' '}
                      <Trans>and repeat this until</Trans>{' '}
                      <Text bold>
                        {GeneralAction.formatDate(copyUntilDate)}
                      </Text>
                      .
                    </Text>
                  </>
                ) : (
                  <Text>
                    <Trans>Copy</Trans> {props.project.name} <Trans>from</Trans>{' '}
                    {GeneralAction.formatDate(props.project.startDate)}{' '}
                    <Trans>to</Trans> {GeneralAction.formatDate(copyToDate)}?
                  </Text>
                )}
              </Box>
            ) : (
              <Box>
                <VStack space={2}>
                  {!isRepeat && (
                    <Box>
                      <FormControl>
                        <FormControl.Label>
                          <Text>
                            <Trans>Copy to</Trans>
                          </Text>
                        </FormControl.Label>
                        <DateTimePicker
                          mode={'date'}
                          value={new Date(copyToDate)}
                          minimumDate={new Date().setDate(
                            new Date().getDate() + 1,
                          )}
                          onChange={date => {
                            setCopyToDate(new Date(date));
                          }}
                        />
                      </FormControl>
                    </Box>
                  )}

                  {isRepeat && (
                    <>
                      <Box>
                        <FormControl>
                          <FormControl.Label>
                            <Text>
                              <Trans>For days</Trans>
                            </Text>
                          </FormControl.Label>
                          <Checkbox.Group
                            onChange={values => {
                              setCopyDays(values);
                            }}>
                            {copyDaysChecks}
                          </Checkbox.Group>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormControl.Label>
                            <Text>
                              <Trans>Repeat from</Trans>
                            </Text>
                          </FormControl.Label>
                          <DateTimePicker
                            mode={'date'}
                            value={new Date(copyToDate)}
                            minimumDate={new Date().setDate(
                              new Date().getDate() + 1,
                            )}
                            onChange={date => {
                              setCopyToDate(new Date(date));
                            }}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormControl.Label>
                            <Text>
                              <Trans>Repeat until</Trans>
                            </Text>
                          </FormControl.Label>
                          <DateTimePicker
                            mode={'date'}
                            value={new Date(copyUntilDate)}
                            minimumDate={new Date(copyToDate)}
                            maximumDate={new Date(copyToDate).setMonth(
                              new Date(copyToDate).getMonth() + 6,
                            )}
                            onChange={date => {
                              setCopyUntilDate(new Date(date));
                            }}
                          />
                        </FormControl>
                      </Box>
                    </>
                  )}
                </VStack>
              </Box>
            )}
          </VStack>
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2} key={headerFooterKey.current + '2'}>
            <Button
              variant={'ghost'}
              onPress={() => {
                setCopyConfirm(false);
                setCopyToDate(new Date().setDate(new Date().getDate() + 1));
                setIsRepeat(false);
                setCopyDays([]);
                props.onClose();
              }}>
              <Text>
                <Trans>Cancel</Trans>
              </Text>
            </Button>
            {copyConfirm ? (
              <Button
                colorScheme={'primary'}
                onPress={() => {
                  copyProject();
                }}
                isDisabled={copyWaiting}
                isLoading={copyWaiting}>
                <Text color={'#fff'}>
                  <Trans>Confirm</Trans>
                </Text>
              </Button>
            ) : (
              <Button
                isDisabled={isRepeat && copyDays.length === 0}
                colorScheme={'primary'}
                onPress={() => {
                  setCopyConfirm(true);
                }}>
                {isRepeat ? (
                  <Text color={'#fff'}>{translations.Repeat}</Text>
                ) : (
                  <Text color={'#fff'}>{translations.Copy}</Text>
                )}
              </Button>
            )}
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ProjectCopyModal;
