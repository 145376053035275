import React, { useCallback, useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { VStack } from 'native-base';
import { useRecoilState } from 'recoil';
import CalendarOpenState from '../../../Recoil/CalendarOpenState';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import CalendarAddBlock from './CalendarAddBlock';
import CalendarShiftBlock from './CalendarShiftBlock';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CalendarRow = (props) => {

    const
        [shiftBlocks, setShiftBlocks] = useState([]),
        [calendarOpen, setCalendarOpen] = useRecoilState(CalendarOpenState);
        ;

    const firstRender = useRef(true);

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            const startTime = new Date(props.startOn);
            const endTime = new Date(props.endOn);
            const ratio = (100 / (endTime.getTime() - startTime.getTime()));

            //add items

            let shiftElements = [];
            let currTime = new Date(startTime);
            let i = 0;
            let emptyEmployee = false;
            for (let shift of props.shifts) {

                let shiftStartOn = new Date(shift.shift.startOn);
                let shiftEndOn = new Date(shift.shift.endOn);

                let preDuration = new Date(shiftStartOn).getTime() - new Date(currTime).getTime();
                let duration = new Date(shiftEndOn).getTime() - new Date(shiftStartOn).getTime();

                let id = uuidv4();
                // shiftElements.push(
                //     <CalendarAddBlock
                //         key={id}
                //         edge={i === 0 ? 'start' : 'between'}
                //         date={props.date}
                //         startOn={new Date(currTime)}
                //         endOn={new Date(shiftStartOn)}
                //         employeeId={props?.employeeId}
                //         departmentId={props?.departmentId}
                //         width={(preDuration * ratio) + '%'}
                //         reload={() => {
                //             if (typeof props.reload === 'function') props.reload();
                //         }}
                //     />
                // );

                //add shift
                shiftElements.push(
                    <CalendarShiftBlock
                        key={uuidv4()}
                        date={props.date}
                        shift={shift}
                        width={'100%'}//{(duration * ratio) + '%'}
                        reload={() => {
                            if (typeof props.reload === 'function') props.reload();
                        }}
                    />
                );

                //update currtime
                currTime = new Date(shiftEndOn);
                i++;

                emptyEmployee = shift.employee == undefined;
            };

            if(!emptyEmployee){
                //add last item
                let postDuration = new Date(endTime).getTime() - new Date(currTime).getTime();
                if (postDuration > 0 && props.isLastRow) {
                    shiftElements.push(
                        <CalendarAddBlock
                            key={uuidv4()}
                            date={props.date}
                            open={props.open || false}
                            startOn={new Date(currTime)}
                            endOn={new Date(endTime)}
                            employeeId={props?.employeeId}
                            departmentId={props?.departmentId}
                            width={'100%'}
                            reload={() => {
                                if (typeof props.reload === 'function') props.reload();
                            }}
                        />
                    );
                }
                }

            //set in state
            setShiftBlocks(shiftElements);
        }
        init();
    }, [props]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad]);

    //return
    return (
        <VStack width={'100%'}>
            {shiftBlocks}
        </VStack>
    );
};

export default CalendarRow;
