import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Divider, Text, Heading, View, Stack } from 'native-base';
import GeneralAction from '../../Actions/GeneralAction';
import APIAction from '../../Actions/APIAction';
import main from '../../Assets/Styles/main.json';
import TranslationAction from '../../Actions/TranslationAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import EmployeeItem from "../Organization/Components/EmployeeItem";
import { v4 as uuidv4 } from "uuid";

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Details = (props) => {
    const job = props.job;

    //let [logo, setLogo] = useState('');
    let [language, setLanguage] = useState(null);
    let [address, setAddress] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Get language
            setLanguage(await TranslationAction.getSelectedLanguage());

            // Get address
            if (job.shift && job.shift.address) {
                // By default, pick address of shift
                setAddress(job.shift.address);
            } else if (job.shift && job.shift.project && job.shift.project.client) {
                let client = job.shift.project.client;

                if (client.addressBilling) {
                    // Pick billing address of client if we don't have a shift address
                    setAddress(client.addressBilling);
                } else if (client.addresses && client.addresses[0]) {
                    // Pick first address of client if no billing address
                    setAddress(client.addresses[0]);
                }
            }

            // Get logo company
            /*let logoResult = null;
            if (job.shift.project.client.logo) {
              logoResult = await APIAction.request({method: 'get', url: job.shift.project.client.logo});
            }
      
            // Default style/source
            let imageStyle = {
              resizeMode: "contain",
              height: 100,
              width: 100
            };
      
            let source = require('../../../../Assets/Images/logo.png');
      
            // Set path of logo as source
            if (logoResult) {
              source = {
                uri: logoResult.path
              };
            }
      
            // Create logo element
            setLogo(
              <Image
                style={imageStyle}
                alt={job.shift.project.client.name}
                source={source}
              />
            ); */
        };
        fetchData();
    }, [job]);

    return (

        <Box style={mainStyle.boxItem}>
            <Box style={{ flex: 1 }}>
                <Box style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    paddingTop: 0,
                    paddingBottom: 10,
                }}>
                    <Box style={mainStyle.dateDayLabelWrapper}>
                        <Text
                            style={mainStyle.dateDayLabel}>{language && GeneralAction.formatDateFull(job.shift.startOn, language)}</Text>
                    </Box>
                    <Box style={mainStyle.timeLabelWrapper}>
                        <Text
                            style={mainStyle.timeLabel}>{GeneralAction.formatTime(job.arrivedAt ? job.arrivedAt : job.shift.startOn)} - {GeneralAction.formatTime(job.leftAt ? job.leftAt : job.shift.endOn)}</Text>
                    </Box>
                    {/*<Box style={mainStyle.iconDevice}>
                        <BrandsIcon name={'android'} size={24} style={{color:'#00aaff'}} />
                    </Box>*/}
                </Box>
                <Divider my="2" style={mainStyle.dividerStyle} />
                <Stack
                    direction={{
                        md: 'row',
                        base: 'column'
                    }}
                    alignItems={{
                        md: 'center',
                        base: 'flex-start'
                    }}
                    justifyContent={'space-between'}
                    space={{
                        md: 0,
                        base: 2
                    }}
                >
                    <Box>
                        <Heading style={[mainStyle.mediumTitle, {
                            textAlign: 'center',
                            fontSize: 20,
                        }]}>{job.shift.position.alias ? job.shift.position.alias : job.shift.position.name}</Heading>
                    </Box>
                    <EmployeeItem key={uuidv4()} data={job.employee} viewOnly={true} noPressable={true} />
                </Stack>
            </Box>
        </Box>
    );
};

export default Details;
