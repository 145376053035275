import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { VStack, Box, Heading, FormControl, Input, Divider, Spinner } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import main from '../../Assets/Styles/main.json';
import iconConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import JobTypeItem from './Components/JobTypeItem';
import DepartmentItem from './Components/DepartmentItem';
import { v4 as uuidv4 } from 'uuid';
import TranslationAction from '../../Actions/TranslationAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(iconConfig);

const SettingScreen = ({ navigation }) => {

    const
        isFirstLoad = useRef(true),
        [translations, setTranslations] = useState({
            'Search': ''
        }),
        [jobTypes, setJobTypes] = useState(null),
        allJobTypes = useRef(null),
        [departments, setDepartments] = useState(null),
        allDepartments = useRef(null),
        [jobSearch, setJobSearch] = useState(''),
        [departmentSearch, setDepartmentSearch] = useState('')
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            // Get positions
            let apiPositions = await APIAction.request({ method: 'GET', url: '/api/positions', cache: true });

            if ('hydra:member' in apiPositions) {
                apiPositions = apiPositions['hydra:member'];

                let jobTypeItems = [];
                let allTypeItems = {};
                for (let apiPosition of apiPositions) {

                    let uuid = uuidv4();
                    let jobTypeItem =
                        <JobTypeItem key={uuid} data={apiPosition} onName={(name) => {
                            allJobTypes.current[uuid]['name'] = name;
                        }} />;

                    jobTypeItems.push(jobTypeItem);
                    allTypeItems[uuid] = {
                        item: jobTypeItem,
                        name: ''
                    };

                }

                setJobTypes(jobTypeItems);
                allJobTypes.current = allTypeItems;
            }

            // Get departments
            let apiDepartments = await APIAction.request({ method: 'GET', url: '/api/departments', cache: true });

            // Process results
            if ('hydra:member' in apiDepartments) {
                apiDepartments = apiDepartments['hydra:member'];

                let departmentItems = [];
                let allDepartmentItems = {};
                for (let apiDepartment of apiDepartments) {

                    let uuid = uuidv4();
                    let departmentItem = 
                        <DepartmentItem key={uuid} data={apiDepartment} />;

                    departmentItems.push(departmentItem);
                    allDepartmentItems[uuid] = {
                        item: departmentItem,
                        name: apiDepartment.name
                    };
                }

                setDepartments(departmentItems);
                allDepartments.current = allDepartmentItems;
            }
        };
        init();
    }, []);

    useEffect(() => {

        const translate = async () => {
            let newTranslations = await TranslationAction.translateInLine(Object.keys(translations));
            setTranslations(newTranslations);
        };

        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            onFirstLoad();
            translate();
        }

    }, [onFirstLoad, translations]);

    //when job search value is entered
    useEffect(() => {
        if (allJobTypes.current !== null) {
            //clean jobsearch
            let cleanSearch = jobSearch.trim().toLowerCase();
            cleanSearch = cleanSearch.replace(/[^a-z0-9]/g, '');

            //init result
            let result = [];

            if (cleanSearch !== '') {
                for (let [index, value] of Object.entries(allJobTypes.current)) {
                    let cleanName = value.name.trim().toLowerCase().replace(/[^a-z0-9]/g, '');
                    if (cleanName.includes(cleanSearch)) {
                        result.push(value.item);
                    }
                }
            } else {
                for (let [index, value] of Object.entries(allJobTypes.current)) {
                    result.push(value.item);
                }
            }
            setJobTypes(result);
        }
    }, [jobSearch])

    //when department value is entered
    useEffect(() => {
        if (allDepartments.current !== null) {
            //clean jobsearch
            let cleanSearch = departmentSearch.trim().toLowerCase();
            cleanSearch = departmentSearch.replace(/[^a-z0-9]/g, '');

             //init result
             let result = [];

             if (cleanSearch !== '') {
                 for (let [index, value] of Object.entries(allDepartments.current)) {
                     let cleanName = value.name.trim().toLowerCase().replace(/[^a-z0-9]/g, '');
                     if (cleanName.includes(cleanSearch)) {
                         result.push(value.item);
                     }
                 }
             } else {
                 for (let [index, value] of Object.entries(allDepartments.current)) {
                     result.push(value.item);
                 }
             }
             setDepartments(result);
        }
    }, [departmentSearch]);

    return (
        <AppContainer>
            <VStack
                space={2}
            >
                <Box style={mainStyle.pageTitleWrapper}>
                    <Box style={{ flexGrow: 1 }}>
                        <Heading style={mainStyle.pageTitle}><Trans>Type jobs</Trans></Heading>
                    </Box>
                </Box>
                <Box style={mainStyle.whiteBox}>
                    <VStack space={2}>
                        {/*<FormControl>
                            <Input
                                isDisabled={jobTypes === null}
                                onChangeText={(value) => {
                                    setJobSearch(value);
                                }}
                                placeholder={translations['Search']}
                            />
                        </FormControl>
                            <Divider />*/}
                        {jobTypes === null ?
                            <Spinner />
                            :
                            jobTypes
                        }
                    </VStack>
                </Box>
                <Box style={mainStyle.pageTitleWrapper}>
                    <Box style={{ flexGrow: 1 }}>
                        <Heading style={mainStyle.pageTitle}><Trans>Departments</Trans></Heading>
                    </Box>
                </Box>
                <Box style={mainStyle.whiteBox}>
                    <VStack space={2}>
                        {/*<FormControl>
                            <Input
                                isDisabled={departments === null}
                                onChangeText={(value) => {
                                    setDepartmentSearch(value);
                                }}
                                placeholder={translations['Search']}
                            />
                        </FormControl>
                            <Divider />*/}
                        {departments === null ?
                            <Spinner />
                            :
                            departments
                        }
                    </VStack>
                </Box>
            </VStack>
        </AppContainer>
    );
};

export default SettingScreen;