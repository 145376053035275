import React from 'react';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Box, Heading, HStack } from 'native-base';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DepartmentItem = (props) => {
    return (
        <Box>
            <HStack
                space={2}
                alignItems={'center'}
            >
                <Icon name={'location-arrow'} size={24} />
                <Heading style={mainStyle.listDataTitle}>{props.data.name}</Heading>
            </HStack>
        </Box>
    );
};

export default DepartmentItem;
