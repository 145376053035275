import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { Box, Heading, Button, Text, FormControl, Input, useBreakpointValue, VStack, Stack, HStack, Center, Skeleton } from 'native-base';
import { Select } from '../../../Libs/CustomInputs';
import AppContainer from '../../Components/AppContainer';
import CardScanner from '../../../Libs/CardScanner';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import ScaledImage from '../../Components/ScaledImage';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import { useIsFocused } from '@react-navigation/native';
import FileFunctions from '../../../Libs/FileFunctions/index';
import { v4 as uuidv4 } from 'uuid';
import FormAction from '../../../Actions/FormAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const BankDetailsScreen = ({ navigation, route }) => {

    const [user, setUser] = useState([]);
    const [initialLoaded, setInitialLoaded] = useState(false);

    const [scannerKey, setScannerKey] = useState(uuidv4());
    const [scannerVisible, setScannerVisible] = useState(false);
    const [scannerData, setScannerData] = useState({
        title: '',
        onPhoto: () => {
        },
    });
    const [cardImageSrc, setCardImageSrc] = useState({ uri: null });
    const [cardImageExtraSrc, setCardImageExtraSrc] = useState({ uri: null });
    const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width <= 1024 ? Dimensions.get('window').width : 1024);
    const [selectMonths, setSelectMonths] = useState([]);
    const [selectYears, setSelectYears] = useState([]);
    const [expiryMonth, setExpiryMonth] = useState(null);
    const [expiryYear, setExpiryYear] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');

    // Init
    const isFocused = useIsFocused();
    let [id, setId] = useState(null);

    const maxImageWidth = useBreakpointValue({
        base: 1,
        lg: 2
    });

    useEffect(() => {
        const fetchData = async () => {
            // Get user
            if (!initialLoaded) {
                let apiUser = await APIAction.request({ method: 'GET', url: `/api/users/${route.params.id}`, cache: false });
                setUser(apiUser);
                setInitialLoaded(true);

                if (apiUser.employee) {
                    setIban(apiUser.employee.iban);
                    setBic(apiUser.employee.bic);
                }

            } else {
                if (isFocused) {
                    let months = [];
                    for (let i = 1; i <= 12; i++) {
                        let val = (i + '').padStart(2, '0');
                        months.push(<Select.Item key={uuidv4()} label={val} value={val} />);
                    }
                    setSelectMonths(months);

                    let years = [];
                    let currentYear = new Date().getFullYear();
                    for (let i = currentYear; i <= (currentYear + 20); i++) {
                        years.push(<Select.Item key={uuidv4()} label={i + ''} value={i + ''} />);
                    }
                    setSelectYears(years);

                    // Get data of existing verification
                    if (!id) {
                        let verification = await APIAction.request({
                            method: 'GET',
                            url: '/api/payment_verifications/get/open',
                            params: {
                                employeeId: user.employee.id,
                            },
                        });

                        if (verification) {
                            setId(verification.id);
                            let date = new Date(verification.expiryDate);

                            if (date) {
                                let month = ((date.getMonth()) + 1).toString().padStart(2, (0));
                                setExpiryMonth(month);
                                setExpiryYear((date.getFullYear()).toString());
                            }


                            let imageEntity = await FileFunctions.download({ url: verification.imageCard });

                            if (imageEntity) {
                                setCardImageSrc(imageEntity);
                            }

                            let imageExtraEntity = await FileFunctions.download({ url: verification.imageCardExtra });

                            if (imageExtraEntity) {
                                setCardImageExtraSrc(imageExtraEntity);
                            }
                        }
                    }
                }
            }

        };

        fetchData();
    }, [id, isFocused, expiryYear, expiryMonth, user, route.params.id, initialLoaded]);

    /**
     * Open scanner for cards
     * @param {*} side
     */
    const openScanner = async (side) => {
        if (side === 'front') {
            setScannerKey(uuidv4());
            setScannerData({
                title: <Trans>Scan front of card</Trans>,
                onPhoto: (result) => {
                    setCardImageSrc(result);
                },
            });
        } else {
            setScannerKey(uuidv4());
            setScannerData({
                title: <Trans>Scan back of card</Trans>,
                onPhoto: (result) => {
                    setCardImageExtraSrc(result);
                },
            });
        }
        setScannerVisible(true);
    };

    /**
     * Check and post form
     */
    const checkForm = async () => {
        let error = false;

        if (cardImageSrc.uri === null) {
            error = true;
            GeneralAction.toast('error', <Trans>Upload front of payment card</Trans>);
        }

        if (
            (expiryMonth === null) ||
            (expiryYear === null)
        ) {
            error = true;
            GeneralAction.toast('error', <Trans>Fill in expiry date</Trans>);
        }

        // Check if empty
        if (!FormAction.validateEmpty(iban) || !FormAction.validateIban(iban)) {
            error = true;
            GeneralAction.toast('error', <Trans>Please enter your IBAN number</Trans>);
        }

        // Check if empty
        if (!FormAction.validateEmpty(bic) || !FormAction.validateBic(bic)) {
            error = true;
            GeneralAction.toast('error', <Trans>Please enter your BIC number</Trans>);
        }

        if (!error) {
            let now = new Date();
            if ((now.getFullYear() + '') === expiryYear) {
                if ((now.getMonth() + 1) >= parseInt(expiryMonth)) {
                    error = true;
                    GeneralAction.toast('error', <Trans>Invalid expiry date</Trans>);
                }
            }
        }

        //if no errors, post
        if (!error) {
            //data object
            let data = {
                imageCard: cardImageSrc,
                expiryDate: expiryYear + '-' + expiryMonth + '-01',
                userId: user.id,
            };

            if (id) {
                data.id = id;
            }

            if (cardImageExtraSrc && cardImageExtraSrc.uri) {
                data.imageCardExtra = cardImageExtraSrc;
            }

            setDisableBtn(true);

            // Make call to verify payment
            await APIAction.request({
                method: 'post',
                url: '/api/verify/payment',
                body: data,
                type: 'multipart/form-data',
            });

            // Update employee with completed steps
            let nameStep = 'paymentVerified';
            let completedSteps = user.employee.completedSteps;
            let employeeUrl = '/api/employees/' + user.employee.id;
            if (!completedSteps) {
                completedSteps = [];
            }

            if (!completedSteps.includes(nameStep)) {
                completedSteps.push(nameStep);
            }

            let employeeData = {
                completedSteps: completedSteps,
                bic: bic,
                iban: iban,
            };

            await APIAction.request({ method: 'PATCH', url: employeeUrl, body: employeeData });

            ///GeneralAction.toast('success', <Trans>Verification request send</Trans>);

            setTimeout(() => {
                navigation.replace('CompleteProfile', { 'id': user.id });
            }, 2000);
        }
    };

    /**
     * Listen to dimentions changes
     */
    Dimensions.addEventListener('change', ({ window }) => {
        setWindowWidth(window.width <= 1024 ? window.width : 1024);
    });

    return (
        <AppContainer>
            {initialLoaded ?
                <VStack space={2}>
                    <Box style={mainStyle.pageTitleWrapper}>
                        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                            <Heading style={mainStyle.pageTitle}><Trans>Verify bank details</Trans></Heading>
                        </Box>
                    </Box>

                    {/* Input fields */}
                    <Stack
                        space={2}
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                    >
                        <Box flexGrow={1} flexShrink={1}>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>IBAN</Trans></Text></FormControl.Label>
                                <Input w="100%" defaultValue={iban} onChangeText={(value) => setIban(value.replace(/\s+/g, ''))} />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>BIC</Trans></Text></FormControl.Label>
                                <Input w="100%" defaultValue={bic} onChangeText={(value) => setBic(value.replace(/\s+/g, ''))} />
                            </FormControl>
                        </Box>
                    </Stack>

                    <Stack
                        space={2}
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                    >
                        {/* Card image */}
                        <Box flex={1}>
                            <VStack space={2}>
                                <Button colorScheme={'primary'} onPress={() => openScanner('front')}>
                                    <Box style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        minWidth: '100%',
                                    }}>
                                        <Box>
                                            <Icon color={'#fff'} size={30} name={'money-check'} />
                                        </Box>
                                        <Box marginLeft={3}>
                                            <Text color={'#fff'}><Trans>Upload front payment card</Trans></Text>
                                        </Box>
                                    </Box>
                                </Button>

                                <Center>
                                    <ScaledImage
                                        uri={cardImageSrc.uri}
                                        width={(windowWidth / maxImageWidth) - 0.2 * (windowWidth / maxImageWidth)}
                                        maxHeight={200}
                                    />
                                </Center>
                            </VStack>
                        </Box>

                        {/* Card image */}
                        <Box flex={1}>
                            <VStack space={2}>
                                <Button colorScheme={'primary'} onPress={() => openScanner('back')}>
                                    <Box style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        minWidth: '100%',
                                    }}>
                                        <Box>
                                            <Icon color={'#fff'} size={30} name={'money-check'} />
                                        </Box>
                                        <Box marginLeft={3}>
                                            <Text color={'#fff'}><Trans>Upload back payment card</Trans></Text>
                                        </Box>
                                    </Box>
                                </Button>

                                <Center>
                                    <ScaledImage
                                        uri={cardImageExtraSrc.uri}
                                        width={(windowWidth / maxImageWidth) - 0.2 * (windowWidth / maxImageWidth)}
                                        maxHeight={200}
                                    />
                                </Center>
                            </VStack>
                        </Box>
                    </Stack>

                    {/* Expiry date */}
                    <HStack space={2}>
                        <Box
                            flex={1}
                        >
                            <Box>
                                <FormControl.Label isRequired><Text><Trans>Expiry date: Month</Trans></Text></FormControl.Label>
                                <Select
                                    placeholder={'MM'}
                                    selectedValue={expiryMonth}
                                    onValueChange={value => setExpiryMonth(value)}
                                >
                                    {selectMonths}
                                </Select>
                            </Box>
                        </Box>

                        <Box
                            flex={1}
                        >
                            <Box>
                                <FormControl.Label isRequired><Text><Trans>Year</Trans></Text></FormControl.Label>
                                <Select
                                    placeholder={'YYYY'}
                                    selectedValue={expiryYear}
                                    onValueChange={value => setExpiryYear(value)}
                                >
                                    {selectYears}
                                </Select>
                            </Box>
                        </Box>
                    </HStack>

                    {/* Submit */}
                    <Box>
                        <Button
                            isDisabled={disableBtn}
                            isLoading={disableBtn}
                            colorScheme={'success'}
                            onPress={() => {
                                checkForm();
                            }}
                        >
                            <Text color={'#fff'}><Trans>Done</Trans></Text>
                        </Button>
                    </Box>
                </VStack>
                :
                <VStack space={2}>
                    <Skeleton h={'20'} />
                    <Skeleton h={'20'} />
                    <Skeleton h={'20'} />
                </VStack>
            }

            {/* Scanners */}
            <CardScanner
                key={scannerKey}
                title={scannerData.title}
                isOpen={scannerVisible}
                onClose={() => setScannerVisible(false)}
                onPhoto={scannerData.onPhoto}
            />
        </AppContainer>
    );
};

export default BankDetailsScreen;
