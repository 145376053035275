import React from 'react';
import {Box, Pressable} from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { v4 as uuidv4 } from 'uuid';
import RowItem from '../Job/RowItem';

const JobView = (props) => {
    // Init
    const job = props.job;
    const navigation = useNavigation();
    let action = props.action;

    // Default action
    if (!action) {
        action = 'approve';
    }

    /**
     * Open the correct detail page based on action
     */
    let openDetailView = () => {
        // Open page to approve job
        if (action === 'approve') {
            navigation.navigate('ApproveJob', {
                id: job.id,
            });
        }
    };

    return (
        <Box
            sytle={{ paddingLeft: 10 }}
            // onPress={() => {
            //     openDetailView();
            // }}
        >
            <RowItem key={uuidv4()} job={job} />
        </Box>
    );
};

export default JobView;
