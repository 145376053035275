import React, { useEffect, useState, useRef, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Box, Heading, HStack, Skeleton } from 'native-base';
import APIAction from '../../../Actions/APIAction';
import UserAction from '../../../Actions/UserAction';
import GeneralAction from "../../../Actions/GeneralAction";

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobTypeItem = (props) => {

    const
        firstLoad = useRef(true),
        [name, setName] = useState(null),
        [loaded, setLoaded] = useState(false)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {

            // Get client of user
            let dbUser = await UserAction.getUser();
            let client = dbUser.client;

            let clientUrl = client.split('/');
            let clientId = clientUrl.pop();

            // Get current selected alias
            let newName = (await GeneralAction.getAlias(props.data, clientId));

            setName(newName);
            setLoaded(true);

            // Give back name on load
            if (typeof props.onName === 'function') {
                props.onName(newName);
            }
        };
        init();
    }, [props]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad]);

    return (
        <Box>
            {loaded ?
                <HStack
                    space={2}
                    alignItems={'center'}
                >
                    <Icon name={'suitcase'} size={24} />
                    <Heading style={mainStyle.listDataTitle}>{name}</Heading>
                </HStack>
                :
                <Skeleton h={10} />
            }
        </Box>
    );
};

export default JobTypeItem;
