import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Stagger, HStack, IconButton, Text, useDisclose } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import Trans from '../../Components/Trans';

import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CalendarSwitcher = (props) => {

    const { isOpen, onToggle } = useDisclose();

    let icon = 'calendar';
    switch (props.selected) {
        case 'day':
            icon = 'calendar-with-day-focus';
            break;
        case 'week':
            icon = 'calendar-with-week-focus';
            break;
        case 'month':
            icon = 'calendar';
            break;
    };

    return (
        <Box style={props.style}>
            <Stagger
                visible={isOpen}
                initial={{
                    opacity: 0,
                    scale: 0,
                    translateY: 34
                }} animate={{
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                    transition: {
                        type: "spring",
                        mass: 0.8,
                        stagger: {
                            offset: 30,
                            reverse: true
                        }
                    }
                }} exit={{
                    translateY: 34,
                    scale: 0.5,
                    opacity: 0,
                    transition: {
                        duration: 100,
                        stagger: {
                            offset: 30,
                            reverse: true
                        }
                    }
                }}
            >
                <>
                    {(isOpen && icon !== 'calendar-with-day-focus') &&
                        <HStack space={2} mb={2} alignItems={'center'} alignSelf={'flex-end'}>
                            <Box
                                p={2}
                                m={0}
                                borderRadius={'full'}
                                backgroundColor={'#fff'}
                                shadow={'5'}
                            >
                                <Text style={[mainStyle.smallText]} p={0} m={0}><Trans>Day overview</Trans></Text>
                            </Box>
                            <IconButton
                                shadow={'5'}
                                variant={'subtle'}
                                colorScheme={'primary'}
                                borderRadius={'full'}
                                icon={<Icon color={'#00aaff'} name={'calendar-with-day-focus'} size={34} />}
                                onPress={() => {
                                    if (typeof props.onTrigger === 'function') props.onTrigger('day');
                                }}
                            />
                        </HStack>
                    }
                </>
                <>
                    {(isOpen && icon !== 'calendar-with-week-focus') &&
                        <HStack space={2} mb={2} alignItems={'center'} alignSelf={'flex-end'}>
                            <Box
                                p={2}
                                m={0}
                                borderRadius={'full'}
                                backgroundColor={'#fff'}
                                shadow={'5'}
                            >
                                <Text style={[mainStyle.smallText]} p={0} m={0}><Trans>Week overview</Trans></Text>
                            </Box>
                            <IconButton
                                shadow={'5'}
                                variant={'subtle'}
                                colorScheme={'primary'}
                                borderRadius={'full'}
                                icon={<Icon color={'#00aaff'} name={'calendar-with-week-focus'} size={34} />}
                                onPress={() => {
                                    if (typeof props.onTrigger === 'function') props.onTrigger('week');
                                }}
                            />
                        </HStack>
                    }
                </>
                <>
                    {(isOpen && icon !== 'calendar') &&
                        <HStack space={2} mb={2} alignItems={'center'} alignSelf={'flex-end'}>
                            <Box
                                p={2}
                                m={0}
                                borderRadius={'full'}
                                backgroundColor={'#fff'}
                                shadow={'5'}
                            >
                                <Text style={[mainStyle.smallText]} p={0} m={0}><Trans>Month overview</Trans></Text>
                            </Box>
                            <IconButton
                                shadow={'5'}
                                variant={'subtle'}
                                borderRadius={'full'}
                                icon={<Icon color={'#00aaff'} name={'calendar'} size={34} />}
                                onPress={() => {
                                    if (typeof props.onTrigger === 'function') props.onTrigger('month');
                                }}
                            />
                        </HStack>
                    }
                </>
            </Stagger>
            <IconButton
                shadow={'5'}
                alignSelf={'flex-end'}
                variant={'solid'}
                borderRadius={'full'}
                icon={<Icon color={'#fff'} name={icon} size={34} />}
                onPress={onToggle}
            />
        </Box>
    );
};

export default CalendarSwitcher;