import React, { useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Image, Heading, Button, FormControl, Input, Pressable, Modal } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';

import main from '../../Assets/Styles/main.json';

import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import UserAction from '../../Actions/UserAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create({ ...main });

const LoginScreen = ({ navigation }) => {

    let [email, setEmail] = useState();
    let [password, setPassword] = useState();
    let [viewPassword, setViewPassword] = useState(false);
    let [invalid, setIvalid] = useState(false);
    let [loading, setLoading] = useState(false);
    let [adminModal, setAdminModal] = useState(false);

    let emailRef = useRef();
    let passwordRef = useRef();

    const submitLogin = async () => {
        setLoading(true);
        let result = await APIAction.login(email, password);
        setIvalid(!result);
        setLoading(false);
        if (!result) {
            GeneralAction.toast('error', <Trans>Wrong login credentials</Trans>);
        } else {

            let user = await UserAction.getUser();

            if (user.client) {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'App' }],
                });
            } else {
                setAdminModal(true);
            }
        }
    };

    return (
        <AppContainer
            noGradient
            noTabBar
            footer={
                <Box style={mainStyle.registrationAction}>
                    <Box style={{ width: '100%' }}>
                        <Button
                            style={{ backgroundColor: "#00aaff", borderRadius: 10, width: '100%' }}
                            isLoading={loading}
                            isDisabled={loading}
                            onPress={submitLogin}
                        >
                            <Text style={{ color: '#fff', fontWeight: "600", fontSize: 17, textAlign: 'center', padding: 10 }}><Trans>Log in</Trans></Text>
                        </Button>
                    </Box>
                </Box>
            }
        >
            {/* Header */}
            <Box style={mainStyle.headerJoin}>
                <Image
                    source={require('../../Assets/Images/logo.png')}
                    height={20}
                    width={40}
                    resizeMode={'contain'}
                />
            </Box>

            {/* Content */}
            <Box style={mainStyle.contentWrapperJoin}>
                <Heading style={{ color: '#000', fontWeight: '500', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>Log in</Trans></Heading>
                <Heading style={{ color: '#00aaff', fontWeight: '700', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>in Skedle</Trans></Heading>
                <Box style={{ width: '100%', maxWidth: 500 }}>
                    <Box style={{ width: '100%', maxWidth: '100%' }} mt="2">
                        <FormControl isRequired isInvalid={invalid}>
                            <FormControl.Label><Text><Trans>Email:</Trans></Text></FormControl.Label>
                            <Input
                                ref={emailRef}
                                type={'text'}
                                variant={'filled'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'email'}
                                returnKeyType={'next'}
                                keyboardType={'email-address'}
                                onChangeText={(text) => {
                                    setEmail(text.trim().toLocaleLowerCase());
                                }}
                                onSubmitEditing={() => {
                                    passwordRef.current.focus();
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box style={{ width: '100%', maxWidth: '100%' }} mt="2">
                        <FormControl isRequired isInvalid={invalid}>
                            <FormControl.Label><Text><Trans>Password:</Trans></Text></FormControl.Label>
                            <Input
                                ref={passwordRef}
                                type={viewPassword ? 'text' : 'password'}
                                variant={'filled'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'password'}
                                returnKeyType={'done'}
                                keyboardType={'default'}
                                secureTextEntry={!viewPassword}
                                onChangeText={(text) => {
                                    setPassword(text);
                                }}
                                InputRightElement={
                                    <Pressable
                                        onPress={() => {
                                            setViewPassword(!viewPassword);
                                        }}
                                    >
                                        <Icon name={viewPassword ? 'eye-slash' : 'eye'} size={16} style={{ marginRight: 5 }} />
                                    </Pressable>
                                }
                                onSubmitEditing={submitLogin}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>

            {/* Admin modal */}
            <Modal isOpen={adminModal}>
                <Modal.Content>
                    <Modal.Header><Text bold>Ready for impersonation</Text></Modal.Header>
                    <Modal.Body>
                        <Text><Trans>The application is ready. Send a notification trough the admin panel to get started.</Trans></Text>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </AppContainer>
    );
}

export default LoginScreen;