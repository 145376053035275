import React, { useCallback, useEffect, useState, useRef } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet } from 'react-native';
import { Box, Heading, Skeleton, VStack, HStack } from 'native-base';
import main from '../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../Components/Trans';
import { useIsFocused } from '@react-navigation/native';
import InvoiceItem from '../Organization/Components/InvoiceItem';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const InvoiceScreen = ({ navigation }) => {

    // Init
    const firstLoad = useRef(true);
    const focused = useIsFocused();
    const [invoices, setInvoices] = useState(false);

    /**
     * Function to load specific data at first load
     *
     * @type {(function(): void)|*}
     */
    const onFirstLoad = useCallback(() => {
        const init = async () => {
            // Get employees
            let apiInvoices = await APIAction.request(
                {
                    method: 'GET', url: '/api/sales_invoices', cache: false,
                });

            apiInvoices = apiInvoices['hydra:member'];

            let invoiceItems = [];
            for (let apiInvoice of apiInvoices){
                invoiceItems.push(
                    <InvoiceItem key={uuidv4()} data={apiInvoice} />
                );
            }

            setInvoices(invoiceItems);
        };

        if (focused) {
            init();
        }
    }, [focused]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }

    }, [onFirstLoad]);

    useEffect(() => {
        if (!focused) {
            firstLoad.current = true;
        }
    }, [focused]);


    // Return view
    return (
        <AppContainer>
            <VStack
                space={2}
            >
                <Box style={[mainStyle.pageTitleWrapper, { paddingBottom: 0 }]}>
                    <Box style={{ flexGrow: 1 }}>
                        <HStack space={2} alignItems={'center'}>
                            <Box flexGrow={1} flexShrink={1}>
                                <Heading style={mainStyle.pageTitle}><Trans>Invoices</Trans></Heading>
                            </Box>
                        </HStack>
                    </Box>
                </Box>
                <Box style={mainStyle.whiteBox}>
                    <VStack space={2}>
                        {invoices === false &&
                            <VStack space={2}>
                                <Skeleton h={'30'} />
                                <Skeleton h={'30'} />
                            </VStack>
                        }
                        {invoices}
                    </VStack>
                </Box>
            </VStack>
        </AppContainer>
    );
};

export default InvoiceScreen;
