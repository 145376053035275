import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, HStack, Text, IconButton, useTheme, AlertDialog, Button, Popover, Image } from 'native-base';
import UserImage from '../../Components/UserImage';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineawesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Trans from '../../Components/Trans';
import APIAction from '../../../Actions/APIAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import UserAction from "../../../Actions/UserAction";
import GeneralAction from "../../../Actions/GeneralAction";

const Icon = createIconSetFromFontello(lineawesomeConfig);

const ProjectEmployeeShift = (props) => {

    const
        firstLoad = useRef(true),
        [showRemove, setShowRemove] = useState(false),
        [alias, setAlias] = useState(false)
        ;

    const themeColors = useTheme()['colors'];

    let contractStatusColor = "#B4B4B4";
    let contractStatusText = <Trans>Geen contract</Trans>;

    if(props.employeeShift.contract){
        if (props.employeeShift && props.employeeShift.contract && props.employeeShift.contract.complete) {
            contractStatusColor = "#3BB143";
            contractStatusText = <Trans>Afgewerkt contract</Trans>
        }
        else if (props.employeeShift && props.employeeShift.contract && !props.employeeShift.contract.complete) {
            contractStatusColor = "#FFA500";
            contractStatusText = <Trans>Contract niet afgewerkt</Trans>
        }
        if (props.employeeShift.shift && props.employeeShift.shift.easyPayErrors && props.employeeShift.shift.easyPayErrors.length > 0) {
            props.employeeShift.shift.easyPayErrors.forEach(function (error) {
                if (error.active) {
                    contractStatusColor = "#FF6363";
                    contractStatusText = <Trans>Er is een fout opgetreden bij het aanmaken van het contract. Gelieve contact op te nemen met Silver Tie.</Trans>;
                }
            })
        }
    }

    if(props.employeeShift.multiDayContract){
        if (props.employeeShift && props.employeeShift.multiDayContract && props.employeeShift.multiDayContract.complete) {
            contractStatusColor = "#3BB143";
            contractStatusText = <Trans>Afgewerkt contract</Trans>
        }
        else if (props.employeeShift && props.employeeShift.multiDayContract && !props.employeeShift.multiDayContract.complete) {
            contractStatusColor = "#FFA500";
            contractStatusText = <Trans>Contract niet afgewerkt</Trans>
        }
        if (props.employeeShift.shift && props.employeeShift.shift.easyPayErrors && props.employeeShift.shift.easyPayErrors.length > 0) {
            props.employeeShift.shift.easyPayErrors.forEach(function (error) {
                if (error.active) {
                    contractStatusColor = "#FF6363";
                    contractStatusText = <Trans>Er is een fout opgetreden bij het aanmaken van het contract. Gelieve contact op te nemen met Silver Tie.</Trans>;
                }
            })
        }
    }


    let dimonaStatusName = "";
    let dimonaStatusColor = "#B4B4B4";

    if (props.employeeShift && props.employeeShift.contract && props.employeeShift.contract.dimonaStatus) {
        let fullName = props.employeeShift.contract.dimonaStatus.name["nl"];
        if (/-[A-Z]{2}$/.test(fullName)) {
            dimonaStatusName = fullName.slice(0, -3).trim();
        } else {
            dimonaStatusName = fullName;
        }

    } else {
        if(props.employeeShift.multiDayContract && props.employeeShift.multiDayContract.dimonaStatus) {
            let fullName = props.employeeShift.multiDayContract.dimonaStatus.name["nl"];
            if (/-[A-Z]{2}$/.test(fullName)) {
                dimonaStatusName = fullName.slice(0, -3).trim();
            } else {
                dimonaStatusName = fullName;
            }

            dimonaStatusColor = props.employeeShift.multiDayContract.dimonaStatus.color;
        }else{
            dimonaStatusName = "Geen contract";
        }
    }

    const onFirstLoad = useCallback(() => {
        const fetchData = async () => {
            const job = props.employeeShift;
            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);

            if (job.shift && job.shift.position) {
                let aliasRequest = await GeneralAction.getAlias(job.shift.position, clientId);
                setAlias(aliasRequest);
            }
        };
        fetchData();
    }, [props.employeeShift]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad]);


    const remove = async () => {
        let response = await APIAction.request({
            method: 'PATCH',
            url: `/api/employee_shifts/${props.employeeShift.id}`,
            body: {
                deletedAt: new Date(),
            }
        });
        await RequestCacheAction.clear({ url: 'shifts' });
        setShowRemove(false);

        if (typeof props.onUpdate === 'function') {
            props.onUpdate();
        }
    };

    return (
        <>
            <Box style={props.style}>
                <HStack
                    space={2}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Box flexShrink={1}>
                        <HStack
                            space={2}
                            alignItems={'center'}
                        >
                            {props.employeeShift.employee ?
                                <>
                                    <UserImage user={props.employeeShift.employee?.user} />
                                    <Text ellipsizeMode={'tail'} flexShrink={1} style={props.small ? { fontSize: 10 } : null} >{props.employeeShift.employee?.user.firstName} {props.employeeShift.employee?.user.lastName}</Text>
                                </>
                                :
                                <>
                                    <Icon size={24} name={'exclamation-triangle'} />
                                    <Text ellipsizeMode={'tail'} flexShrink={1} style={props.small ? { fontSize: 10 } : null} ><Trans>No employee</Trans></Text>
                                </>
                            }

                        </HStack>
                    </Box>
                    <HStack>
                        {/* Dimona icon */}
                        <Popover trigger={triggerProps => {
                            return <Button {...triggerProps} p={0} backgroundColor={'transparent'}>
                                <Box style={{ backgroundColor: dimonaStatusColor, justifyContent: "center", alignItems: "center", borderRadius: 30, width: 28, height: 28 }}>
                                    <Image resizeMode="contain" style={[{ width: 18, height: 18 }]} source={require('./../../../Assets/Images/dimonaLightBlue.png')} />
                                </Box>
                            </Button>
                        }}
                        >
                            <Popover.Content>
                                <Popover.Body>
                                    <Text>{dimonaStatusName}</Text>
                                </Popover.Body>
                            </Popover.Content>
                        </Popover>
                        {/* Contract icon */}
                        <Popover trigger={triggerProps => {
                            return <Button {...triggerProps} p={0} backgroundColor={'transparent'} borderRadius={'full'} style={{ marginLeft: 5 }}>
                                <Box backgroundColor={contractStatusColor} borderRadius={'full'}>
                                    <Icon name={'file-contract'} size={18} color={"#E9F5FB"} style={{ padding: 5 }} />
                                </Box>

                            </Button>
                        }}
                        >
                            <Popover.Content>
                                <Popover.Body>
                                    <Text>{contractStatusText}</Text>
                                </Popover.Body>
                            </Popover.Content>
                        </Popover>
                        {new Date(props.employeeShift.shift.startOn) > new Date() &&
                            <IconButton
                                style={{ marginLeft: 5 }}
                                borderRadius={'full'}
                                colorScheme={'danger'}
                                variant={'subtle'}
                                icon={<Icon color={themeColors['danger']['600']} name={'trash'} />}
                                onPress={() => {
                                    setShowRemove(true);
                                }}
                            />}
                    </HStack>
                </HStack>
            </Box>
            <AlertDialog isOpen={showRemove} onClose={() => setShowRemove(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text><Trans>Remove employee from shift</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text>
                            <Trans>Do you want to remove</Trans>
                            <Text bold> {props.employeeShift.employee?.user.firstName} {props.employeeShift.employee?.user.lastName} </Text>
                            <Trans>from the shift</Trans>
                            <Text bold> {alias ? alias : props.employeeShift.shift.position.name} test</Text>
                            ?
                        </Text>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button
                                variant={'ghost'}
                                onPress={() => {
                                    setShowRemove(false);
                                }}
                            >
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            <Button
                                colorScheme={'danger'}
                                onPress={() => {
                                    remove();
                                }}
                            >
                                <Text color={'#fff'}><Trans>Remove</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </>
    );
};

export default ProjectEmployeeShift;