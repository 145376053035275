import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Linking, StyleSheet} from 'react-native';
import {
  VStack,
  Box,
  Heading,
  FormControl,
  Text,
  Stack,
  Button,
  CheckIcon,
} from 'native-base';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import main from '../../Assets/Styles/main.json';
import DateTimePicker from '../../Libs/DateTimePicker/index';
import APIAction from '../../Actions/APIAction';
import {Select} from '../../Libs/CustomInputs';
import TranslationAction from '../../Actions/TranslationAction';
import GeneralAction from '../../Actions/GeneralAction';
import {v4 as uuidv4} from 'uuid';
import Helpers from '../../Libs/Helpers';

const mainStyle = StyleSheet.create(main);

const ReportingScreen = () => {
  const [formData, setFormData] = useState({
      startDate: null,
      endDate: null,
      contractType: null,
      employee: null,
    }),
    [isGenerating, setIsGenerating] = useState(false),
    firstLoad = useRef(true),
    [contractTypeOptions, setContractTypeOptions] = useState([]),
    [employeeOptions, setEmployeeOptions] = useState([]);

  /**
   * Fetch data to fill form
   */
  const fetchData = useCallback(() => {
    const init = async () => {
      // Get contract types
      let contractTypes = await APIAction.request({
        method: 'GET',
        url: '/api/contract_types',
        cache: true,
      });
      contractTypes = contractTypes['hydra:member'];

      let newContractTypeOptions = [];
      for (let contractType of contractTypes) {
        let contractTypeLabel = await TranslationAction.translateArray(
          contractType.name,
        );
        newContractTypeOptions.push(
          <Select.Item
            key={uuidv4()}
            value={contractType.id}
            label={contractTypeLabel}
          />,
        );
      }
      setContractTypeOptions(newContractTypeOptions);

      // Get employees
      let apiEmployees = await APIAction.request({
        method: 'GET',
        url: '/api/employees',
        params: {
          verified: 'false',
        },
        cache: false,
      });
      apiEmployees = apiEmployees['hydra:member'];

      let employeeItems = [];
      for (let apiEmployee of apiEmployees) {
        if (apiEmployee.identityVerified && apiEmployee.paymentVerified) {
          employeeItems.push(
            <Select.Item
              key={uuidv4()}
              value={apiEmployee.id}
              label={
                apiEmployee.user.firstName + ' ' + apiEmployee.user.lastName
              }
            />,
          );
        }
      }

      setEmployeeOptions(employeeItems);
    };
    init();
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchData();
    }
  }, [fetchData]);

  const generateReport = async () => {
    // Check if dates are selected
    if (formData.startDate && formData.endDate) {
      // Start date must be before end date
      if (formData.startDate <= formData.endDate) {
        // Generate report
        let response = await APIAction.request({
          method: 'POST',
          url: '/api/reporting',
          body: {
            startDate: formData.startDate,
            endDate: formData.endDate,
            contractTypes: [formData.contractType],
            employee: formData.employee,
          },
          catchFail: false,
        });

        // Redirect to url
        if (typeof response === 'string') {
          await Linking.openURL(response);
          setIsGenerating(false);
        } else {
          GeneralAction.toast('error', <Trans>An error occurred</Trans>);
          setIsGenerating(false);
        }
      } else {
        GeneralAction.toast(
          'error',
          <Trans>Start date must be before end date</Trans>,
        );
        setIsGenerating(false);
      }
    } else {
      GeneralAction.toast('error', <Trans>Fill in required fields</Trans>);
      setIsGenerating(false);
    }
  };

  return (
    <AppContainer>
      <VStack space={2}>
        <Box style={mainStyle.pageTitleWrapper}>
          <Box style={{flexGrow: 1}}>
            <Heading style={mainStyle.pageTitle}>
              <Trans>Reporting</Trans>
            </Heading>
            <Heading style={mainStyle.pageTitle}>
              <Text style={mainStyle.ratingText} ml={2}>
                <Trans>Export contains maximum 500 entries</Trans>
              </Text>
            </Heading>
          </Box>
        </Box>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          space={{
            md: 2,
            base: 4,
          }}>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <FormControl>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>Start Date</Trans>
                  </Text>
                </FormControl.Label>
                <DateTimePicker
                  mode={'date'}
                  value={formData.startDate}
                  onChange={value => {
                    try {
                      let newDate = Helpers.createStringDate(value);
                      if (
                        newDate instanceof Date &&
                        !isNaN(newDate.valueOf())
                      ) {
                        setFormData({...formData, startDate: newDate});
                      }
                    } catch (e) {}
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <FormControl>
                <FormControl.Label isRequired>
                  <Text>
                    <Trans>End Date</Trans>
                  </Text>
                </FormControl.Label>
                <DateTimePicker
                  mode={'date'}
                  value={formData.endDate}
                  onChange={value => {
                    try {
                      let newDate = Helpers.createStringDate(value);
                      if (
                        newDate instanceof Date &&
                        !isNaN(newDate.valueOf())
                      ) {
                        setFormData({...formData, endDate: newDate});
                      }
                    } catch (e) {}
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          space={{
            md: 2,
            base: 4,
          }}>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <Box>
                <FormControl.Label>
                  <Text>
                    <Trans>Contract type</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  selectedValue={formData.contractType || ''}
                  onValueChange={val => {
                    setFormData({...formData, contractType: val});
                  }}>
                  {contractTypeOptions}
                </Select>
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <Box>
                <FormControl.Label>
                  <Text>
                    <Trans>Employee</Trans>
                  </Text>
                </FormControl.Label>
                <Select
                  selectedValue={formData.employee || ''}
                  _selectedItem={{endIcon: <CheckIcon size={5} />}}
                  onValueChange={val => {
                    setFormData({...formData, employee: val});
                  }}>
                  {employeeOptions}
                </Select>
              </Box>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          space={{
            md: 2,
            base: 4,
          }}>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Button
              isLoading={isGenerating}
              flex={1}
              variant={'subtle'}
              onPress={() => {
                setIsGenerating(true);
                generateReport();
              }}>
              <Text color={'primary.600'}>
                <Trans>Generate</Trans>
              </Text>
            </Button>
          </Box>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Button
              isLoading={isGenerating}
              flex={1}
              variant={'outline'}
              onPress={() => {
                setFormData({
                  startDate: null,
                  endDate: null,
                  contractType: '',
                  employee: '',
                });
              }}>
              <Text color={'secondary.600'}>
                <Trans>Clear</Trans>
              </Text>
            </Button>
          </Box>
        </Stack>
      </VStack>
    </AppContainer>
  );
};

export default ReportingScreen;
