import React, { useState, useEffect } from 'react';
import main from '../../Assets/Styles/main.json';
import { StyleSheet } from 'react-native';
import { Box, Heading, HStack, Pressable, Stack, Text, useTheme, VStack } from 'native-base';
import Trans from './Trans';
import { v4 as uuidv4 } from 'uuid';
import GeneralAction from '../../Actions/GeneralAction';
import APIAction from '../../Actions/APIAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import { useRecoilState } from 'recoil';
import ShiftReloadState from '../../Recoil/ShiftReloadState';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const AvailabilityProjectItem = (props) => {

    const
        { colors } = useTheme(),
        [date, setDate] = useState(null),
        [disabled, setDisabled] = useState(false),
        [shiftReload, setShiftReload] = useRecoilState(ShiftReloadState)
        ;

    useEffect(() => {
        const init = async () => {

            //set date for display
            let date = new Date(props.shift.startOn);

            let firstDate = await GeneralAction.formatLocal(date, {
                weekday: 'short',
                year: '2-digit',
                month: 'short',
                day: 'numeric'
            });
            setDate(firstDate);

            //check if shift should be disabled
            let newDisbaled = false;
            for (let plannedShift of props.plannedShiftsTimes) {
                if (
                    (
                        (plannedShift.startOn <= props.shift.startOn) &&
                        (plannedShift.endOn >= props.shift.startOn)
                    ) ||
                    (
                        (plannedShift.startOn <= props.shift.endOn) &&
                        (plannedShift.endOn >= props.shift.endOn)
                    )
                ) {
                    newDisbaled = true;
                    break;
                }
            }
            setDisabled(newDisbaled);


        };
        init();
    }, [setDate, props.shift.startOn, props.shift.endOn, props.plannedShiftsTimes]);

    //on press
    const onShiftPress = async () => {

        //set loading
        props.onLoading(true);
        
        //request
        let response = await APIAction.request({
            method: 'POST',
            url: '/api/employee_shifts',
            body: {
                "employee": props.employee['@id'],
                "shift": "api/shifts/" + props.shift.id
            },
            cache: false
        });

        setShiftReload(props.shift.id);

        if ('status' in response && response.status === 'NOK') {
            await GeneralAction.toast('error', response.message);
            
        } else {
            await GeneralAction.toast('success', <Trans>Shift created</Trans>);
            
        }

        await GeneralAction.sleep(100);
        props.onClose();
    }

    //set style
    let pressStyle = [mainStyle.boxItem, { margin: 0 }];
    if (disabled) {
        pressStyle.push({ backgroundColor: colors['danger']['50'] });
    }

    return (
        <>
            <Pressable
                isDisabled={disabled}
                style={pressStyle}
                flexGrow={1}
                flexShrink={1}
                onPress={() => {
                    onShiftPress();
                }}
            >
                <HStack space={2} flexGrow={1} flexShrink={1} alignItems={'center'}>
                    <Text style={[mainStyle.smallText]}>{date}</Text>
                    <Text flexGrow={1} flexShrink={1}>{props.shift.position.alias ? props.shift.position.alias : props.shift.position.name}</Text>
                    <Text style={[mainStyle.smallText]}>{GeneralAction.formatTime(props.shift.startOn)} - {GeneralAction.formatTime(props.shift.endOn)}</Text>
                    <Icon name={disabled ? 'exclamation-triangle' : 'arrow-right'} size={20} color={disabled ? colors['danger']['600'] : colors['primary']['600']} />
                </HStack>
            </Pressable>
        </>
    )
}

const AvailabilityProject = (props) => {

    const
        { colors } = useTheme()
        ;

    return (
        <Box style={[mainStyle.boxItem, { padding: 5, margin: 0, backgroundColor: colors['primary']['50'] }]}>
            <Box flexGrow={1} flexShrink={1}>
                <Heading style={[mainStyle.mediumTitle]} marginBottom={1} marginLeft={2}>
                    {props.data.project.name}
                </Heading>
                <VStack space={1}>
                    {props.data.shifts.map((shift) => {
                        return (
                            <AvailabilityProjectItem
                                shift={shift}
                                plannedShiftsTimes={props.plannedShiftsTimes}
                                onClose={props.onClose}
                                employee={props.employee}
                                onLoading={props.onLoading}
                            />
                        )
                    })}
                </VStack>
            </Box>
        </Box>
    );
}

export default AvailabilityProject