import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, HStack, Text } from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const CalendarHeader = (props) => {

    const
        [firstLoad, setFirstLoad] = useState(false),
        [headerElements, setHeaderElements] = useState([])
        ;

    const onFirstLoad = useCallback((props) => {
        const init = async () => {
            //loop shift for extreme values
            let minimumDate = new Date(props.date);
            minimumDate = new Date(minimumDate.setHours(9, 0, 0, 0));
            let maximumDate = new Date(props.date);
            maximumDate = new Date(maximumDate.setHours(17, 0, 0, 0));

            let i = 0;
            for (let shift of props.shifts) {

                let shiftMinimum = new Date(shift.shift.startOn);
                let shiftMaximum = new Date(shift.shift.endOn);

                if (i === 0) {
                    minimumDate = new Date(shiftMinimum);
                    maximumDate = new Date(shiftMaximum);
                } else {
                    if (minimumDate > shiftMinimum) minimumDate = new Date(shiftMinimum);
                    if (maximumDate < shiftMaximum) maximumDate = new Date(shiftMaximum);
                }
                i++;
            };

            //round up minimum and maximum
            maximumDate = new Date(maximumDate.setMinutes(maximumDate.getMinutes() - 1)); // Correction for round hours
            minimumDate = new Date(minimumDate.setMinutes(0, 0, 0));
            maximumDate = new Date(maximumDate.setMinutes(0, 0, 0));
            minimumDate = new Date(minimumDate.setHours(minimumDate.getHours() - 1));
            maximumDate = new Date(maximumDate.setHours(maximumDate.getHours() + 1));

            //create header elements
            let newHeaderElements = [];
            i = 0;
            for (let currDate = new Date(minimumDate); currDate <= new Date(maximumDate); currDate = new Date(currDate.setHours(currDate.getHours() + 1))) {

                let formatted = await GeneralAction.formatLocal(currDate, {
                    hour: 'numeric',
                    hour12: false
                });

                let borders = { borderEndWidth: 1, borderEndColor: 'rgba(219,220,222,0.6)' };
                if (i === 0) borders = { ...borders, ...{ borderStartWidth: 1, borderStartColor: 'rgba(219,220,222,0.6)' } }

                newHeaderElements.push(
                    <Box key={uuidv4()} style={[borders]} flexGrow={1} flexShrink={1}>
                        <Text m={0} p={0} width={'100%'} textAlign={'center'} numberOfLines={1}>{formatted}</Text>
                    </Box>
                );
                i++;
            };
            setHeaderElements(newHeaderElements);

            //set length
            if (typeof props.onLength === 'function') {
                props.onLength(newHeaderElements.length);
            }

            //set min
            if (typeof props.onMinDate === 'function') {
                props.onMinDate(minimumDate.toISOString());
            }

            //set max
            if (typeof props.onMaxDate === 'function') {
                props.onMaxDate(new Date(new Date(maximumDate).setHours(new Date(maximumDate.getHours() + 1))).toISOString());
            }
        };
        init();
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad(props);
            setFirstLoad(true);
        }
    }, [props, firstLoad, onFirstLoad]);

    return (
        <></>
        // <Box style={mainStyle.hoursHeader}>
        //     <HStack minW={'100%'}>
        //         {headerElements}
        //     </HStack>
        // </Box>
    );
};

export default CalendarHeader;