import React, {useEffect, useState} from 'react';
import Trans from '../../Components/Trans';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import {Linking, Platform, StyleSheet, TouchableOpacity} from 'react-native';
import main from '../../../Assets/Styles/main.json';
import {Box, Heading, Text, Stack, Skeleton, Button, HStack} from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';
import TranslationAction from '../../../Actions/TranslationAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Address = props => {
  const [fullAddress, setFullAddress] = useState(null);
  useEffect(() => {
    const init = async () => {
      if (props.user) {
        //get address
        let address = props.user.employee.address;

        //get current language
        let language = await TranslationAction.getSelectedLanguage();

        //get country
        let country = await GeneralAction.getCountry(address.country);

        //format addess
        setFullAddress(
          `${address.street} ${address.streetNumber}${address.suite ?? ''}, ${
            address.zip
          } ${address.place}, ${country}`,
        );
      }
    };

    init();
  }, [props.user]);

  return (
    <Box style={[props.style]}>
      {props.user ? (
        <TouchableOpacity
          style={{margin: 0, flexDirection: 'column'}}
          onPress={() => {
            // Open map with address of user
            let os = Platform.OS;
            let url;

            if (os !== 'web') {
              url = Platform.select({
                ios: `maps:0,0?q=${fullAddress}`,
                android: `geo:0,0?q=${fullAddress}`,
              });
            } else {
              url = 'https://www.google.be/maps/place/' + fullAddress;
            }

            Linking.openURL(url);
          }}>
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            space={3}
            justifyContent={{
              base: 'flex-start',
              md: 'space-between',
            }}
            alignItems={{
              base: 'stretch',
              md: 'center',
            }}>
            <Box>
              <HStack alignItems={'center'} space={2}>
                <Icon
                  name={'location-arrow'}
                  size={16}
                  style={{color: '#556ee6'}}
                />
                <Text style={{color: 'rgba(0,0,0,0.5)'}} fontSize={12}>
                  <Trans>Address</Trans>
                </Text>
              </HStack>
              <Text>{fullAddress}</Text>
            </Box>
            {/* <Box flexGrow={1} flexShrink={1} maxWidth={{
                            base: 'full',
                            md: 250
                        }}>
                            <Button
                                borderRadius={'full'}
                                variant={'subtle'}
                                colorScheme={'secondary'}
                                onPress={() => {
                                    // Open map with address of user
                                    let os = Platform.OS;
                                    let url;

                                    if (os !== 'web') {
                                        url = Platform.select({
                                            ios: `maps:0,0?q=${fullAddress}`,
                                            android: `geo:0,0?q=${fullAddress}`,
                                        });
                                    } else {
                                        url = 'https://www.google.be/maps/place/' + fullAddress;
                                    }

                                    Linking.openURL(url);
                                }}
                            >
                                <HStack space={1} alignItems={'center'}>
                                    <Icon name={'location-arrow'} size={16} style={{ color: '#556ee6' }} />
                                    <Text style={{ color: '#556ee6', fontSize: 13 }}><Trans>View on map</Trans></Text>
                                </HStack>
                            </Button>
                        </Box> */}
          </Stack>
        </TouchableOpacity>
      ) : (
        <Skeleton h={85} />
      )}
    </Box>
  );
};

export default Address;
