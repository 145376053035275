import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, HStack, Text, Image } from 'native-base';
import main from '../../../../Assets/Styles/main.json';
import StatusIndicator from './StatusIndicator';
import { createIconSetFromFontello } from 'react-native-vector-icons';

import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const StatusRow = (props) => {
    return (
        <Box style={props.style}>
            <HStack
                space={2}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={1}
            >
                <HStack
                    space={2}
                    alignItems={'center'}
                >
                    <Box style={mainStyle.personItemImage}>
                        <Image
                            style={[mainStyle.userCircle, { width: 36, height: 36 }]}
                            source={require('../../../../Assets/Images/user-1.png')}
                        />
                    </Box>
                    <Box>
                        <Text style={mainStyle.personItemName}>Jos Bosmans <Text style={{ color: '#00aaff' }}>- Kelner</Text></Text>
                    </Box>
                </HStack>
                <Box>
                    <StatusIndicator />
                </Box>
            </HStack>
        </Box>
    );
};

export default StatusRow;