import React from 'react';
import MUIDrawer from '@mui/material/Drawer';
import { Box, HStack, Button } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig)

const Drawer = (props) => {
    return (
        <MUIDrawer
            style={props.style}
            variant={props.variant || 'temporary'}
            anchor={'bottom'}
            open={props.open}
            onClose={() => {
                if (typeof props.onClose === 'function') {
                    props.onClose();
                }
            }}
        >
            <Box style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}>
                <Button variant={'ghost'} colorScheme={'red'} onPress={() => {
                    if (typeof props.onClose === 'function') {
                        props.onClose();
                    }
                }} >
                    <Icon name={'times'} />
                </Button>
            </Box>

            <HStack p={3} justifyContent={'center'}>
                <Box style={{ width: '100%', maxWidth: 1024 }}>
                    {props.children}
                </Box>
            </HStack>
        </MUIDrawer>
    )
}

export default Drawer;