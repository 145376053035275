import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Skeleton, VStack, Box, Heading, ScrollView } from 'native-base';
import CalendarHeader from '../../Components/CalendarHeader';
import CalendarRow from '../../Components/CalendarRow';
import main from '../../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../../../Actions/APIAction';
import UserAction from '../../../../Actions/UserAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import Trans from '../../../Components/Trans';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const StaffScreen = (props) => {

    const
        [firstLoad, setFirstLoad] = useState(false),
        [ready, setReady] = useState(false),
        [shifts, setShifts] = useState(null),
        [header, setHeader] = useState(null),
        [length, setLength] = useState(0),
        [minDate, setMinDate] = useState(null),
        [maxDate, setMaxDate] = useState(null),
        [shiftElements, setShiftElements] = useState([])
        ;

    let routeDate = new Date(props.date);
    routeDate.setHours(0, 0, 0, 0);

    //create header first
    const onFirstLoad = useCallback(() => {
        const init = async (cache = false) => {

            /* SET DATE */
            let currentDate = new Date(props.date);
            currentDate.setHours(0, 0, 0, 0);

            /* GET SHIFTS */
            let currentUser = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(currentUser.client);

            let apiMainShifts = await APIAction.request({
                method: 'GET',
                url: '/api/shifts',
                params: {
                    onDate: currentDate.toISOString(),
                    empty: true
                },
                cache: false
            });
            apiMainShifts = apiMainShifts['hydra:member'];

            let apiShifts = await APIAction.request({
                method: 'GET', url: '/api/employee_shifts', params: {
                    clientId: clientId,
                    onDate: currentDate.toISOString()
                }, cache: cache
            });
            apiShifts = apiShifts['hydra:member'];

            /* ADD SHIFTS TOGETHER */
            for (let mainShift of apiMainShifts) {
                apiShifts.push({ shift: mainShift });
            }

            /* SORT BY DATE */
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SET HEADER IN STATE */
            setHeader(
                <CalendarHeader
                    key={uuidv4()}
                    shifts={apiShifts}
                    date={currentDate}
                    onLength={(hLenght) => {
                        setLength(hLenght);
                    }}
                    onMinDate={(hMin) => {
                        setMinDate(new Date(hMin));
                    }}
                    onMaxDate={(hMax) => {
                        setMaxDate(new Date(hMax));
                    }}
                />
            );

            //set
            setShifts(apiShifts);
            setReady(true);
        };
        init();
    }, [props]);

    //then load other items
    useEffect(() => {
        const init = async () => {

            /* SORT BY DATE */
            let apiShifts = shifts;

            /* SPLIT BY EMPLOYEE */
            let employees = {};
            let employeeShifts = {};

            for (let shift of apiShifts) {
                let employeeId = 'none_' + uuidv4();
                if (shift.employee) {
                    employeeId = shift.employee['@id'];
                }
                if (!(employeeId in employeeShifts)) {
                    employees[employeeId] = shift.employee;
                    employeeShifts[employeeId] = [];
                }

                employeeShifts[employeeId].push(shift);
            }

            /* CREATE SHIFT ITEMS */
            let newShiftElements = [];
            for (let [employeeId, shifts] of Object.entries(employeeShifts)) {
                newShiftElements.push(
                    <CalendarRow
                        key={uuidv4()}
                        shifts={shifts}
                        startOn={minDate}
                        endOn={maxDate}
                        date={props.date}
                        employeeId={employeeId}
                        reload={() => {
                            setFirstLoad(false);
                        }}
                    />
                );
            }

            //add last row
            newShiftElements.push(
                <CalendarRow
                    key={uuidv4()}
                    shifts={[]}
                    startOn={minDate}
                    endOn={maxDate}
                    date={props.date}
                    reload={() => {
                        setFirstLoad(false);
                    }}
                />
            )

            /* SET IN STATE */
            setShiftElements(newShiftElements);

        }
        if (minDate && maxDate && shifts) init();
    }, [minDate, maxDate, shifts, props])

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad();
            setFirstLoad(true);
        }
    }, [onFirstLoad, firstLoad]);

    return (
        <Box style={mainStyle.whiteBox}>
            <Heading style={mainStyle.mediumTitle} mb={5}><Trans>Staff preview</Trans></Heading>
            {!ready ?
                <VStack space={2}>
                    <Skeleton h={'30'} />
                    <Skeleton h={'30'} />
                </VStack>
                :
                <ScrollView horizontal contentContainerStyle={{ minWidth: '100%', width: length * 60 }}>
                    <VStack w={'100%'} space={2}>
                        {header}
                        {shiftElements}
                    </VStack>
                </ScrollView>
            }
        </Box>
    );
}

export default StaffScreen;