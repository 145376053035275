import {
  AlertDialog,
  useTheme,
  VStack,
  Button,
  Text,
  Icon,
  Spinner,
} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';
import TranslationAction from '../../../../Actions/TranslationAction';
import Trans from '../../../Components/Trans';
import APIAction from '../../../../Actions/APIAction';
import Helpers from '../../../../Libs/Helpers';

const ProjectDeviationModal = props => {
  const firstRender = useRef(true),
    awaitDownload = useRef(false),
    pdfError = useRef(false),
    headerFooterKey = useRef(uuid()),
    {colors} = useTheme(),
    [translations, setTranslations] = useState({
      deviation: '',
      sheet: '',
    }),
    cancelRef = useRef(null);

  const onFirstLoad = useCallback(() => {
    const init = async () => {
      let newTranslations = await TranslationAction.translateInLine(
        Object.keys(translations),
      );
      setTranslations({...translations, ...newTranslations});
    };
    if (translations['deviation'] === '' || translations['sheet'] === '') {
      init();
    }
  }, [setTranslations, translations]);

  const downloadDeviationSheet = async () => {
    console.log('Download deviation sheet');
    awaitDownload.current = true;
    const response = await APIAction.request({
      method: 'get',
      url: '/api/projects/' + props.project.id + '/deviation-sheets',
      fileResponse: true,
    }).then(async blob => {
      // Handle file
      awaitDownload.current = false;
    });
    props.onClose();
  };

  useEffect(() => {
    if (firstRender.current && props.isOpen) {
      firstRender.current = false;
      onFirstLoad();
    }
  }, [onFirstLoad, props.isOpen]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose()}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />

        <AlertDialog.Header key={headerFooterKey.current + '1'}>
          {Helpers.firstLetterUpperCase(translations['deviation'])}
        </AlertDialog.Header>

        <AlertDialog.Body>
          <VStack>
            {awaitDownload.current ? (
              <Spinner colorScheme={'primary'} />
            ) : (
              <Text>
                <Trans>Download the deviation sheets</Trans>
              </Text>
            )}
            {!awaitDownload.current && pdfError.current && (
              <Text
                color={colors['danger']['600']}
                fontSize={'xs'}
                display={'flex'}>
                <Trans>Something went wrong</Trans>
              </Text>
            )}
          </VStack>
        </AlertDialog.Body>

        <AlertDialog.Footer>
          <Button.Group space={2} key={headerFooterKey.current + '2'}>
            <Button variant={'ghost'} onPress={() => props.onClose()}>
              <Text>
                <Trans>Cancel</Trans>
              </Text>
            </Button>
            <Button
              variant={'solid'}
              colorScheme={'primary'}
              onPress={downloadDeviationSheet}>
              <Text color={'#fff'}>Download</Text>
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default ProjectDeviationModal;
