import React, { useState, useEffect, useRef } from 'react';
import { Skeleton } from 'native-base';
import TranslationAction from '../../Actions/TranslationAction';

const Trans = (props) => {

    const firstLoad = useRef(true);
    const [translation, setTranslation] = useState(null);

    useEffect(() => {
        const translate = async () => {
            let result = await TranslationAction.translate(props.children);
            setTranslation(result);
        }

        if (firstLoad.current) {
            firstLoad.current = false;
            translate();
        }
    }, [props.children]);

    if (null === translation) {
        return (
            <Skeleton.Text lines={1} />
        );
    }

    return <>{translation}</>;
};

export default Trans;