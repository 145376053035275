import React, { useEffect, useState } from 'react';
import AppContainer from '../Components/AppContainer';
import { StyleSheet } from 'react-native';
import {
    Box, Divider, Heading,
    ScrollView, Text,
} from 'native-base';
import main from '../../Assets/Styles/main.json';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import TranslationAction from '../../Actions/TranslationAction';

const mainStyle = StyleSheet.create(main);

const ProjectScreen = ({ route }) => {

    const [project, setProject] = useState(false);
    let [language, setLanguage] = useState(null);
    let [startDate, setStartDate] = useState('');
    let [endDate, setEndDate] = useState('');
    let [address, setAddress] = useState(false);
    let [country, setCountry] = useState('');

    let id = '';

    // Get id
    if (route.params) {
        if (route.params.id) {
            id = route.params.id;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (id && !project) {
                // Get language
                setLanguage(await TranslationAction.getSelectedLanguage());

                // Get project
                let url = '/api/projects/' + id;
                let currentProject = await APIAction.request({ method: 'GET', url: url });

                if (currentProject) {
                    setProject(currentProject);
                }

                // Get address
                if (currentProject.address) {
                    setAddress(currentProject.address);
                } else if (currentProject.client) {
                    let client = currentProject.client;

                    if (client.addressBilling) {
                        // Pick billing address of client if we don't have a shift address
                        setAddress(client.addressBilling);
                    } else if (client.addresses && client.addresses[0]) {
                        // Pick first address of client if no billing address
                        setAddress(client.addresses[0]);
                    }
                }

                // Format dates
                if (language) {
                    setStartDate(GeneralAction.formatDateFull(currentProject.startDate, language, false, 'numeric', 'short', false));
                    setEndDate(GeneralAction.formatDateFull(currentProject.endDate, language, false, 'numeric', 'short', false));
                }
            }

            // Get country name
            if (address && !country && language && address.country) {
                let countryFull = await GeneralAction.getCountry(address.country);
                setCountry(countryFull);
            }
        };

        fetchData();

    }, [id, language, project, startDate, endDate, address, country]);

    return (
        <AppContainer>

            {
                project &&
                <ScrollView>
                    <Box style={mainStyle.boxItemVertical}>
                        <Box style={[mainStyle.jobHeader, {}]}>
                            <Box>
                                <Box style={[mainStyle.jobCompanyName, {}]}>
                                    <Heading style={[mainStyle.mediumTitle, {
                                        textAlign: 'left',
                                        fontSize: 20,
                                    }]}>{project.name}</Heading>
                                    <Text style={[mainStyle.companyName, { fontSize: 15 }]}>{project.client.name}</Text>

                                </Box>
                            </Box>
                        </Box>
                        <Divider my="3" style={mainStyle.dividerStyle} />


                        <Box style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 10, width: '100%' }}>
                            <Box style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                                <Box>
                                    <Text style={{ fontWeight: '700', fontSize: 16 }}>
                                        {startDate === endDate ? startDate : startDate + ' - ' + endDate}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                            <Box>
                                <Text style={{ fontWeight: '700', fontSize: 16 }}>
                                    {address &&
                                        <>
                                            {address.name}, {address.street + ' ' + address.streetNumber}, {address.zip + ' ' + address.place + ', ' + country}
                                        </>
                                    }
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </ScrollView>
            }
        </AppContainer>
    );
};

export default ProjectScreen;
