import React, { useCallback, useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Skeleton, VStack, HStack, Box, Button, Heading, Modal, Text, FlatList } from 'native-base';
import AppContainer from '../../Components/AppContainer';
import RefreshControl from '../../../Libs/RefreshControl';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import DayBox from './Components/DayBox';
import GeneralAction from '../../../Actions/GeneralAction';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const OneWeekScreen = ({ navigation, route }) => {

    const
        [date, setDate] = useState(new Date(route.params.date).setHours(0, 0, 0, 0)),
        [displayDate, setDisplayDate] = useState(null),
        [weekList, setWeekList] = useState([]),
        [weekListRefreshing, setWeekListRefreshing] = useState(false),
        [weekListShow, setWeekListShow] = useState(false),
        firstLoad = useRef(true),
        [refresh, setRefresh] = useState(uuidv4()),
        [ready, setReady] = useState(false),
        [dayElements, setDayElements] = useState([])
        ;

    const onFirstLoad = useCallback((props) => {
        const init = async (cache = true) => {

            /* DATE */
            let zeroDate = new Date(route.params.date).setHours(0, 0, 0, 0);
            zeroDate = new Date(zeroDate);
            let day = zeroDate.getDay();
            if (day === 0) day = 7; //correct for sunday 
            day = day - 1;
            zeroDate = new Date(zeroDate.setDate(zeroDate.getDate() - day));

            let endDate = new Date(zeroDate);
            endDate = new Date(endDate.setDate(endDate.getDate() + 6));
            endDate = new Date(endDate.setHours(23, 59, 59, 999));

            setDate(zeroDate);
            let formattedDateStart = await GeneralAction.formatLocal(zeroDate, {
                day: 'numeric',
                month: 'short',
                year: '2-digit',
                timeZone: 'europe/brussels'
            });
            let formattedDateEnd = await GeneralAction.formatLocal(endDate, {
                day: 'numeric',
                month: 'short',
                year: '2-digit',
                timeZone: 'europe/brussels'
            });
            setDisplayDate(`${formattedDateStart} - ${formattedDateEnd}`);

            /* DAY ITEMS */
            let newDayItems = [];
            let currDate = new Date(zeroDate);
            for (let i = 0; i < 7; i++) {
                newDayItems.push(
                    <DayBox
                        date={new Date(currDate)}
                        reload={() => {
                            firstLoad.current = true;
                            setRefresh(uuidv4());
                        }}
                    />
                );
                currDate = new Date(new Date(currDate).setDate(new Date(currDate).getDate() + 1));
            }

            //set
            setDayElements(newDayItems);
            setReady(true);
        };
       
        init();
    }, [route.params.date]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.currDate = false;
            onFirstLoad();
        }
    }, [onFirstLoad, refresh]);

    //generate list of weeks for quick navigation
    const generateCalenderList = useCallback((direction = null) => {
        const init = async () => {
            //create batch of weeks
            const dateBatch = async (startDate) => {
                let result = [];

                let lastDate = startDate;
                for (let i = 0; i < 10; i++) {
                    let endDate = new Date(lastDate);
                    endDate.setDate(endDate.getDate() + 6);
                    endDate = new Date(endDate);

                    let name = await GeneralAction.formatLocal(lastDate, {
                        day: 'numeric',
                        month: 'short',
                        year: '2-digit',
                        timeZone: 'europe/brussels'
                    });

                    name += ' - ';

                    name += await GeneralAction.formatLocal(endDate, {
                        day: 'numeric',
                        month: 'short',
                        year: '2-digit',
                        timeZone: 'europe/brussels'
                    });

                    result.push({
                        key: uuidv4(),
                        startDate: lastDate,
                        endDate: endDate,
                        name: name
                    });

                    let nextDate = new Date(endDate);
                    nextDate = nextDate.setDate(nextDate.getDate() + 1);
                    lastDate = new Date(nextDate);
                }

                return result;
            }

            //if no direction asked, start anew
            if (null === direction) {
                let current = new Date();
                current.setHours(0, 0, 0, 0);
                current = new Date(current);

                //get day of current
                let currentDay = current.getDay();
                if (currentDay === 0) currentDay = 7;
                currentDay = currentDay - 1;

                //get monday
                let currentMonday = current.setDate(current.getDate() - currentDay);

                //get 2 mondays ago
                let startMonday = new Date(currentMonday);
                startMonday = startMonday.setDate(startMonday.getDate() - 14);
                startMonday = new Date(startMonday);

                //get first batch
                let batch = await dateBatch(startMonday);

                //set in state
                setWeekList(batch);
            }

            //if new dates need to be appended to end
            if ('end' === direction) {
                //get last day in existing list
                let lastDay = weekList[weekList.length - 1];

                //create next list
                let nextDay = new Date(lastDay.endDate);
                nextDay.setDate(nextDay.getDate() + 1);
                nextDay = new Date(nextDay);

                //create batch
                let batch = await dateBatch(nextDay);

                //set in state
                setWeekList([...weekList, ...batch]);
            }

            //if new dates need to be appended to start
            if ('start' === direction) {
                //get first day in existing list
                let firstDay = weekList[0];

                //previous list
                let previousDay = new Date(firstDay.startDate);
                previousDay.setDate(previousDay.getDate() - (7 * 10));
                previousDay = new Date(previousDay);

                //create batch
                let batch = await dateBatch(previousDay);

                //set in state
                setWeekList([...batch, ...weekList]);
            }
        }
       
        init(direction);
    }, [weekList]);

    //load first weeks in picker
    useEffect(() => {
        if (weekList.length < 1) {
            generateCalenderList();
        }
    }, [weekList, generateCalenderList]);

    return (
        <>
            <AppContainer
                headerColor={'#f2f9fc'}
                header={
                    <HStack alignItems={'center'} space={2} px={3}>
                        <Box flexGrow={1}>
                            <HStack alignItems={'center'}>
                                <Button variant={'ghost'} onPress={() => {
                                    let prevDate = new Date(date);
                                    prevDate = prevDate.setDate(prevDate.getDate() - 7);
                                    prevDate = new Date(prevDate);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'Calendar',
                                            params: {
                                                date: prevDate.getTime()
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-left'} size={24} />
                                </Button>
                                <Button flexGrow={1} variant={'ghost'} p={0} height={45} onPress={() => {
                                    setWeekListShow(true);
                                }}>
                                    <Heading style={[mainStyle.pageHeaderNavigationTitle]}>{displayDate}</Heading>
                                </Button>
                                <Button variant={'ghost'} onPress={() => {
                                    let nextDate = new Date(date);
                                    nextDate = nextDate.setDate(nextDate.getDate() + 7);
                                    nextDate = new Date(nextDate);
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'Calendar',
                                            params: {
                                                date: nextDate.getTime()
                                            }
                                        }]
                                    });
                                }}>
                                    <Icon name={'angle-right'} size={24} />
                                </Button>
                            </HStack>
                        </Box>
                        {/*<Button colorScheme={'primary'} height={30} py={0} onPress={() => {
                            setWeekListShow(true);
                        }}>
                            <Icon color={'#fff'} name={'calendar-check'} size={24} />
                    </Button>*/}
                    </HStack>
                }
            >

                {!ready ?
                    <VStack space={2}>
                        <Skeleton h={20} />
                        <Skeleton h={20} />
                    </VStack>
                    :
                    <Box style={mainStyle.whiteContainer}>
                        {dayElements}
                    </Box>
                }

            </AppContainer>

            {/* switcher */}
            {/* <CalendarSwitcher
                style={{ position: 'absolute', bottom: 100, right: 25 }}
                selected={'week'}
                onTrigger={(val) => {
                    switch (val) {
                        case 'day':
                            navigation.reset({
                                index: 0,
                                routes: [{
                                    name: 'OneDay',
                                    params: {
                                        date: new Date(date).getTime()
                                    }
                                }]
                            });
                            break;
                        case 'month':
                            navigation.reset({
                                index: 0,
                                routes: [{
                                    name: 'OneMonth',
                                    params: {
                                        date: new Date(date).getTime()
                                    }
                                }]
                            });
                            break;
                    }
                }}
            /> */}

            {/* modal */}
            <Modal isOpen={weekListShow} onClose={() => setWeekListShow(false)}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text ><Trans>Choose week</Trans></Text></Modal.Header>
                    <Box height={300} p={3}>
                        <FlatList
                            data={weekList}
                            keyExtractor={item => item.key}
                            ItemSeparatorComponent={() => <Box height={1} />}
                            renderItem={({ item }) => {
                                let now = new Date();
                                let colorScheme = 'light';

                                if (
                                    (item.startDate <= now) &&
                                    (item.endDate >= now)
                                ) {
                                    colorScheme = 'primary';
                                }

                                if (
                                    (item.startDate <= date) &&
                                    (item.endDate >= date)
                                ) {
                                    colorScheme = 'secondary';
                                }

                                return (
                                    <Button colorScheme={colorScheme} variant={'subtle'} size={'sm'} onPress={() => {
                                        navigation.reset({
                                            index: 0,
                                            routes: [{
                                                name: 'Calendar',
                                                params: {
                                                    date: (new Date(item.startDate)).getTime()
                                                }
                                            }]
                                        });
                                    }}>
                                        <Text>{item.name}</Text>
                                    </Button>
                                );
                            }}
                            onEndReached={() => {
                                generateCalenderList('end');
                            }}
                            onEndReachedThreshold={2}
                            refreshControl={
                                <RefreshControl
                                    refreshing={weekListRefreshing}
                                    onRefresh={() => {
                                        setWeekListRefreshing(true);
                                        generateCalenderList('start');
                                        setWeekListRefreshing(false);
                                    }}
                                />
                            }
                        />
                    </Box>
                </Modal.Content>
            </Modal>
        </>
    );
}

export default OneWeekScreen;