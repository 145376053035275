import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Platform, Image} from 'react-native';
import {Box} from 'native-base';
import {NavigationContainer, useIsFocused} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {enableScreens} from 'react-native-screens';
import {MessagingHandler, LinkHandler, logAnalytics} from './Libs/Firebase';
import InteractionWaiter from './Views/Components/InteractionWaiter';

import GreetingScreen from './Views/Public/GreetingScreen';
import LoginScreen from './Views/Public/LoginScreen';
import LoadingScreen from './Views/General/LoadingScreen';
import UpdateScreen from './Views/General/UpdateScreen';
import HomeScreen from './Views/Home/HomeScreen';

import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from './Assets/Fontello/line-awesome-config.json';
import Header from './Views/Components/Header';
import SecureStorage from './Libs/SecureStorage';
import UpdateChecker from './Libs/UpdateChecker';
import APIAction from './Actions/APIAction';
import InvitationScreen from './Views/Invitation/InvitationScreen';
// import HomeNotificationScreen from './Views/Home/HomeNotificationsScreen';
// import CreateJobScreen from './Views/Organization/CreateJobScreen';
import OrganizationScreen from './Views/Organization/OrganizationScreen';
import PersonScreen from './Views/Person/PersonScreen';
import SearchScreen from './Views/Search/SearchScreen';
import OneDayScreen from './Views/Calendar/OneDay/OneDayScreen';
import OneWeekScreen from './Views/Calendar/OneWeek/OneWeekScreen';
import OneMonthScreen from './Views/Calendar/OneMonth/OneMonthScreen';
import {useKeyboard} from './Views/Components/KeyboardHook';
import ProjectScreen from './Views/Project/ProjectScreen';
import ProjectListScreen from './Views/Project/ProjectListScreen';
import ApproveJob from './Views/Job/ApproveJob';
import StatusScreen from './Views/Calendar/Status/StatusScreen';
import CompleteProfileScreen from './Views/CompleteProfile/CompleteProfileScreen';
import PersonalInfoScreen from './Views/CompleteProfile/Items/PersonalInfoScreen2';
import IdCardScreen from './Views/CompleteProfile/Items/IdCardScreen';
import BankDetailsScreen from './Views/CompleteProfile/Items/BankDetailsScreen';
import AdditionalInfoScreen from './Views/CompleteProfile/Items/AdditionalInfoScreen';
import ReportingScreen from './Views/Organization/ReportingScreen';
import SignDocumentsScreen from './Views/CompleteProfile/Items/SignDocumentsScreen';
import SettingScreen from './Views/Organization/SettingScreen';
import ClockScreen from './Views/Clock/ClockScreen';
import UserAction from './Actions/UserAction';
import ApproveJobs from './Views/Job/ApproveJobs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import InvoiceScreen from './Views/Invoices/InvoiceScreen';
import DeviationScreen from './Views/Organization/DeviationScreen';

enableScreens(true);

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const linking = {
  prefixes: [
    'http://localhost:3000',
    'https://employer.silvertie.aware.be',
    'https://staging.employer.silvertie.aware.be',
  ],
  config: {
    screens: {
      Loading: 'public/loading',
      Greeting: 'public/greeting',
      Login: 'public/login',
      App: 'app',
      OneDay: 'one_day/:type/:date',
      OneWeek: 'one_week/:date',
      OneMonth: 'one_month/:date',
      DayStaff: 'day_staff/:date',
      DayDepartment: 'day_department/:date',
      Status: 'status/:date',
      ApproveJob: 'approveJob',
      ApproveJobs: 'approveJobs',
      Person: 'person/:id',
      Calendar: 'calendar/:date',
      Search: 'search',
      Organization: 'organization',
      OrganizationOverview: 'organization_overview',
      InvoiceOverview: 'invoice_overview',
      Setting: 'setting',
      Reporting: 'reporting',
      Home: 'home',
      ProjectList: 'project_list',
    },
  },
};

const TabBarIcon = ({color, focused, size, iconName}) => {
  const keyboardSize = useKeyboard();

  let FocusedBox = props => {
    return (
      <Box
        size={70}
        bg={'primary.600'}
        style={{
          alignSelf: 'center',
          position: 'absolute',
          top: keyboardSize === 0 || Platform.OS === 'ios' ? -25 : 0,
          borderRadius: 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {props.children}
      </Box>
    );
  };

  let tabIcon = <Icon name={iconName} size={30} color={color} />;

  if (focused) {
    return <FocusedBox>{tabIcon}</FocusedBox>;
  }

  return (
    <Box
      style={{
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
      }}>
      {tabIcon}
    </Box>
  );
};

const OrganizationRouter = ({navigation}) => {
  const prevFocused = useRef(false);
  const focused = useIsFocused();

  useEffect(() => {
    if ((prevFocused.current === false) & (focused === true)) {
      navigation.navigate('Organization', {
        screen: 'OrganizationOverview',
      });
    }

    prevFocused.current = focused;
  }, [focused, navigation]);

  return (
    <InteractionWaiter>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName={'OrganizationOverview'}>
        <Stack.Screen
          name={'OrganizationOverview'}
          component={OrganizationScreen}
        />
        <Stack.Screen name={'Project'} component={ProjectScreen} />
        <Stack.Screen name={'Person'} component={PersonScreen} />
        <Stack.Screen name={'Invite'} component={InvitationScreen} />
        <Stack.Screen name={'Setting'} component={SettingScreen} />
        <Stack.Screen name={'Reporting'} component={ReportingScreen} />
        <Stack.Screen name={'Deviation'} component={DeviationScreen} />
        <Stack.Screen name={'Invoices'} component={InvoiceScreen} />

        <Stack.Screen
          name={'CompleteProfile'}
          component={CompleteProfileScreen}
        />
        <Stack.Screen
          name={'CompletePersonalInfo'}
          component={PersonalInfoScreen}
        />
        <Stack.Screen name={'CompleteProfileID'} component={IdCardScreen} />
        <Stack.Screen
          name={'CompleteBankDetails'}
          component={BankDetailsScreen}
        />
        <Stack.Screen
          name={'CompleteAdditionalInfo'}
          component={AdditionalInfoScreen}
        />
        <Stack.Screen name={'SignDocuments'} component={SignDocumentsScreen} />
      </Stack.Navigator>
    </InteractionWaiter>
  );
};

{
  /*
const SearchRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                }}
                initialRouteName={'Search'}
            >
                <Stack.Screen name={'Search'} component={SearchScreen} />
                <Stack.Screen name={'Person'} component={PersonScreen} />
                <Stack.Screen name={'Project'} component={ProjectScreen} />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};
*/
}

{
  /*
const CalendarRouter = () => {
    return (
        <InteractionWaiter>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
                initialRouteName={'OneWeek'}
            >
                <Stack.Screen
                    name={'OneDay'}
                    component={(props) => <OneDayScreen {...props} />}
                    initialParams={{ date: new Date().getTime(), type: 'staff' }}
                />
                <Stack.Screen
                    name={'OneWeek'}
                    component={(props) => <OneWeekScreen {...props} />}
                    initialParams={{ date: new Date().getTime() }}
                />
                <Stack.Screen
                    name={'OneMonth'}
                    component={(props) => <OneMonthScreen {...props} />}
                    initialParams={{ date: new Date().getTime() }}
                />
            </Stack.Navigator>
        </InteractionWaiter>
    );
};
*/
}

const TabRouter = () => {
  const firstLoad = useRef(true),
    [client, setClient] = useState(null);
  const onFirstLoad = useCallback(() => {
    const init = async () => {
      //get user
      let user = await UserAction.getUser();

      //get client
      let client = await APIAction.request({
        method: 'GET',
        url: user.client,
      });

      setClient(client);
    };
    init();
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      onFirstLoad();
    }
  }, [onFirstLoad]);

  if (client) {
    return (
      <>
        <InteractionWaiter>
          <Header />
          <Tab.Navigator
            screenOptions={{
              tabBarHideOnKeyboard: Platform.OS === 'android',
              headerShown: false,
              tabBarShowLabel: false,
              tabBarActiveTintColor: '#fff',
              tabBarStyle: {
                position: 'absolute',
                height: Platform.OS === 'ios' ? 80 : 65,
                paddingTop: 5,
                paddingBottom: Platform.OS === 'ios' ? 25 : 10,
              },
            }}>
            <Tab.Screen
              name={'Home'}
              component={HomeRouter}
              options={{
                tabBarIcon: props => (
                  <TabBarIcon {...props} iconName={'home'} />
                ),
              }}
            />
            <Tab.Screen
              name={'Calendar'}
              component={OneWeekScreen}
              initialParams={{
                date: new Date().getTime(),
              }}
              options={{
                tabBarIcon: ({focused}) =>
                  focused ? (
                    <Image
                      resizeMode="contain"
                      style={[{width: 70, height: 70, marginBottom: 30}]}
                      source={require('./Assets/Images/dimonaLogoFocus.png')}
                    />
                  ) : (
                    <Image
                      resizeMode="contain"
                      style={[{width: 35, height: 35}]}
                      source={require('./Assets/Images/dimonaLogo.png')}
                    />
                  ),
              }}
            />
            {client.enableProjectView && (
              <Tab.Screen
                name={'ProjectList'}
                component={ProjectListScreen}
                options={{
                  tabBarIcon: props => (
                    <TabBarIcon {...props} iconName={'calendar-check'} />
                  ),
                }}
              />
            )}
            {/*<Tab.Screen
                        name={'Status'}
                        component={StatusScreen}
                        initialParams={{ date: new Date().getTime() }}
                        options={{ tabBarIcon: (props) => <TabBarIcon {...props} iconName={'tasks'} /> }}
                    />*/}
            <Tab.Screen
              name={'Organization'}
              component={OrganizationRouter}
              options={{
                tabBarIcon: props => (
                  <TabBarIcon {...props} iconName={'user'} />
                ),
              }}
            />
            {/*<Tab.Screen
                        name={'Search'}
                        component={SearchRouter}
                        options={{ tabBarIcon: (props) => <TabBarIcon {...props} iconName={'search'} /> }}
                />*/}
          </Tab.Navigator>
        </InteractionWaiter>
      </>
    );
  }

  return <LoadingScreen />;
};

const HomeRouter = () => {
  return (
    <InteractionWaiter>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName={'Base'}>
        <Stack.Screen
          name={'Base'}
          component={props => <HomeScreen {...props} />}
        />
        <Stack.Screen name={'ApproveJob'} component={ApproveJob} />
        <Stack.Screen name={'ApproveJobs'} component={ApproveJobs} />
      </Stack.Navigator>
    </InteractionWaiter>
  );
};

const MainRouter = props => {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();

  return (
    <>
      <NavigationContainer
        documentTitle={{
          enabled: false,
        }}
        linking={linking}
        fallback={<LoadingScreen />}
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current.getCurrentRoute().name;

          if (previousRouteName !== currentRouteName) {
            await logAnalytics({
              screen_name: currentRouteName,
              screen_class: currentRouteName,
            });
          }

          routeNameRef.current = currentRouteName;
        }}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
          initialRouteName={props.mainRoute}>
          <Stack.Screen name={'Greeting'} component={GreetingScreen} />
          <Stack.Screen name={'Login'} component={LoginScreen} />
          <Stack.Screen name={'App'} component={TabRouter} />
          <Stack.Screen name={'Update'} component={UpdateScreen} />
          <Stack.Screen name={'Clock'} component={ClockScreen} />
        </Stack.Navigator>
        <UpdateChecker />
        <MessagingHandler />
      </NavigationContainer>
      <LinkHandler
        onTrigger={link => {
          // Check if we have a link
          if (link) {
            // Get parameters from url
            let paramString = link.split('?');

            if (paramString.length >= 2) {
              // Get parameters in an array
              paramString = paramString[1];
              let queryString = new URLSearchParams(paramString);

              // Impersonate user
              if (link.includes('impersonate') && queryString.has('user')) {
                APIAction.impersonate(queryString.get('user'));
              }
            }
          }
        }}
      />
    </>
  );
};

const Router = props => {
  let [ready, setReady] = useState(false);
  let [mainRoute, setMainRoute] = useState('Greeting');

  const onStart = async () => {
    let authorized = await APIAction.authenticationCheck();
    let authReady = false;

    if (!authorized) {
      let credentials = await SecureStorage.getCredentials();
      if (credentials) {
        let loginResult = await APIAction.login(
          credentials.username,
          credentials.password,
        );
        if (loginResult) {
          authReady = true;
          setMainRoute('App');
        }
      }
    } else {
      authReady = true;
      setMainRoute('App');
    }

    //check if locked on clock screen
    let clockLocked = await AsyncStorage.getItem('ClockLocked');
    if (clockLocked && authReady) {
      setMainRoute('Clock');
    }

    //if user has no client always go to greeting
    let user = await UserAction.getUser();
    if (user && user.client === null) {
      setMainRoute('Greeting');
    }

    //load main router
    setReady(true);
  };

  useEffect(() => {
    if (!ready) {
      onStart();
    }
  }, [ready]);

  //render
  if (ready) {
    return <MainRouter {...props} mainRoute={mainRoute} />;
  } else {
    return <LoadingScreen />;
  }
};

export default Router;
