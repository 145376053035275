import React, {useEffect, useState} from 'react';
import main from '../../Assets/Styles/main.json';
import {Platform, StyleSheet} from 'react-native';
import AppContainer from '../Components/AppContainer';
import {
  Box,
  Button,
  FormControl,
  Heading,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Trans from '../Components/Trans';
import DateTimePicker from '../../Libs/DateTimePicker';
import Helpers from '../../Libs/Helpers';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import UserAction from '../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);

const DeviationScreen = () => {
  const [formData, setFormData] = useState({
      startDate: null,
      endDate: null,
    }),
    [isGenerating, setIsGenerating] = useState(false),
    [clientId, setClientId] = useState(null);

  const generateDeviationSheets = async () => {
    // In Reportingscreen this is nested too much, this is cleaner.
    if (
      !formData.startDate ||
      !formData.endDate ||
      formData.startDate > formData.endDate
    ) {
      GeneralAction.toast('error', <Trans>Fill in required fields</Trans>);
      return setIsGenerating(false);
    }

    try {
      if (
        !clientId ||
        clientId === '' ||
        formData.startDate === null ||
        formData.endDate === null
      )
        return;

      let response = await APIAction.request({
        method: 'GET',
        url: `/api/projects/deviation-sheets?startDate=${Helpers.formatDateUrlString(
          formData.startDate,
        )}&endDate=${Helpers.formatDateUrlString(
          formData.endDate,
        )}&clientId=${clientId}`,
        fileResponse: true,
        catchFail: false,
      });

      if (!response) {
        return GeneralAction.toast(
          'error',
          <Trans>No deviation sheets found</Trans>,
        );
      }

      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        await Helpers.downloadAndOpenPdfFromResponse(response);
      } else if (Platform.OS === 'web') {
        Helpers.downloadAndOpenPdfFromResponse(response);
      }
    } catch (e) {
      GeneralAction.toast('error', <Trans>An error occurred</Trans>);
      setIsGenerating(false);
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    UserAction.getUser().then(user => {
      if (user) {
        setClientId(user.client.split('/').pop());
      }
    });

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      setFormData({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      });
    }
  }, []);

  return (
    <AppContainer>
      <VStack space={2}>
        <Box style={mainStyle.pageTitleWrapper}>
          <Box style={{flexGrow: 1}}>
            <Heading style={mainStyle.pageTitle}>
              <Trans>Deviation sheets</Trans>
            </Heading>
            <Heading style={mainStyle.pageTitle}>
              <Text style={mainStyle.ratingText} ml={2}>
                <Trans>Export contains maximum 500 entries</Trans>
              </Text>
            </Heading>
          </Box>
        </Box>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          space={{
            md: 2,
            base: 4,
          }}>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <FormControl>
                <FormControl.Label>
                  <Text>
                    <Trans>Start Date</Trans>
                  </Text>
                </FormControl.Label>
                <DateTimePicker
                  mode={'date'}
                  value={formData.startDate}
                  onChange={value => {
                    try {
                      let newDate = Helpers.createStringDate(value);
                      if (
                        newDate instanceof Date &&
                        !isNaN(newDate.valueOf())
                      ) {
                        setFormData({...formData, startDate: newDate});
                      }
                    } catch (e) {}
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Box>
              <FormControl>
                <FormControl.Label>
                  <Text>
                    <Trans>End Date</Trans>
                  </Text>
                </FormControl.Label>
                <DateTimePicker
                  mode={'date'}
                  value={formData.endDate}
                  onChange={value => {
                    try {
                      let newDate = Helpers.createStringDate(value);
                      if (
                        newDate instanceof Date &&
                        !isNaN(newDate.valueOf())
                      ) {
                        setFormData({...formData, endDate: newDate});
                      }
                    } catch (e) {}
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction={{
            md: 'row',
            base: 'column',
          }}
          space={{
            md: 2,
            base: 4,
          }}>
          <Box flexGrow={1} flexShrink={1} flexBasis={{md: 1}}>
            <Button
              isLoading={isGenerating}
              flex={1}
              variant={'subtle'}
              onPress={() => {
                setIsGenerating(true);
                generateDeviationSheets();
              }}>
              <Text color={'primary.600'}>
                <Trans>Generate</Trans>
              </Text>
            </Button>
          </Box>
        </Stack>
      </VStack>
    </AppContainer>
  );
};

export default DeviationScreen;
