import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Heading } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Collapsible = (props) => {

    const
        [show, setShow] = useState(props.open || false);
    ;

    const collapseStyle = {
        height: show ? 'auto' : 0,
        overflow: 'hidden'
    };

    useEffect(() => {
        setShow(props.open);
    }, [props.open]);

    const onTrigger = () => {
        let open = !show;
        setShow(open);
        if (typeof props.onTrigger === 'function') {
            props.onTrigger(open);
        }
    };

    return (
        <Box style={[props.style]}>
            <Box style={[mainStyle.whiteBoxCollapse, { marginBottom: 0 }]}>
                <Pressable style={mainStyle.collapseButton} onPress={() => onTrigger()}>
                    <Box style={mainStyle.collapseButtonContent}>
                        <Heading style={[mainStyle.mediumTitle, mainStyle.collapseButtonText]}>{props.title}</Heading>
                        <Box style={mainStyle.collapseButtonIcon}>
                            <Icon name={'angle-' + (show ? 'up' : 'down')} size={16} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                        </Box>
                    </Box>
                </Pressable>

                {/* collapsible */}
                <Box style={collapseStyle}>
                    <Box style={[mainStyle.whiteBoxCollapseContent]}>
                        {props.children}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Collapsible;